import { createSlice } from '@reduxjs/toolkit'
import { AppState } from 'store/reduxStore';
import {Setting} from 'types/crema/models/Setting';

const initialState: Setting = {
    navCollapsed: false,
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers:{
        resetState(state){
            state = initialState
        },
        routeChange(state){
            state.navCollapsed = false
        },
        toggleNavCollapsed(state){
            state.navCollapsed = !state.navCollapsed
        },
    }
})

export const {routeChange, toggleNavCollapsed, resetState} = settingsSlice.actions
export default settingsSlice.reducer

export const selectSettings = (state : AppState) => state.settings