
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CremaTheme } from 'types/crema/AppContextPropsType';
import React from 'react';
import { ITempleResponse } from 'types/api/temple';
import clsx from 'clsx'

export const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        status: {
            borderRadius: '3px',
            width: 'fit-content',
            padding: '1px 5px',
            display: 'block'
        },
        statusActive: {
            background: theme.palette.success.main,
            color: theme.palette.success.contrastText
        },
        statusInActive: {
            background: theme.palette.error.main,
            color: theme.palette.error.contrastText
        }
    }),
);

interface TempleStatusChipProps {
    temple: ITempleResponse,
    className?: string
}
const TempleStatusChip : React.FC<TempleStatusChipProps> = ({temple, className}) => {
    const classes = useStyles()
    return (
        <span 
            className={clsx(
                classes.status, temple.isActive ? classes.statusActive : classes.statusInActive,
                className ? className: ''
            )}
        >
                {temple.isActive ? 'Active' : 'Inactive'}
        </span>
    );
};

TempleStatusChip.propTypes = {
    
};

export default TempleStatusChip;