import FormikTextField from 'components/Fields/FormikTextField'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core'
import { inviteApi } from 'api/temple'
import { useSnackbar } from 'notistack'
import { getAxiosError } from 'utils/api'
import { UserRoleEnum } from 'store/reducers/temple/usersSlice'

const validationSchema = yup.object({
    role: yup.mixed<UserRoleEnum>().oneOf(Object.values(UserRoleEnum)).required(),
    email: yup.string().required().email(),
})

const InviteUserForm: React.FC =
    () => {
        const [open, setOpen] = useState<boolean>(false)
        const { messages } = useIntl()
        const { enqueueSnackbar } = useSnackbar();


        const initialValues = {
            email: '',
            role: UserRoleEnum.staff
        }

        return (
            <>
                <Button color='primary' variant='contained' onClick={() => setOpen(true)}>Invite User</Button>
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle style={{ paddingBottom: 0 }}>Send Invitation</DialogTitle>
                    <DialogContent>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={async (values, actions) => {
                                inviteApi.send(values).then(res => res.data).then(data => {
                                    enqueueSnackbar('User invite sent', { variant: 'success' })
                                    actions.resetForm()
                                    setOpen(false)
                                }).catch(err => {
                                    enqueueSnackbar(`Failed to send invite, ${getAxiosError(err)}`, { variant: 'error' })
                                })
                            }}
                        >
                            {() => (
                                <Form>
                                    <FormikTextField
                                        name='email'
                                        variant='outlined'
                                        required
                                        fullWidth
                                        placeholder={messages['common.email']}
                                        label={messages['common.email']}
                                    />

                                    <FormikTextField
                                        select
                                        name='role'
                                        variant='outlined'
                                        required
                                        fullWidth
                                        placeholder={messages['common.role']}
                                        label={messages['common.role']}
                                    >
                                        <MenuItem value={UserRoleEnum.staff}>Staff</MenuItem>
                                        <MenuItem value={UserRoleEnum.admin}>Admin</MenuItem>
                                    </FormikTextField>

                                    <Button type='submit' color='primary' style={{ marginTop: "12px" }} variant='contained'>Send</Button>
                                </Form>
                            )}
                        </Formik>
                    </DialogContent>
                    <DialogActions />
                </Dialog>
            </>
        )
    }

export default InviteUserForm