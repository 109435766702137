import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { donationAPI } from "api/temple";
import { apiStatus } from "shared/constants/AppEnum";
import { AppState } from "store/reduxStore";
import { IApiState } from "store/types";
import { IDonationResponse, TempleIdType } from "types/api/temple";
import { IDonation, IDonationMetaType } from "types/store/temple";
import { selectCurrentTempleId } from "./templeSlice";





const donationAdapter =createEntityAdapter<IDonationResponse>({
    sortComparer:(a,b) => a.name.localeCompare(b.name),
})

type addOnInitialStateType ={
    api:IApiState,
    apiMeta:IApiState,
}

const addOnInitialState:addOnInitialStateType ={
    api:{
        status:apiStatus.IDLE,
        error:null
    },
    apiMeta:{
        status:apiStatus.IDLE,
        error:null
    }
}

export const donationListThunk = createAsyncThunk(
    'donation/list',
    async(payload:TempleIdType,{rejectWithValue,getState}) => {
        let templeId =payload.templeId ?? selectCurrentTempleId(getState() as AppState)
        try{
            const response = await donationAPI.list(templeId)
            return response.data.map(donation => {
                donation.templeId = templeId
                return donation
            })
        }catch(err:any){
            return rejectWithValue(err.response.data)
        }
    } 
)
 export const donationAddThunk = createAsyncThunk(
     'donation/create',
     async(payload:IDonation & TempleIdType,{rejectWithValue,getState}) => {
         let templeId = payload.templeId  ?? selectCurrentTempleId(getState() as AppState)
        try{
            const response = await donationAPI.post(payload,templeId)
            response.data.templeId = templeId
            return response.data
        }catch(err:any){
            return rejectWithValue(err.response.data)
        }
     }
 )

 export const donationRetrieveThunk =createAsyncThunk(
     'donation/retrieve',
     async(payload:IdOrUrlType & TempleIdType,{rejectWithValue,getState}) => {
         let templeId= payload.templeId ?? selectCurrentTempleId(getState() as AppState)
         try{
             let params
             if('id' in payload && payload.id)
                params ={itemId:payload.id}
            else if('url' in payload)
                params = {url:payload.url}
            else
                return rejectWithValue({detail:'Identifier not provided'})
            const response = await donationAPI.get(params,templeId)
            response.data.templeId = templeId
            return response.data
         }catch(err:any){
             return rejectWithValue(err.response.data)
         }
     }
 )

 export const donationUpdateThunk = createAsyncThunk(
     'donation/update',
     async(payload:PayloadWithUrl<Partial<IDonation>>,{rejectWithValue}) => {
         try{
             const response = await donationAPI.update(payload.url,payload.data)
             return response.data
         }catch(err:any){
             return rejectWithValue(err.response.data)
         }
     }
 )

 export const donationDeleteThunk = createAsyncThunk(
     'donation/delete',
     async(payload:IdAndUrlType) => {
         await donationAPI.delete(payload.url)
         return payload.id
     }
 )

 export const donationMetaAddThunk = createAsyncThunk(
     'donation/meta/add',
     async(payload:PayloadWithUrl<IDonationMetaType>,{rejectWithValue}) => {
         try{
             const response = await donationAPI.addDonationMeta(payload.url,payload.data)
             return response.data
         }catch(err:any){
             return rejectWithValue(err.response.data)
         }
     }
 )

export const donationMetaRemoveThunk = createAsyncThunk(
    'donation/meta/remove',
    async(payload:PayloadWithUrl<IDonationMetaType>,{rejectWithValue}) => {
        try{
            const response = await donationAPI.removeDonationMeta(payload.url,payload.data)
            return response.data
        }catch(err:any){
            return rejectWithValue(err.response.data)
        }
    }
)


const donationSlice = createSlice({
    name:'donation',
    initialState:donationAdapter.getInitialState(addOnInitialState),
    reducers:{
        resetState(state){
            state.api ={
                status:apiStatus.IDLE,
                error:null
            }
            state.apiMeta ={
                status:apiStatus.IDLE,
                error:null
            }
            donationAdapter.removeAll(state)
        }

    },
    extraReducers:builder =>{
        builder.addCase(donationListThunk.fulfilled,(state,action) => {
            state.api ={
                status:apiStatus.SUCCEEDED,
                error:null
            }
            donationAdapter.upsertMany(state, action.payload)
        })
        builder.addCase(donationDeleteThunk.fulfilled,(state,action) => {
            state.api ={
                status:apiStatus.SUCCEEDED,
                error:null
            }
            donationAdapter.removeOne(state,action.payload)
        })
        builder.addMatcher(
            isAnyOf(
               donationListThunk.pending,
               donationRetrieveThunk.pending,
               donationUpdateThunk.pending,
               donationDeleteThunk.pending,
               donationAddThunk.pending,
            ),(state,action) =>{
                state.api ={
                    status:apiStatus.LOADING,
                    error:null
                }
            }
        )
        builder.addMatcher(
            isAnyOf(
                donationAddThunk.fulfilled,
                donationUpdateThunk.fulfilled,
                donationRetrieveThunk.fulfilled,
            ),(state,action) =>{
                state.api= {
                    status:apiStatus.SUCCEEDED,
                    error:null
                }
                donationAdapter.upsertOne(state, action.payload)
            }
        )
        builder.addMatcher(
            isAnyOf(
                donationAddThunk.rejected,
                donationListThunk.rejected,
                donationRetrieveThunk.rejected,
                donationUpdateThunk.rejected,
                donationDeleteThunk.rejected,
            ),(state,action) =>{
                state.api = {
                    status:apiStatus.FAILED,
                    error:action.payload
                }
            }
        )
        builder.addMatcher(
            isAnyOf(
               donationMetaAddThunk.fulfilled,
               donationMetaRemoveThunk.fulfilled,
            ),(state,action) =>{
                state.apiMeta = {
                    status:apiStatus.SUCCEEDED,
                    error:null
                }
                donationAdapter.upsertOne(state, action.payload)
            }
        )
        builder.addMatcher(
            isAnyOf(
               donationMetaAddThunk.pending,
               donationMetaRemoveThunk.pending,
            ),(state,action) =>{
                state.apiMeta ={
                    status:apiStatus.LOADING,
                    error:null
                }
            }
        )
        builder.addMatcher(
            isAnyOf(
                donationMetaRemoveThunk.rejected,
                donationMetaAddThunk.rejected
            ),(state,action) =>{
                state.apiMeta = {
                    status:apiStatus.FAILED,
                    error:action.payload
                }
            }
        )
    }
})  
export default donationSlice.reducer 

export const {
    selectAll:selectAllDonation,
    selectById:selectDonationById,
    selectIds:selectDonationIds,
} = donationAdapter.getSelectors((state:AppState) => state.donation)

export const {resetState} = donationSlice.actions

export const selectDonationStatus = (state:AppState):IApiState => state.donation.api
export const selectDonationMetaStatus = (state:AppState) => state.donation.apiMeta

export const selectCurrentTempleDonation = createSelector(
    [selectAllDonation,selectCurrentTempleId],
    (donations,currentTemple) => donations.filter(donation => donation.templeId === currentTemple)    
)

export const selectDonationsByTempleId = createSelector(
    (state:AppState) => state,
    (_:any,templId:string|number) => templId,
    (state,templeId) => {
        if(templeId)
        return selectAllDonation(state).filter(donation => donation.templeId === templeId)
    }
)