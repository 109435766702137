// import React from 'react';
import Error404 from 'components/ErrorPage/Error404'
import Error500 from 'components/ErrorPage/Error500'
import ComingSoon from 'components/ErrorPage/ComingSoon'
import Maintenance from 'components/ErrorPage/Maintenance'
import Error403 from 'components/ErrorPage/Error403';

// export const errorRouteConfig = [
//   {
//     routes: [
//       {
//         path: '/404',
//         // component: React.lazy(() => import('components/ErrorPage/Error404')),
//         component: Error404
//       },
//     ],
//   },
//   {
//     routes: [
//       {
//         path: '/500',
//         // component: React.lazy(() => import('components/ErrorPage/Error500')),
//         component: Error500
//       },
//     ],
//   },
//   {
//     routes: [
//       {
//         path: '/coming-soon',
//         // component: React.lazy(() => import('components/ErrorPage/ComingSoon')),
//         component: ComingSoon
//       },
//     ],
//   },
//   {
//     routes: [
//       {
//         path: '/maintenance',
//         // component: React.lazy(() => import('components/ErrorPage/Maintenance')),
//         component: Maintenance
//       },
//     ],
//   },
// ];

export const errorRouteConfig = [
    {
      path: '/404',
      // component: React.lazy(() => import('components/ErrorPage/Error404')),
      component: Error404
    },
    {
      path: '/403',
      component: Error403
    },
    {
      path: '/500',
      // component: React.lazy(() => import('components/ErrorPage/Error500')),
      component: Error500
    },
    {
      path: '/coming-soon',
      // component: React.lazy(() => import('components/ErrorPage/ComingSoon')),
      component: ComingSoon
    },
    {
      path: '/maintenance',
      // component: React.lazy(() => import('components/ErrorPage/Maintenance')),
      component: Maintenance
    },
];

