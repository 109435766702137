import Box from '@material-ui/core/Box';
import HeadingCard from 'components/Card/HeadingCard';
import React from 'react';
import { IOfferingCategoryResponse } from 'types/api/temple';
import OfferingCategoryTable from '../OfferingCategoryTable';

interface OfferingCategoryListCardProps {
    offeringCategories: IOfferingCategoryResponse[]
}

const OfferingCategoryListCard: React.FC<OfferingCategoryListCardProps> = ({ offeringCategories }) => {
    return (
        <HeadingCard title="Offering Categories" cardContent={false}>
             {offeringCategories.length > 0 ? (
                <OfferingCategoryTable offeringCategories={offeringCategories} />
            ) : (
                <Box component="p" textAlign="center" my={10}>Offering Category List is empty</Box>
            )}
        </HeadingCard>
    );
};


export default OfferingCategoryListCard;