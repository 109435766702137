import Box from '@material-ui/core/Box';
import HeadingCard from 'components/Card/HeadingCard';
import React from 'react';
import { IOfferingResponse } from 'types/api/temple';
import OfferingTable from '../OfferingTable';

interface OfferingListCardProps {
    offerings: IOfferingResponse[]
}

const OfferingListCard: React.FC<OfferingListCardProps> = ({ offerings }) => {
    return (
        <HeadingCard title="Offerings" cardContent={false}>
            {offerings.length > 0 ? (
                <OfferingTable offerings={offerings} />
            ) : (
                <Box component="p" textAlign="center" my={10}>Offering list is empty</Box>
            )}
        </HeadingCard>
    );
};


export default OfferingListCard;