import React, { useEffect } from 'react'
import { apiStatus } from 'shared/constants/AppEnum'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { offeringListThunk, selectCurrentTempleOfferings, selectOfferingStatus } from 'store/reducers/temple/offeringSlice'
import { CremaTheme } from 'types/crema/AppContextPropsType';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IOfferingResponse } from 'types/api/temple';
import { Scrollbar } from '@crema';
import { offeringIntersect, offeringNot, offeringUnion } from 'utils/temple/offering';
import { BulletList } from 'react-content-loader'

const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        root: {
            margin: 'auto',
            [theme.breakpoints.down('sm')]: {
                flexDirection: "column",
                alignItems: "stretch"
            },
        },
        cardHeader: {
            padding: theme.spacing(1, 2),
        },
        list: {
            width: 300,
            height: 500,
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: "100%",
            },
        },
        button: {
            margin: theme.spacing(0.5, 0),
        },
    }),
);

interface OfferingTransferListProps {
    chosenOfferings?: IOfferingResponse[],
    handleAddOfferings: ((offerings: IOfferingResponse[]) => void | Promise<any>) & Function
    handleRemoveOfferings: ((offerings: IOfferingResponse[]) => void | Promise<any>) & Function
}

const OfferingTransferList: React.FC<OfferingTransferListProps> = props => {
    const { chosenOfferings = [], handleAddOfferings, handleRemoveOfferings } = props
    const classes = useStyles();
    const dispatch = useAppDispatch()
    const allOfferings = useAppSelector(selectCurrentTempleOfferings)
    const offeringAPIStatus = useAppSelector(selectOfferingStatus)
    const [availableOfferings, setAvailableOfferings] = React.useState<IOfferingResponse[]>([]);
    useEffect(() => {
        dispatch(offeringListThunk({}))
    }, [dispatch])
    useEffect(() => {
        if (allOfferings)
            setAvailableOfferings(offeringNot(allOfferings, chosenOfferings))
    }, [chosenOfferings, allOfferings])
    const [checked, setChecked] = React.useState<IOfferingResponse[]>([]);
    const leftChecked = offeringIntersect(checked, availableOfferings);
    const rightChecked = offeringIntersect(checked, chosenOfferings);

    const handleToggle = (offering: IOfferingResponse) => () => {
        const currentIndex = checked.findIndex(o => o.id === offering.id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(offering);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: IOfferingResponse[]) => offeringIntersect(checked, items).length;

    const handleToggleAll = (items: IOfferingResponse[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(offeringNot(checked, items));
        } else {
            setChecked(offeringUnion(checked, items));
        }
    };

    const handleCheckedRight = () => {
        handleAddOfferings(leftChecked)
        setChecked(offeringNot(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        handleRemoveOfferings(rightChecked)
        setChecked(offeringNot(checked, rightChecked));
    };

    const customList = (title: React.ReactNode, items: IOfferingResponse[]) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {offeringAPIStatus.status === apiStatus.LOADING ? (
                    <BulletList />
                ) : (
                    <Scrollbar>
                        {items.map((offering: IOfferingResponse) => {
                            const labelId = `transfer-list-all-item-${offering.id}-label`;
                            return (
                                <ListItem key={offering.id} role="listitem" button onClick={handleToggle(offering)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checked.findIndex(obj => obj.id === offering.id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={offering.name} />
                                </ListItem>
                            );
                        })}
                        <ListItem />
                    </Scrollbar>
                )}

            </List>

        </Card>
    );

    return (
        <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
            <Grid item>{customList('Available Offerings', availableOfferings)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList('Selected Offerings', chosenOfferings)}</Grid>
        </Grid>
    );
}

export default React.memo(OfferingTransferList)