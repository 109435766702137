import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { unwrapResult } from '@reduxjs/toolkit';
import HeadingCard from 'components/Card/HeadingCard';
import { CommonTableHeadCell, CommonTableOrder } from 'components/Table/common/types';
import PaginatedTable, { getComparator } from 'components/Table/PaginatedTable';
import PaginatedTableHead from 'components/Table/PaginatedTable/PaginatedTableHead';
import React, { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { apiStatus } from 'shared/constants/AppEnum';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllInactiveTemples, selectTempleListApi, staffTempleListThunk } from 'store/reducers/temple/templeSlice';
import { ITempleResponse } from 'types/api/temple';
import { dateTimeToString, isoDateToDate, stableSort } from 'utils/fns';
import { useStyles } from './style';
import { StaffTempleOrderByType, StaffTempleParamsType } from 'api/admin/types';

type TempleApprovalRowType = {
    url: string
    id: number | string
    name: string
    createdAt?: Date
}

type OrderingChoicesType = Partial<Record<keyof TempleApprovalRowType, StaffTempleOrderByType>>
type OrderByChoices = Record<CommonTableOrder, OrderingChoicesType>

interface TempleApprovalTableProps{
    handleTempleOpen: (url: string) => void
}

interface IPaginationState {
    order: CommonTableOrder,
    currentPage: number,
    maxPage: number,
    nextPageUrl: string
    orderBy: keyof TempleApprovalRowType,
    pageSize: number,
}

const headCells: CommonTableHeadCell<TempleApprovalRowType>[] = [
    { id: 'id', align: 'left', disablePadding: false, label: 'Temple Id', hasSorting: true },
    { id: 'name', align: 'left', disablePadding: false, label: 'Name', hasSorting: true },
    { id: 'createdAt', align: 'left', disablePadding: false, label: 'Created On', hasSorting: true },
];

const getRows = (temples: ITempleResponse[], order: CommonTableOrder, orderBy: keyof TempleApprovalRowType): TempleApprovalRowType[] => {
    const rows = temples.map(temple => {
        let createdAt: Date | boolean = temple.createdAt ? isoDateToDate(temple.createdAt) : false
        return ({
            url: temple.url,
            id: temple.id,
            name: temple.name,
            ...(typeof createdAt !== 'boolean' ? { createdAt: createdAt } : {})
        })
    })
    return stableSort(rows, getComparator(order, orderBy))
}


const initialState: IPaginationState = {
    order: 'desc',
    currentPage: 0,
    maxPage: 0,
    nextPageUrl: '',
    orderBy: 'id',
    pageSize: 10,
}


const orderByChoices: OrderByChoices = {
    asc: {
        id: 'id',
        name: 'name',
        createdAt: 'created_at'
    },
    desc: {
        id: '-id',
        name: '-name',
        createdAt: '-created_at'
    }
}

const TempleApprovalTable: React.FC<TempleApprovalTableProps> = ({handleTempleOpen}) => {
    const dispatch = useAppDispatch()
    const classes = useStyles()
    const api = useAppSelector(selectTempleListApi)
    const [pagination, setPagination] = useState<IPaginationState>(initialState)
    const [params, setParams] = useState<StaffTempleParamsType>({ is_active: false, o: '-created_at' })
    const temples = useAppSelector(selectAllInactiveTemples)
    const rows = useMemo(() => getRows(temples, pagination.order, pagination.orderBy), [temples, pagination.order, pagination.orderBy])
    useEffect(() => {
        dispatch(staffTempleListThunk(params))
            .then(unwrapResult)
            .then(response => setPagination(p => ({ ...p, nextPageUrl: response.nextLink })))
            .catch(err => err)
    }, [dispatch, params])
    useEffect(() => {
        if (orderByChoices[pagination.order][pagination.orderBy])
            setParams(p => ({ ...p, o: orderByChoices[pagination.order][pagination.orderBy] }))
    }, [pagination.order, pagination.orderBy])
    const handleChangePage = async (event: unknown, newPage: number) => {
        if (newPage > pagination.maxPage) {
            setPagination(p => ({ ...p, maxPage: newPage }))
            if (pagination.nextPageUrl) {
                await dispatch(staffTempleListThunk({ url: pagination.nextPageUrl }))
                    .then(unwrapResult)
                    .then(response => setPagination(p => ({ ...p, nextPageUrl: response.nextLink })))
                    .catch(err => err)
            }
        }
        setPagination(p => ({ ...p, currentPage: newPage }))
    };

    const sortHandler = (id: keyof TempleApprovalRowType) => {
        pagination.orderBy === id ?
            setPagination(p => ({ ...p, order: p.order === 'asc' ? 'desc' : 'asc' })) :
            setPagination(p => ({ ...p, orderBy: id }))
    }

    return (
        <HeadingCard title="Temple Approvals" cardContent={false} hasheaderBackground={false}>
            {temples.length > 0 ? (
                <PaginatedTable
                    pagination={{
                        rowsPerPage: pagination.pageSize,
                        rowsPerPageOptions: [10],
                        currentPage: pagination.currentPage,
                        nextIconDisabled:
                            temples.length > 0
                                && !pagination.nextPageUrl
                                && pagination.currentPage === pagination.maxPage ? true : false,
                        handleChangePage: handleChangePage,
                    }}
                    tableHead={
                        <PaginatedTableHead
                            headCells={headCells}
                            orderBy={pagination.orderBy}
                            order={pagination.order}
                            sortHandler={sortHandler}
                        />
                    }
                    api={api}
                >
                    {rows.slice(pagination.currentPage * pagination.pageSize, pagination.currentPage * pagination.pageSize + pagination.pageSize)
                        .map(row => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    <Box className={classes.link} onClick={() => handleTempleOpen(row.url)}>{`#${row.id}`}</Box>
                                </TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.createdAt ? dateTimeToString(row.createdAt) : ''}</TableCell>
                            </TableRow>
                        ))}
                </PaginatedTable>
            ) : (
                <Box textAlign="center" my={10}>
                    {api.status === apiStatus.LOADING ? <CircularProgress/> : <p>'No pending requests'</p>}
                </Box>
            )}
        </HeadingCard>
    );
};


export default TempleApprovalTable