import MapPicker from 'components/MapPicker';
import React from 'react';
import { useMemo } from 'react';
import { LocationResponseType } from 'types/api/temple';

interface TempleLocationMapPrps {
    location?: LocationResponseType
    apiKey: string,
    onChangeLocation?: (lat: number, lng: number) => void,
    showSearchBox?: boolean
    fixed?: boolean
    draggable?: boolean
}

const getDefaultLocation = (location?: LocationResponseType) => {
    return location?
        { lat: location.latlng.latitude, lng: location.latlng.longitude } :
        { lat: 20.5937, lng: 78.9629}
}
const TempleLocationMap: React.FC<TempleLocationMapPrps> = 
({ location, apiKey, onChangeLocation, showSearchBox, fixed, draggable }) => {
    const defaultLocation = useMemo(() => getDefaultLocation(location), [location])
    return (
        <MapPicker
            apiKey={apiKey}
            defaultLocation={defaultLocation}
            zoom={15}
            onChangeLocation={(lat, lng) => {
                if (onChangeLocation !== undefined) onChangeLocation(lat, lng)
            }}
            showSearchBox={showSearchBox}
            fixed={fixed}
            draggable={draggable}
        />
    );
};

export default TempleLocationMap;