import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    imgRoot: {
      cursor: 'pointer',
      display: 'inline-block',
      width: 140,
    },
    cardRoot: {
      maxWidth: '36rem',
      width: '100%',
      overflow: 'hidden',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      position: 'relative',
      paddingTop: 20,
      [theme.breakpoints.up('xl')]: {
        paddingTop: 32,
      },
      '&:before': {
        content: "''",
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        width: 130,
        height: 9,
        borderBottomRightRadius: 80,
        borderBottomLeftRadius: 80,
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: theme.palette.primary.main,
      },
    },
    textUppercase: {
      textTransform: 'uppercase',
    },
  }));

export default useStyles