import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { offeringAddonAPI } from "api/temple"
import { IAddonResponse, TAddonPayload } from "containers/Dashboard/Bookable/AdditionalFields"
import { apiStatus } from "shared/constants/AppEnum"
import { AppState } from "store/reduxStore"
import { IApiState } from "store/types"

const addonAdapter = createEntityAdapter<IAddonResponse>({
    sortComparer: (a, b) => a.fieldName.localeCompare(b.fieldName),
})

type addOnInitialStateType = {
    api: IApiState
}

const addOnIntitalState: addOnInitialStateType = {
    api: {
        status: apiStatus.IDLE,
        error: null
    },
}

export const addonFieldListThunk = createAsyncThunk(
    'offering/addon/list',
    async (payload: string, { rejectWithValue }) => {
        try {
            const response = await offeringAddonAPI.list(payload)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const addonFieldAddThunk = createAsyncThunk(
    'offering/addon/add',
    async (payload: TAddonPayload, { rejectWithValue }) => {
        try {
            const response = await offeringAddonAPI.add(payload)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const addonFieldEditThunk = createAsyncThunk(
    'offering/addon/edit',
    async ({ addonId, field }: { addonId: number, field: TAddonPayload }, { rejectWithValue }) => {
        try {
            const response = await offeringAddonAPI.edit({ addonId, field })
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const addonFieldDeleteThunk = createAsyncThunk(
    'offering/addon/delete',
    async (addonId: number, { rejectWithValue }) => {
        try {
            await offeringAddonAPI.remove(addonId)
            return addonId
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

const offeringAddonSlice = createSlice({
    name: 'offering/addon',
    initialState: addonAdapter.getInitialState(addOnIntitalState),
    reducers: {
        resetState(state) {
            state.api = {
                status: apiStatus.IDLE,
                error: null
            }
            addonAdapter.removeAll(state)
        }
    },
    extraReducers: builder => {
        builder.addCase(addonFieldListThunk.pending, (state) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(addonFieldListThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            addonAdapter.upsertMany(state, action.payload)
        })
        builder.addCase(addonFieldListThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(addonFieldAddThunk.pending, (state) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(addonFieldAddThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            addonAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(addonFieldAddThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(addonFieldEditThunk.pending, (state) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(addonFieldEditThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            addonAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(addonFieldEditThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(addonFieldDeleteThunk.pending, (state) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(addonFieldDeleteThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            addonAdapter.removeOne(state, action.payload)
        })
        builder.addCase(addonFieldDeleteThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
    }
})

export const {
    selectAll: selectAllAddon,
    selectById: selectAddonById,
    selectIds: selectAddonIds,
} = addonAdapter.getSelectors((state: AppState) => state.offeringAddon)

export const { resetState } = offeringAddonSlice.actions

export const selectAddonApiStatus = (state: AppState): IApiState => state.offeringAddon.api

export default offeringAddonSlice.reducer

