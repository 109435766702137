import Scrollbar from '@crema/core/Scrollbar';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import React from 'react';
import { apiStatus } from 'shared/constants/AppEnum';
import { IApiState } from 'store/types';
import { useStyles } from '../common/style'
import { CommonTableOrder } from '../common/types';

interface PaginatedTableProps {
  toolbar?: React.ReactElement
  tableHead?: React.ReactElement
  api?: IApiState
  action?: React.ReactElement
  pagination: {
    count?: number
    currentPage: number,
    nextIconDisabled: boolean,
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void,
    rowsPerPage: number,
    rowsPerPageOptions?: number[]
    handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void
  }
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<T extends {}>(
  order: CommonTableOrder,
  orderBy: keyof T,
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const PaginatedTable: React.FC<PaginatedTableProps> = props => {
  const {
    toolbar,
    tableHead,
    api,
    action,
    pagination: {
      rowsPerPageOptions = [10, 25, 50],
      count = -1,
      rowsPerPage,
      currentPage,
      nextIconDisabled,
      handleChangePage,
      handleChangeRowsPerPage
    },
    children
  } = props
  const classes = useStyles()

  const getPagination = () => (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={currentPage}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeRowsPerPage && handleChangeRowsPerPage(e)}
      nextIconButtonProps={{ disabled: nextIconDisabled }}
    />
  )
  const buildFooter = () => (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        {action}
      </Grid>
      <Grid item>
        {getPagination()}
      </Grid>

    </Grid>
  )
  return (
    <React.Fragment>
      {toolbar !== undefined && toolbar}
      <TableContainer>
        <Scrollbar>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            {tableHead !== undefined && tableHead}
            <TableBody>
              {children}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
      {api && api.status === apiStatus.LOADING && <LinearProgress />}
      {action ? buildFooter() : getPagination()}
    </React.Fragment>
  );
};

export default PaginatedTable;