import { makeStyles } from "@material-ui/core/styles";
import { CremaTheme } from "types/crema/AppContextPropsType";
import { Fonts } from 'shared/crema/constants/AppEnums';


const useStyles = makeStyles((theme : CremaTheme) => ({
    listItemRoot: {
        width: "auto",
        fontSize: "0.875rem",
        "&:hover": {
            color: theme.palette.secondary.main,
        },
    },
    copyrightWrapper: {
        color: theme.palette.gray[600],
        fontSize: "1rem",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
        },
    },
    copyrightLink: {
        fontWeight: Fonts.BOLD,
        marginLeft: ".25rem",
        color: theme.palette.grey.A400,
        backgroundColor: "initial",
        textDecoration: "none",
        "&:hover": {
            color: theme.palette.primary.light,
        },
    },
    justifyContentCenter: {
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center!important",
        },
    },
    flexDirectionColumn: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column!important",
        },
    },
}))

export default useStyles;
