import React, { Fragment, useState } from 'react';
import Box from '@material-ui/core/Box';
import { CremaTheme } from 'types/crema/AppContextPropsType';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { getImage } from 'utils/fns';
import { apiStatus, ImageSizes } from 'shared/constants/AppEnum';
import IntlMessages from '@crema/utility/IntlMessages';
import clsx from 'clsx'
import { ITempleResponse } from 'types/api/temple';
import { allowedImgTypes, maxFileSize } from 'shared/constants/AppConst';
import { DropzoneDialog } from 'material-ui-dropzone';
import { TempleImgType } from 'types/store/temple';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectImgUploadApi, uploadImgThunk } from 'store/reducers/temple/templeSlice';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: CremaTheme) => (
    createStyles({
        bgImg: {
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: theme.palette.gray[600],
            position: 'relative'
        },
        imgsContainer: {
            position: 'relative',
            textAlign: 'center'
        },
        coverImg: {
            width: "100%",
            height: '35vh',
            fontSize: '1.2rem',
        },
        displayImg: {
            width: '20vh',
            height: '20vh',
            position: 'absolute',
            fontSize: '.8rem',
            bottom: 10,
            left: 10,
            boxShadow: '1px 1px 8px 0px',
            borderRadius: '.5rem',
            border: '2px solid #fff',
            overflow: 'hidden',
        },
        changeContainer: {
            width: '100%',
            height: '100%',
            padding: 10,
            background: 'rgba(0,0,0,0.5)',
            color: theme.palette.primary.contrastText,
        },
        cvrChangeContainer: {
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            padding: 20
        },
        displayChangeContainer: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        loader: {
            background: 'rgba(0,0,0,0.5)',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0
        },
        circularProgess: {
            color: "#fff"
        },
        flex: {
            display: 'flex',
        },
        hidden: {
            display: 'none'
        },
    })
))

interface TempleImgProps {
    temple: ITempleResponse
}

const TempleImg: React.FC<TempleImgProps> = ({ temple }) => {
    const classes = useStyles()
    const [coverCls, setCoverCls] = useState(classes.hidden)
    const [displayCls, setDisplayCls] = useState(classes.hidden)
    const [dialog, setDialog] = useState(false)
    const [imgType, setImgType] = useState<TempleImgType|''>('')
    const api = useAppSelector(selectImgUploadApi)

    const dispatch = useAppDispatch()
    const openDialog = (type: TempleImgType) => {
        setDialog(true)
        setImgType(type)
    }
    const closeDialog = () => {
        setDialog(false)
        setImgType('')
    }
    const saveDialog = (files: File[]) => {
        if(imgType !== '' && files.length === 1 ){
            dispatch(uploadImgThunk({
                id: temple.id,
                url: temple.url,
                file: files[0],
                imgType: imgType
            }))
        }
        setDialog(false)
    }
    return (
        <Fragment>
            <Box className={classes.imgsContainer}>
                <Box
                    className={clsx(classes.bgImg, classes.coverImg)}
                    style={{
                        backgroundImage: temple.coverImg ? `url(${getImage(temple.coverImg, ImageSizes.LARGE)})` : '',
                    }}
                    onMouseEnter={() => setCoverCls(classes.flex)}
                    onMouseLeave={() => setCoverCls(classes.hidden)}
                >
                    <Box 
                        onClick={() => openDialog('cover')}
                        className={clsx(
                            classes.changeContainer, 
                            classes.cvrChangeContainer, 
                            api.cover.status === apiStatus.LOADING ? classes.hidden : coverCls
                        )}
                    >
                        <Box component="span">
                            <IntlMessages id="cover.change" />
                        </Box>
                    </Box>
                    <Box className={clsx(classes.loader, api.cover.status !== apiStatus.LOADING ? classes.hidden : '')}>
                        <CircularProgress size="2em" classes={{colorPrimary: classes.circularProgess}} />
                    </Box>
                </Box>
                <Box
                    className={clsx(classes.bgImg, classes.displayImg)}
                    style={{
                        backgroundImage: temple.displayImg ? `url(${getImage(temple.displayImg, ImageSizes.MEDIUM)})` : '',
                    }}
                    onMouseEnter={() => setDisplayCls(classes.flex)}
                    onMouseLeave={() => setDisplayCls(classes.hidden)}
                >
                    <Box 
                        onClick={() => openDialog('display')}
                        className={clsx(
                            classes.changeContainer, 
                            classes.displayChangeContainer, 
                            api.display.status === apiStatus.LOADING ? classes.hidden : displayCls
                        )}
                    >
                        <Box component="span">
                            <IntlMessages id="display.change" />
                        </Box>
                    </Box>
                    <Box className={clsx(classes.loader, api.display.status !== apiStatus.LOADING ? classes.hidden : '')}>
                        <CircularProgress size="2em" classes={{colorPrimary: classes.circularProgess}}/>
                    </Box>
                </Box>
            </Box>
            <DropzoneDialog
                open={dialog}
                onSave={saveDialog}
                acceptedFiles={allowedImgTypes}
                showPreviews={false}
                maxFileSize={maxFileSize}
                onClose={() => closeDialog()}
                filesLimit={1}
                showPreviewsInDropzone={true}
                // useChipsForPreview={true}
            />
        </Fragment>
    );
};

export default React.memo(TempleImg);
