import IntlMessages from '@crema/utility/IntlMessages'
import { Box, Grid, IconButton, MenuItem, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Alert } from '@material-ui/lab'
import { FeeSplitNameResponseType } from 'api/report_app/report_app.response'
import FormikTextField from 'components/Fields/FormikTextField'
import { useField } from 'formik'
import { round } from 'mathjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { IOfferingFormFeeSplitEntryType } from 'types/store/temple'
import DeleteIcon from '@material-ui/icons/Delete';
import { useAppDispatch } from 'store/hooks'
import { feeSplitNameCreateThunk } from 'store/reducers/report_app/feeSplitNameSlice'
import { ITempleResponse } from 'types/api/temple'
import { unwrapResult } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import { extractError } from 'utils/api'

interface PriceSplitFormProps {
    temple: ITempleResponse
    price?: number
    feeSplitNames: FeeSplitNameResponseType[]
    addNewEntry: () => void
    removeEntry: (index: number) => void
}

const getAvailableSplitNames = (feeSplitNames: FeeSplitNameResponseType[], entries: IOfferingFormFeeSplitEntryType[] | undefined) =>
    feeSplitNames.filter(val => entries?.findIndex(entry => entry.name == val.url) == -1)

const PriceSplitForm: React.FC<PriceSplitFormProps> = ({ price, feeSplitNames, addNewEntry, removeEntry, temple }) => {
    const [field, meta] = useField<IOfferingFormFeeSplitEntryType[] | undefined>('splitEntries')
    const errorText = meta.error && meta.touched ? meta.error : '';
    const availableSplitNames = useMemo(() => getAvailableSplitNames(feeSplitNames, field.value), [feeSplitNames, field.value])
    const { messages } = useIntl();
    const [newFeeSplitName, setNewFeeSplitName] = useState("")
    const dispatch = useAppDispatch()
    const { enqueueSnackbar } = useSnackbar()


    if (price == undefined) return null
    return (
        <Box mt={5} mb={5}>
            {field.value && field.value.map((entry, index) => {
                const feeSplitName = feeSplitNames.find(val => val.url == entry.name)
                return (
                    <PriceSplitEntryRow
                        remove={() => removeEntry(index)}
                        key={index}
                        price={price}
                        baseName={`splitEntries.${index}`}
                        feeSplitNames={[...availableSplitNames, ...(feeSplitName !== undefined ? [feeSplitName] : [])]}
                    />
                )
            })}
            {errorText && typeof errorText == 'string' && <Alert severity="error">{errorText}</Alert>}
            <Box mt={4}>
                <Grid container alignItems='center' direction="row" justify='space-between' spacing={6}>
                    <Grid item md={4}>
                        <Button
                            onClick={() => addNewEntry()}
                            // disabled={isButtonDisabled()}
                            variant="outlined"
                            size="medium"
                        >
                            <IntlMessages id="feeSplitEntry.add" />
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={8} container spacing={2} alignItems='center' direction="row" justify='space-between'>
                        <Grid item xs={12} md={8}>
                            <TextField
                                value={newFeeSplitName}
                                onChange={(event) => setNewFeeSplitName(event.currentTarget.value)}
                                margin='dense'
                                placeholder={messages['feeSplitName.new'].toString()}
                                label={messages['feeSplitName.new']}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button
                                onClick={() => !!newFeeSplitName && 
                                    dispatch(feeSplitNameCreateThunk({name: newFeeSplitName, contentObject: temple.url}))
                                        .then(unwrapResult)
                                        .then(() => setNewFeeSplitName(""))
                                        .catch(err => enqueueSnackbar(extractError(err)))
                                    }
                                // disabled={isButtonDisabled()}
                                variant="contained"
                                size="medium"
                                color='primary'
                                disabled={!!!newFeeSplitName}
                                fullWidth
                            >
                                <IntlMessages id="common.add" />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default PriceSplitForm

interface IPriceSplitEntryRowProps {
    baseName: string
    feeSplitNames: FeeSplitNameResponseType[]
    price: number
    remove: () => void
}

const PriceSplitEntryRow: React.FC<IPriceSplitEntryRowProps> = ({ baseName, feeSplitNames, price, remove }) => {
    const [valueField, valueMeta, valueHelpers] = useField<number | "">(`${baseName}.value`)
    const valueErrorText = valueMeta.error && valueMeta.touched ? valueMeta.error : '';
    const [percentField, percentMeta, percentHelpers] = useField<number | "">(`${baseName}.percent`)
    const percentErrorText = percentMeta.error && percentMeta.touched ? percentMeta.error : '';
    const { messages } = useIntl();
    const [savedPrice, setSavedPrice] = useState(price)

    useEffect(() => {
        if(price !== savedPrice && percentField.value){
            setSavedPrice(price)
            valueHelpers.setValue(round((price * percentField.value) / 100, 2))
        }
    }, [percentField, baseName, valueHelpers, savedPrice, price])

    return (
        <Grid container spacing={2} alignItems='center' direction="row" justify="center">
            <Grid item md={4} xs={12}>
                <FormikTextField
                    select
                    fullWidth
                    placeholder={messages['feeSplitEntry.name']}
                    name={`${baseName}.name`}
                    label={messages['feeSplitEntry.name']}
                    variant='outlined'
                >
                    {feeSplitNames.map(item => (
                        <MenuItem key={item.id} value={item.url}>
                            {item.name}
                        </MenuItem>
                    ))}
                </FormikTextField>
            </Grid>
            <Grid item md={3} xs={12}>
                <TextField
                    {...valueField}
                    type='number'
                    onChange={(event) => {
                        valueHelpers.setValue(parseFloat(event.currentTarget.value))
                        percentHelpers.setValue(round((parseFloat(event.currentTarget.value) * 100) / price, 2))
                    }}
                    margin='normal'
                    placeholder={messages['feeSplitEntry.value'].toString()}
                    label={messages['feeSplitEntry.value']}
                    variant='outlined'
                    helperText={valueErrorText}
                    error={!!valueErrorText}
                    fullWidth
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <TextField
                    {...percentField}
                    type='number'
                    onChange={(event) => {
                        percentHelpers.setValue(parseFloat(event.currentTarget.value))
                        valueHelpers.setValue(round((price * parseFloat(event.currentTarget.value)) / 100, 2))
                    }}
                    margin='normal'
                    placeholder={messages['feeSplitEntry.percent'].toString()}
                    label={messages['feeSplitEntry.percent']}
                    variant='outlined'
                    helperText={percentErrorText}
                    error={!!percentErrorText}
                    fullWidth
                />
            </Grid>
            <Grid item md={2} xs={12}>
                <IconButton aria-label="delete" onClick={() => remove()}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    )
}
