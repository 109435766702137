import { Checkbox, FormControl, Grid, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import FormBlock from "components/Form/FormBlock";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectCurrentTempleDeities } from "store/reducers/temple/deitySlice";
import { selectCurrentTemple } from "store/reducers/temple/templeSlice";

type Props = {
    deities: string[],
    setDeities: (dieties: string[]) => void
}

export default function MultiDeitySelect({ deities, setDeities }: Props) {
    // const [values, setValues] = useState([])
    const allDeities = useAppSelector(selectCurrentTempleDeities)
    const options = useMemo(() => allDeities.filter(d => deities.includes(d.url)), [allDeities, deities])
    const dispatch = useAppDispatch()
    const currentTemple = useAppSelector(selectCurrentTemple)

    // useEffect(() => {
    //     console.log(allDeities, currentTemple)
    //     if((!allDeities || !allDeities?.length) && currentTemple) dispatch(deityListThunk({ templeId: currentTemple.id }))
    // }, [allDeities])

    return (
        <FormBlock titleId={'assigned.deities'}>
            <List disablePadding dense>
                {allDeities.map(d => (
                    <ListItem button onClick={() => setDeities(deities.includes(d.url) ? deities.filter(deity => deity !== d.url) : [...deities, d.url])} disableGutters key={d.name}>
                        <Checkbox checked={deities.indexOf(d.url) > -1} size="small" />
                        <ListItemText primaryTypographyProps={{ style: { fontSize: 14 } }} primary={`${d.name}${d?.isMain ? ` (Main)` : ''}`} />
                    </ListItem>
                ))}
            </List>
        </FormBlock>
    )
}