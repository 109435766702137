import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import React from 'react';
import {useStyles} from './style'

interface HeadingCardCardProps {
    title: string
    cardContent?: boolean
    hasheaderBackground?: boolean,
    action?: React.ReactNode
}

const HeadingCard: React.FC<HeadingCardCardProps> = ({ title, cardContent=true, hasheaderBackground=true, children, action }) => {
    const classes = useStyles()
    return (
        <Card>
            <CardHeader 
                action={action}
                classes={hasheaderBackground ? { root: classes.headerRoot } : {}} title={
                <Box component="h5">{title}</Box>
            } />
            {cardContent ? (
                <CardContent>{children}</CardContent>
            ): children}
        </Card>
    );
};


export default HeadingCard;