import React, { useEffect, useState } from 'react'
import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import GoBack from 'components/GoBack'
import { RouteConfigComponentProps } from 'react-router-config'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { offeringCategoryRetrieveThunk, offeringCategoryUpdateThunk, selectOfferingCategoryById, selectOfferingCategoryStatus } from 'store/reducers/temple/offeringCategorySlice'
import FitContentPaper from 'components/Paper/FitContentPaper'
import FormOfferingCategory from './FormOfferingCategory'
import { FormikHelpers } from 'formik'
import { unwrapResult } from '@reduxjs/toolkit'
import { getEditedPayload, getFieldErrors } from 'utils/api'
import { IOfferingCategory } from 'types/store/temple'
import useRestricter from 'hooks/useRestricter'
import { PERMISSIONS, RESTRICTED_PATHS } from 'utils/permissions'

interface RouteParams {
    id: string | undefined
}

const EditOfferingCategory: React.FC<RouteConfigComponentProps<RouteParams>> = (props) => {
    const { id } = props.match.params
    const api = useAppSelector(selectOfferingCategoryStatus)
    const offeringCategory = useAppSelector(state => selectOfferingCategoryById(state, id ? id : ''))
    const [isSuccess, setIsSuccess] = useState(false)
    const [fetched, setFetched] = useState(false)
    const [initialValues, setInitialValues] = useState<IOfferingCategory>({name: ''})
    const dispatch = useAppDispatch()
    const hasEditPermission = useRestricter(PERMISSIONS.CHANGE, RESTRICTED_PATHS.OFFERING_CATEGORIES)

    useEffect(() => {
        if (offeringCategory){
            setInitialValues({name: offeringCategory.name})
        }
        if(!fetched){
            if (offeringCategory)
                dispatch(offeringCategoryRetrieveThunk({url: offeringCategory.url}))
            else 
                dispatch(offeringCategoryRetrieveThunk({ id: id ? id : ''}))
            setFetched(true)
        }
    }, [dispatch, offeringCategory, id, fetched])

    const handleSubmit = (data: IOfferingCategory, actions: FormikHelpers<IOfferingCategory>) => {
        setIsSuccess(false)
        let payload = getEditedPayload(initialValues, data)
        if (payload && offeringCategory?.url){
            dispatch(offeringCategoryUpdateThunk({ data: payload, url: offeringCategory.url }))
                .then(unwrapResult)
                .then(() => setIsSuccess(true))
                .catch(err => {
                    Object.entries(getFieldErrors(data, err)).forEach(([key, value]) => 
                        actions.setFieldError(key, value))
                })
                .finally(() => {
                    actions.setSubmitting(false)
                })
        } else{
            actions.setSubmitting(false)
        }
    }
    const handleSuccess = () => isSuccess ? 'Offering category updated' : ''

    if(!hasEditPermission) return null

    return (
        <GoBack {...props}>
            <FitContentPaper>
                <Box component="h5" mb={4}><IntlMessages id="offeringCategory.edit.title" /></Box>
                <FormOfferingCategory
                    buttonLabelId="common.update"
                    api={api}
                    initialValues={initialValues}
                    onSubmitCb={handleSubmit}
                    isSuccess={isSuccess}
                    successCb={handleSuccess}
                />
            </FitContentPaper>
        </GoBack>
    )
}

export default EditOfferingCategory