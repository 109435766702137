import {makeStyles} from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
    appAuth: {
    //   flex: 1,
    //   display: 'flex',
      position: 'relative',
      minHeight: '100vh',
      backgroundColor: '#f3f4f6',
      backgroundSize: 'cover',
  
      '& .scrollbar-container': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      },
      // '& .main-content-view': {
      //   padding: 20,
      // },
      '& .footer': {
        marginRight: 0,
        marginLeft: 0,
      },
    },
  }));

export default useStyle