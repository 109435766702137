import React from 'react';
import PhoneInput from 'react-phone-input-2'
import { useField } from 'formik'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CremaTheme } from 'types/crema/AppContextPropsType';
import 'react-phone-input-2/lib/material.css'
import { TextFieldProps } from '@material-ui/core';

const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        root: {
            fontFamily: theme.typography.fontFamily,
            '& .form-control': {
                width: '100% !important',
                marginBottom: '8px',
                marginTop: '20px',
                fontFamily: theme.typography.fontFamily,
                color: '#495057',
                padding: '14.5px 14px 14.5px 58px',
                '&:focus': {
                    borderColor: theme.palette.primary.main,
                    boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
                }
            },
            '& .selected-flag .flag': {
                marginTop: '-6px !important'
            },
            '& .special-label': {
                left: '8px',
                top: '12px',
                fontSize: '12px',
                color: '#495057',
            }
        },
    }),
);

type FormikPhoneFieldProps = Omit<TextFieldProps, 'name'> & {name: string}

export const FormikPhoneField = (props: FormikPhoneFieldProps) => {
    const [field, meta, helpers] = useField(props.name);
    const classes = useStyles()
    const errorText = meta.error && meta.touched ? meta.error : '';
    return (
        <PhoneInput
            country={'in'}
            inputProps={{
                name: field.name,
                required: props.required,
            }}
            value={field.value}
            onChange={(phone, data) => helpers.setValue(`+${phone}`)}
            specialLabel={props.label ? props.required ? `${props.label} *` : `${props.label}` : props.required ? 'Phone *' : 'Phone' }
            containerClass={classes.root}
            defaultErrorMessage={errorText}
            isValid={!Boolean(meta.error)}
        />
    );
};