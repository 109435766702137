import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import FormikTextField from 'components/Fields/FormikTextField'
import { Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import Alert from '@material-ui/lab/Alert'
import { extractError, isFieldError } from 'utils/api'
import { useIntl } from 'react-intl'
import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import { IApiState } from 'store/types'
import { apiStatus, BlockPeriods, DonationTypes } from 'shared/constants/AppEnum'
import { IDonationFormType } from 'types/store/temple'
import MenuItem from '@material-ui/core/MenuItem'
import { capStr } from 'utils/fns'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CremaTheme } from 'types/crema/AppContextPropsType'
import WeeklyDays from '../Bookable/WeeklyDays'
import MonthDays from '../Bookable/MonthDays'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { donationCategoryListThunk, selectCurrentTempleDonationCategories } from 'store/reducers/temple/donationCategorySlice'
import { donationFormInitValues } from 'utils/temple/donation'
import { IDonationResponse } from 'types/api/temple'
import FullDates from '../Bookable/FullDates'
import FormikSwitchField from 'components/SwitchField/SwitchField'


const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        formLeftPane: {
            // marginBottom: "1rem",
            [theme.breakpoints.up('sm')]: {
                marginRight: "2rem",
            },
        },
        formRightPane: {
            marginTop: "1rem",
        }
    }),
);
type FormSubmitHandleType =
    ((values: IDonationFormType, formikHelpers: FormikHelpers<IDonationFormType>) => void | Promise<any>) & Function

interface FormProps {
    buttonLabelId: string,
    initialValues?: IDonationFormType | null,
    onSubmitCb: FormSubmitHandleType
    api: IApiState
    alerts: TqAlert[],
    donation?: IDonationResponse
}

const FormDonation: React.FC<FormProps> = (props) => {
    const {
        buttonLabelId,
        initialValues,
        onSubmitCb,
        api,
        alerts
    } = props
    const donationCategories = useAppSelector(selectCurrentTempleDonationCategories)
    // const temple = useAppSelector(selectCurrentTemple)
    const dispatch = useAppDispatch()
    const { messages } = useIntl();
    const classes = useStyles();

    useEffect(() => {
        dispatch(donationCategoryListThunk({}))
    }, [dispatch])
    const validationSchema = yup.object({
        name: yup
            .string()
            .required('Name is required'),
        donationType: yup
            .string()
            .oneOf(Object.values(DonationTypes), 'Invalid donation Type')
            .required('Type of donation is required'),
        category: yup
            .string()
            .required('Category is required'),
        isDateRequired: yup.boolean().required(),
        isAmountFixed: yup.boolean().required(),
        amount: yup
            .number()
            .positive()
            .when(['isAmountFixed'], (isAmtFixed: any, schema: any) => {
                if (isAmtFixed)
                    return schema.required()
                return schema
            }),
        minAmount: yup.number().positive(),
        maxAmount: yup.number().positive(),
        description: yup.string(),
        perDayLimit: yup.number().positive(),
        blockPeriod: yup
            .string()
            .oneOf(Object.values(BlockPeriods), 'Invalid block period'),
        blockUnit: yup.number().positive(),
        repeatUnit: yup.number().positive(),
        donationMeta: yup.object({
            customDates: yup.object({
                fieldDate: yup.date(),
            }),
            excludedDates: yup.object({
                fieldDate: yup.date(),
            }),
        })
    })
    return (
        <Formik
            enableReinitialize
            validateOnChange={true}
            initialValues={initialValues && donationCategories.length > 0 ?
                initialValues : donationFormInitValues
            }
            validationSchema={validationSchema}
            onSubmit={onSubmitCb}
        >
            {({ isSubmitting, dirty, values, isValid, setFieldValue, errors }) => (
                <Form noValidate autoComplete='off'>
                    <Grid container spacing={10}>
                        <Grid xs={12} sm={6} item>
                            <Box className={classes.formLeftPane}>
                                <FormikTextField
                                    placeholder={messages['common.name']}
                                    name='name'
                                    label={<IntlMessages id='common.name' />}
                                    variant='outlined'
                                    required
                                    fullWidth
                                />
                                <FormikTextField
                                    select
                                    name='category'
                                    label={<IntlMessages id='donationCategory' />}
                                    variant='outlined'
                                    required
                                    fullWidth
                                >
                                    {donationCategories.map(item => (
                                        <MenuItem key={item.id} value={item.url}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </FormikTextField>
                                <FormikSwitchField
                                    label='Is Amount Fixed'
                                    name="isAmountFixed"
                                />
                                {values.isAmountFixed === true ? (
                                    <FormikTextField
                                        placeholder={messages['common.amount']}
                                        name='amount'
                                        label={<IntlMessages id='common.amount' />}
                                        variant='outlined'
                                        value={values.amount}
                                        type='number'
                                        required
                                        fullWidth
                                    />
                                ) : (
                                    <React.Fragment>
                                        <FormikTextField
                                            placeholder={messages['common.minAmount']}
                                            name='minAmount'
                                            label={<IntlMessages id='common.minamount' />}
                                            variant='outlined'
                                            type='number'
                                            value={values.minAmount}
                                            fullWidth
                                        />
                                        <FormikTextField
                                            placeholder={messages['common.maxAmount']}
                                            name='maxAmount'
                                            label={<IntlMessages id='common.maxamount' />}
                                            variant='outlined'
                                            type='number'
                                            value={values.maxAmount}
                                            fullWidth
                                        />
                                    </React.Fragment>
                                )}
                                <FormikTextField
                                    name="description"
                                    label={<IntlMessages id='donation.description' />}
                                    variant='outlined'
                                    fullWidth
                                    multiline
                                    rowsMax={10}
                                />

                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikSwitchField
                                label='Is Date Required'
                                name='isDateRequired'
                            />
                            {values.isDateRequired && (
                                <React.Fragment>
                                    <FormikTextField
                                        select
                                        name='donationType'
                                        label={<IntlMessages id='donation.donationType' />}
                                        variant='outlined'
                                        required
                                        fullWidth
                                    >
                                        {Object.entries(DonationTypes).map(([key, value]) => (
                                            <MenuItem key={key} value={value}>
                                                {capStr(value)}
                                            </MenuItem>
                                        ))}
                                    </FormikTextField>
                                    <FormikTextField
                                        select
                                        placeholder={messages['donation.blockPeriod']}
                                        name='blockPeriod'
                                        label={<IntlMessages id='donation.blockPeriod' />}
                                        variant='outlined'
                                        fullWidth
                                    >
                                        {Object.entries(BlockPeriods).map(([key, value]) => (
                                            <MenuItem key={key} value={value}>
                                                {capStr(value).split('_').join(' ')}
                                            </MenuItem>
                                        ))}
                                    </FormikTextField>
                                    {values.blockPeriod && values.blockPeriod !== BlockPeriods.NA && (
                                        <FormikTextField
                                            placeholder={messages['donation.blockunit']}
                                            name='blockUnit'
                                            label={<IntlMessages id='donation.blockunit' />}
                                            variant='outlined'
                                            type='number'
                                            fullWidth
                                        />)}
                                    {values.donationType !== DonationTypes.CUSTOM && (
                                        <FormikTextField
                                            placeholder={messages['donation.repeatUnit']}
                                            name='repeatUnit'
                                            label={<IntlMessages id='donation.repeatUnit' />}
                                            variant='outlined'
                                            type='number'
                                            fullWidth
                                        />
                                    )}
                                    <Box className={classes.formRightPane}>
                                        {values.donationType === DonationTypes.MONTHLY && (
                                            <MonthDays
                                                formContext="donationMeta.monthDays"
                                                formState={values.donationMeta.monthDays}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                        {values.donationType === DonationTypes.WEEKLY && (
                                            <WeeklyDays
                                                formContext="donationMeta.weekDays"
                                                formState={values.donationMeta.weekDays}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                        {values.donationType === DonationTypes.CUSTOM ? (
                                            <FullDates
                                                titleId="donation.customDonation.action.title"
                                                formState={values.donationMeta.customDates}
                                                dateContext="donationMeta.customDates"
                                                setFieldValue={setFieldValue}
                                                errors={errors.donationMeta?.customDates}
                                            //  addHandler={handleCustomDateAdd}
                                            // deleteHandler={handleCustomDateDelete}
                                            // arrayHelpers={arrayHelpers}
                                            />
                                        ) : (
                                            <FullDates
                                                titleId="donation.excluded"
                                                formState={values.donationMeta.excludedDates}
                                                dateContext="donationMeta.excludedDates"
                                                errors={errors.donationMeta?.excludedDates}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Box>
                                </React.Fragment>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Button disabled={isSubmitting || !isValid} variant="contained" color="primary" type="submit">
                                <IntlMessages id={buttonLabelId} />
                            </Button>
                        </Grid>
                        <Grid item container spacing={4} direction="column" alignItems="flex-start">
                            {alerts.map((alert, index) => (
                                <Grid item key={index}>
                                    <Alert severity={alert.severity}>{alert.message}</Alert>
                                </Grid>
                            ))}
                            {api.status === apiStatus.FAILED && !isFieldError(api.error) && (
                                <Grid item>
                                    <Alert severity="error">{extractError(api.error)}</Alert>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export default React.memo(FormDonation)