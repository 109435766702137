import React, { useMemo, useState } from 'react'
import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core'
import { TAdditionalField } from 'types/store/temple'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useParams } from 'react-router'
import { addonFieldDeleteThunk, selectAddonApiStatus, selectAllAddon } from 'store/reducers/temple/offeringAddonSlice'
import EditIcon from '@material-ui/icons/Edit'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import FieldsForm from './FieldsForm'
import { extractIdFromUrlRegex } from 'shared/constants/AppConst'

export type TAddonPayload = Omit<TAdditionalField, 'fieldType'> & {
    dataType: string,
    offering: string
}

export interface IAddonResponse {
    id: number,
    url: string
    fieldName: string
    description: string
    required: boolean
    dataType: string
    maxLength: number
    minLength: number
    minValue: number
    maxValue: number
    offering: string
    addToPrice: boolean,
    allowMultiplier: boolean,
    multiplierLabel?: string
}

interface RouteParams {
    id: string | undefined
}

const addonFieldTypes = [
    {
        type: 'str',
        label: 'Text'
    },
    {
        type: 'int',
        label: 'Number(Int)'
    },
    {
        type: 'float',
        label: 'Number(Float)'
    }
]

const AdditionalField: React.FC = () => {
    const { id } = useParams<RouteParams>()
    const dispatch = useAppDispatch()
    const fields = useAppSelector(selectAllAddon)

    const filteredAddonFields = useMemo(() => {
        return fields.filter(f => id == f.offering.match(extractIdFromUrlRegex)?.[1])
    }, [fields])


    const api = useAppSelector(selectAddonApiStatus)
    const [edit, setEdit] = useState<TAdditionalField & IAddonResponse | null>(null)

    const handlDelete = (addonId?: number) => {
        if (addonId) dispatch(addonFieldDeleteThunk(addonId))
    }

    const getDescription = (field: IAddonResponse): string => {
        const hasMin = (field?.minLength || field?.minValue)
        const hasMax = (field?.maxLength || field?.maxValue)

        if (hasMin && hasMax) return `${field?.description ? `${field.description}, ` : ''}${field.dataType === 'str' ? 'Length range' : 'Value range'}: ${field?.minLength ?? field?.minValue}-${field?.maxLength ?? field?.maxValue}${field?.allowMultiplier ? `, Multiplier: ${field.multiplierLabel}` : ""}${field?.required ? `, Required` : ''} ${field?.addToPrice ? "(Add to price enabled)" : ""} `
        if (hasMin) return `${field?.description ? `${field.description}, ` : ''}${field.dataType === 'str' ? 'Minimum length' : 'Minimum value'}: ${field?.minLength ?? field?.minValue}${field?.allowMultiplier ? `, Multiplier: ${field.multiplierLabel}` : ""}${field?.required ? `, Required` : ''} ${field?.addToPrice ? "(Add to price enabled)" : ""}`
        if (hasMax) return `${field?.description ? `${field.description}, ` : ''}${field.dataType === 'str' ? 'Maximum length' : 'Maximum value'}: ${field?.maxLength ?? field?.maxValue}${field?.allowMultiplier ? `, Multiplier: ${field.multiplierLabel}` : ""}${field?.required ? `, Required` : ''} ${field?.addToPrice ? "(Add to price enabled)" : ""}`

        return `${field?.description ? `${field.description}, ` : ''}${field?.allowMultiplier ? `Multiplier: ${field.multiplierLabel}, ` : ""}${field?.required ? `Required` : ''} ${field?.addToPrice ? "(Add to price enabled)" : ""}`
    }

    if (!id) return null

    return (
        <Box>
            <List disablePadding style={{ marginBottom: "20px" }}>
                {filteredAddonFields?.map(f => (
                    <ListItem style={{ borderBottom: '1px solid lightgray' }} key={`${f.fieldName}-${f.dataType}`}>
                        {edit && edit.url === f.url ? <FieldsForm edit={edit} cancelEdit={() => setEdit(null)} /> : (
                            <>
                                <ListItemText primary={
                                    <Box display="flex" style={{ gap: '8px', alignItems: 'center' }}>
                                        <Typography variant='h6' style={{ fontWeight: 500, fontSize: 18 }}>{f.fieldName}</Typography>
                                        <Typography variant='body2' style={{ fontWeight: 400, borderRadius: '20px', border: '1px solid lightgray', padding: "2px 6px" }}>{addonFieldTypes.find(fr => fr.type === f.dataType)?.label ?? 'n/a'}</Typography>
                                    </Box>
                                }
                                    secondary={getDescription(f)}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton size='small' onClick={() => setEdit({ ...f, fieldType: f.dataType })}>
                                        <EditIcon fontSize='small' />
                                    </IconButton>
                                    <IconButton size='small' color='secondary' onClick={() => handlDelete(f?.id)}>
                                        <RemoveCircleOutlineIcon fontSize='small' />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </>
                        )}
                    </ListItem>
                ))}
            </List>
            <FieldsForm />
        </Box>
    )
}

export default React.memo(AdditionalField)