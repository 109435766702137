import React, { useEffect } from 'react';
import TempleDialog from './TempleDialog';
import { apiStatus } from 'shared/constants/AppEnum';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectTempleActivationApi, selectTempleDeleteApi, templeActivationThunk, templeDeleteThunk } from 'store/reducers/temple/templeSlice';
import { useSnackbar } from 'notistack';

interface TempleDialogWrapperProps{
    url: string
    open: boolean
    handleClose: () => void
}

const TempleDialogWrapper : React.FC<TempleDialogWrapperProps> = ({url, open, handleClose, children}) => {
    const dispatch = useAppDispatch()
    const deleteApi = useAppSelector(selectTempleDeleteApi)
    const activationApi = useAppSelector(selectTempleActivationApi)
    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        if (deleteApi.status === apiStatus.SUCCEEDED) {
            handleClose()
            enqueueSnackbar('Temple deleted successfully', { variant: 'success' })
        }
        else if (deleteApi.status === apiStatus.FAILED) {
            enqueueSnackbar('Failed deleting temple', { variant: 'error' })
        }
    }, [handleClose, deleteApi, enqueueSnackbar])
    const handleTempleDelete = (data: IdAndUrlType) => dispatch(templeDeleteThunk(data))
    const handleTempleActivation = (data: IdAndUrlType & { shouldActivate: boolean }) =>
        dispatch(templeActivationThunk(data))

    return (
        <React.Fragment>
            {children}
            {url && (
                <TempleDialog
                    open={open}
                    handleClose={handleClose}
                    handleDelete={handleTempleDelete}
                    handleActivation={handleTempleActivation}
                    deleteApi={deleteApi}
                    activationApi={activationApi}
                    url={url}
                />
            )}
        </React.Fragment>
    );
};

export default TempleDialogWrapper;