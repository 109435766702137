import IntlMessages from '@crema/utility/IntlMessages'
import { Box } from '@material-ui/core'
import { unwrapResult } from '@reduxjs/toolkit'
import GoBack from 'components/GoBack'
import FitContentPaper from 'components/Paper/FitContentPaper'
import { FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { RouteConfigComponentProps } from 'react-router-config'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { deityRetrieveThunk, deityUpdateThunk, selectDeityById, selectDeityStatus } from 'store/reducers/temple/deitySlice'
import { IOfferingResponse } from 'types/api/temple'
import { IDeityType } from 'types/store/temple'
import { getEditedPayload, getFieldErrors } from 'utils/api'
import { deityToForm } from 'utils/temple/deity'
import FormDeity from './FormDeity'
import { useSnackbar } from 'notistack';
import useRestricter from 'hooks/useRestricter'
import { PERMISSIONS, RESTRICTED_PATHS } from 'utils/permissions'
import { selectCurrentTempleOfferings } from 'store/reducers/temple/offeringSlice'

interface RouteParams {
    id: string | undefined
}

const EditDeity: React.FC<RouteConfigComponentProps<RouteParams>> = props => {
    const { id } = props.match.params
    const dispatch = useAppDispatch()
    const apiStatus = useAppSelector(selectDeityStatus)
    const { enqueueSnackbar } = useSnackbar();
    const deity = useAppSelector(state => selectDeityById(state, id ?? ''))
    const [deityOfferings, setDeityOfferings] = useState<IOfferingResponse[]>([])
    const [alerts, setAlerts] = useState<TqAlert[]>([])
    const offerings = useAppSelector(selectCurrentTempleOfferings)
    // const [formState, setFormState] = useState<IDeityType>()
    const hasEditPermission = useRestricter(PERMISSIONS.CHANGE, RESTRICTED_PATHS.DEITY)

    useEffect(() => {
        dispatch(deityRetrieveThunk({ id: id ?? '' }))
    }, [dispatch, id])

    useEffect(() => {
        if (deity)
            setDeityOfferings(offerings.filter(o => deity.offerings.includes(o.url)))
    }, [deity, offerings])

    // useEffect(() => {
    //     if (!formState && deity)
    //         setFormState(deityToForm(deity))
    // }, [deity, formState])

    const handleAddOfferings = (offerings: IOfferingResponse[]) => {
        setDeityOfferings(prev => [...prev, ...offerings])
        // if (deity)
        //     dispatch(deityAddOfferingThunk({ url: deity.url, offerings: offerings }))
        //         .then(unwrapResult)
        //         .then(() => enqueueSnackbar('Offerings added to deity', { variant: 'success' }))
        //         .catch(err => {
        //             let errors = getOfferingsError(err)
        //             if (errors.length > 0)
        //                 errors.forEach(offeringErr => {
        //                     enqueueSnackbar(offeringErr, { variant: 'error' })
        //                 })
        //             else enqueueSnackbar('Error occured while adding offerings', { variant: 'error' })
        //         })
    }


    const handleRemoveOfferings = (offerings: IOfferingResponse[]) => {
        const currentOfferings = deityOfferings.filter(obj1 => !offerings.some(obj2 => obj1.id === obj2.id))
        setDeityOfferings(currentOfferings)
        // if (deity)
        //     dispatch(deityAddOfferingThunk({ url: deity.url, offerings: currentOfferings }))
        //         .then(unwrapResult)
        //         .then(() => enqueueSnackbar('Offerings removed from deity', { variant: 'success' }))
        //         .catch(err => {
        //             let errors = getOfferingsError(err)
        //             if (errors.length > 0)
        //                 errors.forEach(offeringErr => {
        //                     enqueueSnackbar(offeringErr, { variant: 'error' })
        //                 })
        //             else enqueueSnackbar('Error occured while removing offerings', { variant: 'error' })
        //         })
    }

    const handleSubmit = async (data: IDeityType, actions: FormikHelpers<IDeityType>) => {
        setAlerts([])
        if (deity) {
            let payload = getEditedPayload(deityToForm(deity), data)
            if (Object.keys(payload).length > 0) {
                await dispatch(deityUpdateThunk({ url: deity.url, data: { ...data, offerings: deityOfferings.map(df => df.url) } }))
                    .then(unwrapResult)
                    .then(() => setAlerts(a => [...a, { message: `Deity updated`, severity: 'success' }]))
                    .catch(err => {
                        Object.entries(getFieldErrors(data, err)).forEach(([key, value]) =>
                            actions.setFieldError(key, value))
                    })
            }
        }
        actions.setSubmitting(false)
    }

    if (!hasEditPermission) return null

    return (
        <GoBack {...props}>
            <FitContentPaper>
                <Box component="h4" mb={4}><IntlMessages id="deity.edit.title" /></Box>
                <FormDeity
                    initialValues={deity}
                    offerings={deityOfferings}
                    handleAddOfferings={handleAddOfferings}
                    handleRemoveOfferings={handleRemoveOfferings}
                    api={apiStatus}
                    onSubmitCb={handleSubmit}
                    alerts={alerts}
                    buttonLabelId="common.update"
                />
            </FitContentPaper>
        </GoBack>
    )
}

export default EditDeity