import { makeStyles } from "@material-ui/core/styles";
import { CremaTheme } from "types/crema/AppContextPropsType";
import { Fonts } from 'shared/crema/constants/AppEnums';

const useStyles = makeStyles((theme: CremaTheme) => ({
    appBarRoot: {
        background: theme.palette.background.paper,
        color: theme.palette.primary.main,
        marginBottom: "5vh"
    },
    listItemRoot: {
        display: "flex",
        alignItems: "center",
        fontSize: "1rem",
        paddingLeft: "1.25rem",
        paddingRight: "1.25rem",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        transition: "all .15s linear",
        fontWeight: Fonts.REGULAR,
        color: theme.palette.primary.main,
        "& i": {
            marginRight: "0.25rem",
        },
        [theme.breakpoints.up("md")]: {
            marginRight: ".5rem",
            paddingLeft: ".5rem",
            paddingRight: ".5rem",
            color: theme.palette.primary.main,
            justifyContent: "center",
            "&:hover": {
                color: theme.palette.secondary.main,
            },
        },
    },
    headerImg: {
        verticalAlign: "middle",
        // borderStyle: "none",
        height: "35px"
    },
    menuPaper: {
        width: "calc(100% - 2rem)",
    },
    outlineNone: {
        outline: "none!important",
    },
    flexDirectionColumn: {
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
    toolbarContainer: {
        display: "flex!important",
        justifyContent: "space-between",
        alignItems: "center",
    },
}))

export default useStyles;
