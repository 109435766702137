import { createSlice } from '@reduxjs/toolkit'
import { Common } from 'types/crema/models/Common'

const initialState: Common = {
    error: '',
    loading: false,
    isAppDrawerOpen: false,
    updatingContent: false,
    message: '',
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        resetState(state){
            state = initialState
        },
        fetchStart(state){
            state = {...state, error: '', message: '', loading: true}
        },
        fetchSuccess(state){
            state.error = ''
            state.message = ''
            state.loading = false
            state.updatingContent = false
        },
        fetchError(state, action){
            const { error } = action.payload
            state.loading = false
            state.error = error
            state.message = ''
            state.updatingContent = false
        },
        showMessage(state, action){
            const { message } = action.payload
            state.error = ''
            state.message = message
            state.loading = false
            state.updatingContent = false
        },
        onToggleAppDrawer(state){
            state.isAppDrawerOpen = !state.isAppDrawerOpen
        },
        hideMessage(state){
            state.loading = false
            state.error = ''
            state.message = ''
            state.updatingContent = false
        }
    }
})


export const { 
    fetchStart,
    fetchError,
    fetchSuccess,
    hideMessage,
    showMessage,
    onToggleAppDrawer,
    resetState
} = commonSlice.actions

export default commonSlice.reducer