import IntlMessages from '@crema/utility/IntlMessages';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import FitContentPaper from 'components/Paper/FitContentPaper';
import TempleForm from 'containers/Dashboard/TempleProfile/TempleForm';
import React from 'react';
import { useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectTempleCreateApi } from 'store/reducers/temple/templeSlice';

interface RegisterTempleProps{

}

const RegisterTemple: React.FC<RegisterTempleProps> = (props) => {
    const createApi = useAppSelector(selectTempleCreateApi)
    const [success, setSuccess] = useState(false)
    const successCb = () => setSuccess(true)
    return (
        <Box mb={20} maxWidth='600px' ml="auto" mr="auto">
            <FitContentPaper>
                <Typography variant="h6" align="center">Register Temple</Typography>
                <TempleForm 
                    showMap={true} 
                    api={createApi} 
                    buttonLabelId='sidebar.temple.register' 
                    successCb={successCb}
                />
                {success &&  (
                    <Box mt="20px">
                        <Alert severity="success"><IntlMessages id="temple.registered"/></Alert>
                    </Box>
                )}
            </FitContentPaper>
        </Box>
    );
}

export default RegisterTemple;