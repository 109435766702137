import { Box, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { IRow, MemoizedBasicTable, StringNumberArrayType } from 'components/Table/BasicTable';
import { OfferingFilterForm } from 'containers/Dashboard/Offering/OfferingToolbar';
import usePermissionCheck from 'hooks/usePermissionCheck';
import React, { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {  selectDeityAsMapWithUrl } from 'store/reducers/temple/deitySlice';
import { selectOfferingCategoryAsMapWithUrl, TOfferingCategoryResponseMapType } from 'store/reducers/temple/offeringCategorySlice';
import { offeringUpdateThunk } from 'store/reducers/temple/offeringSlice';
import { IOfferingResponse, IOfferingTimeResponse } from 'types/api/temple';
import { capStr } from 'utils/fns';
import { PERMISSIONS, RESTRICTED_PATHS } from 'utils/permissions';
import { fmtBlockPeriod, getTimeChipText, offeringTimeToForm } from 'utils/temple/offering';

interface OfferingTableProps {
    editHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deleteHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deletingIds?: StringNumberArrayType,
    offerings: IOfferingResponse[],
    filterList?: OfferingFilterForm
}



const OfferingTable: React.FC<OfferingTableProps> = ({ editHandler, deleteHandler, deletingIds, offerings, filterList }) => {
    const dispatch = useAppDispatch()
    const hasEditPermission = usePermissionCheck(RESTRICTED_PATHS.OFFERINGS, PERMISSIONS.CHANGE)
    const categoryMap = useAppSelector(selectOfferingCategoryAsMapWithUrl)
    const deityMap = useAppSelector(selectDeityAsMapWithUrl)

    const getRows = useCallback((
        offerings: IOfferingResponse[],
        handleToggle: (offering: IOfferingResponse) => void,
        categoryMap: TOfferingCategoryResponseMapType,
        filterList?: OfferingFilterForm
    ): IRow[] => {
        let rows: IRow[] = []
        offerings.forEach((offering,i) => {
            const row = {
                id: offering.id,
                values: [
                    i + 1,
                    offering.name,
                    categoryMap[offering?.category]?.name,
                    offering.price,
                    (offering.assignedDeities.map(d => deityMap[d]?.name)?.join(', ') ?? "N/A"),
                    capStr(offering.offeringType),
                    (offering.availableOffline && offering.availableOnline ? "All" : (offering.availableOffline ? "Counter, POS" : "App, Web")),
                    offering.offeringTimes?.length === 0 ? offering.perDayLimit ?? 'No limit' : 'N/a',
                    offering.blockPeriod && offering.blockUnit ? fmtBlockPeriod(offering.blockPeriod, offering.blockUnit) : 'N/a',

                    offering.offeringTimes ? (
                        <React.Fragment>
                            {offering.offeringTimes.map((offeringTime: IOfferingTimeResponse) => (
                                <React.Fragment key={offeringTime.id}>
                                    {getTimeChipText(offeringTimeToForm(offeringTime))}
                                    <br />
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ) : 'N/a',
                    
                ]
            }
            
                row.values.push(
                    <Box style={{display:'flex',gap:8,alignItems:'center'}}>
                        <Typography>{(offering.isDakshinaRequired && offering.minDakshinaAmount) ? offering.minDakshinaAmount : "N/A"}</Typography>
                       { (hasEditPermission) &&  <FormControlLabel
                            label={""}
                            checked={offering.isDakshinaRequired ? offering.isDakshinaRequired : false}
                            onChange={() => handleToggle(offering)}
                            control={
                                <Switch
                                    name={''}
                                />}
                        />}
                    </Box>)
            if (((!filterList?.category || offering.category === filterList?.category) && (!filterList?.deity || offering.assignedDeities.some(d => d === filterList.deity))))
                rows.push(row)
        })
        return rows
    }, [offerings, hasEditPermission])

    const handleToggle = useCallback(async (offering: IOfferingResponse) => {
        await dispatch(offeringUpdateThunk({
            url: offering.url,
            data: { isDakshinaRequired: !offering.isDakshinaRequired }
        }))
    }, [dispatch])

    const rows = useMemo(() => getRows(offerings, handleToggle, categoryMap, filterList), [offerings, handleToggle, categoryMap, filterList])

    const headRow = useMemo(() => {
        const rows = [
            'common.slNo',
            'common.name',
            'offeringCategory',
            'common.price',
            'offering.assignedDeities',
            'offering.offeringType',
            'offering.offeringMedium',
            'offering.perDayLimit',
            'offering.blockPeriod',
            'offering.time',
            'offering.minDakshinaAmount',
        ]
        // if (hasEditPermission) rows.push('offering.isDakshinaRequired')
        return rows
    }, [hasEditPermission])

    return (
        <MemoizedBasicTable
            rows={rows}
            headRow={headRow}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            deletingIds={deletingIds}
        />
    );
};

export default OfferingTable;