import React, { useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import InfoView from '@crema/core/InfoView';
import Box from '@material-ui/core/Box';
import IntlMessages from '@crema/utility/IntlMessages';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { loginThunk, logoutThunk, registerThunk, selectAuth, setJwtToken } from 'store/reducers/auth/authSlice'
import { useStyles } from './styles'
import FormikTextField from 'components/Fields/FormikTextField';
import { Alert } from '@material-ui/lab';
import { extractError } from 'utils/api';
import { apiStatus } from 'shared/constants/AppEnum';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';
import { inviteApi } from 'api/temple';

const validationSchema = yup.object({
    email: yup
        .string()
        .email('Invalid Email')
        .required('Email required'),
    password: yup.string().required('Password is required'),
    confirm_password: yup.string()
        .oneOf([yup.ref('password'), ''], 'Passwords must match')
        .required('*Required'),
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required")
});


interface LocationState {
    email?: string
    token?: string,
    accept?: string
}

export default function NewAccount() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const auth = useAppSelector(selectAuth)
    const { messages } = useIntl();
    const classes = useStyles();
    const location = useLocation<LocationState>()
    const email = useMemo(() => location.state?.email, [location.state])
    const { enqueueSnackbar } = useSnackbar()
    const token = useMemo(() => location.state?.token, [location.state])
    const accept = useMemo(() => location.state?.accept, [location.state])

    useEffect(() => {
        if (!email) {
            enqueueSnackbar("You don't have proper access to this page", { variant: 'error' })
            history.push(`/user-invitaion?token=${token}`)
        }
    }, [email])

    if (!email) return null

    return (
        <Box className={classes.page}>
            <Box className={classes.container}>
                <Typography variant='h6' className={classes.title}>
                    Create new account to join
                </Typography>
                <Box
                    display='flex'
                    flexDirection='column'>
                    <Formik
                        initialValues={{
                            email,
                            password: '',
                            confirm_password: '',
                            first_name: '',
                            last_name: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data: IRegisterRequest) => {
                            dispatch(registerThunk(data)).then(unwrapResult).then(() => {
                                enqueueSnackbar("Successfully created account", { variant: 'success' })
                                inviteApi.accept(accept ?? "").then(() => {
                                    enqueueSnackbar("Successfully accepted invitation", { variant: 'success' })
                                    dispatch(logoutThunk())
                                    history.push('/login')
                                }).catch((err) => {
                                    console.error(err)
                                    enqueueSnackbar("Failed to accept, try again", { variant: 'error' })
                                })
                            }).catch(err => {
                                enqueueSnackbar(extractError(err), { variant: 'error' })
                            })
                        }}>
                        {() => (
                            <Form className={classes.formRoot} noValidate autoComplete='off'>
                                <Box mb={{ xs: 1, xl: 3 }}>
                                    <FormikTextField
                                        placeholder={messages['common.email']}
                                        name='email'
                                        label={<IntlMessages id='common.email' />}
                                        variant='outlined'
                                        fullWidth
                                        disabled
                                    />
                                </Box>

                                <Box mb={{ xs: 1, lg: 2 }}>
                                    <FormikTextField
                                        placeholder={messages['common.firstName']}
                                        label={<IntlMessages id='common.firstName' />}
                                        name='first_name'
                                        variant='outlined'
                                        fullWidth
                                    />
                                </Box>

                                <Box mb={{ xs: 1, lg: 2 }}>
                                    <FormikTextField
                                        placeholder={messages['common.lastName']}
                                        label={<IntlMessages id='common.lastName' />}
                                        name='last_name'
                                        variant='outlined'
                                        fullWidth
                                    />
                                </Box>

                                <Box mb={{ xs: 1, lg: 2 }}>
                                    <FormikTextField
                                        type='password'
                                        placeholder={messages['common.password']}
                                        label={<IntlMessages id='common.password' />}
                                        name='password'
                                        variant='outlined'
                                        fullWidth
                                    />
                                </Box>

                                <Box mb={{ xs: 1, lg: 2 }}>
                                    <FormikTextField
                                        type='password'
                                        placeholder={messages['common.retypePassword']}
                                        label={<IntlMessages id='common.retypePassword' />}
                                        name='confirm_password'
                                        variant='outlined'
                                        fullWidth
                                    />
                                </Box>

                                <Box
                                    display='flex'
                                    justifyContent="end"
                                >
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                        disabled={auth.status === apiStatus.LOADING}
                                    >
                                        Create account
                                    </Button>
                                </Box>
                                {auth.error && <Box mb={6}>
                                    <Alert severity="error">{extractError(auth.error)}</Alert>
                                </Box>}

                            </Form>
                        )}
                    </Formik>
                </Box>


            </Box>
        </Box>
    );
}