import React, { useEffect, useState } from 'react'
import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import GoBack from 'components/GoBack'
import { RouteConfigComponentProps } from 'react-router-config'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import FitContentPaper from 'components/Paper/FitContentPaper'
import { FormikHelpers } from 'formik'
import { getFieldErrors } from 'utils/api'
import { offeringAddThunk, selectOfferingStatus } from 'store/reducers/temple/offeringSlice'
import { IOfferingFormType } from 'types/store/temple'
import FormOffering from './FormOffering'
import { formToOffering } from 'utils/temple/offering'
import { unwrapResult } from '@reduxjs/toolkit'
import { selectCurrentTemple } from 'store/reducers/temple/templeSlice'
import { selectFeeSplitNameByContentObject } from 'store/reducers/report_app/feeSplitNameSlice'
import { FeeSplitConfigEntryType } from 'api/report_app/report_app.base'
import { feeSplitConfigCreateThunk } from 'store/reducers/report_app/feeSplitConfigSlice'
import usePermissionCheck from 'hooks/usePermissionCheck'
import { PERMISSIONS, RESTRICTED_PATHS } from 'utils/permissions'
import useRestricter from 'hooks/useRestricter'


const AddOffering: React.FC<RouteConfigComponentProps> = (props) => {
    const api = useAppSelector(selectOfferingStatus)
    const dispatch = useAppDispatch()
    const [alerts, setAlerts] = useState<TqAlert[]>([])
    const temple = useAppSelector(selectCurrentTemple)
    const feeSplitNames = useAppSelector(state => selectFeeSplitNameByContentObject(state, temple?.url ?? ''))
    const hasPermission = useRestricter(PERMISSIONS.ADD, RESTRICTED_PATHS.OFFERINGS)

    const handleSubmit = (data: IOfferingFormType, actions: FormikHelpers<IOfferingFormType>) => {
        setAlerts([])
        dispatch(offeringAddThunk(formToOffering(data)))
            .then(unwrapResult)
            .then(async response => {
                setAlerts(a => [
                    ...a,
                    { message: `Offering ${response.name} Successfully created`, severity: 'success' }
                ])
                if (data.splitEntries) {
                    const entries: FeeSplitConfigEntryType[] = []
                    for (const item of data.splitEntries) {
                        if (item.percent != "") {
                            entries.push({ name: item.name, percent: item.percent })
                        }
                    }
                    await dispatch(feeSplitConfigCreateThunk({
                        contentObject: response.url,
                        entries: entries
                    })).then(unwrapResult)
                        .catch(err => {
                            setAlerts(a => [
                                ...a,
                                { message: 'Error updating split fee', severity: 'error' }
                            ])
                        })
                }
                actions.resetForm()
            })
            .catch(err => {
                Object.entries(getFieldErrors(data, err)).forEach(([key, value]) => {
                    setAlerts(a => [
                        ...a,
                        { message: 'Error adding offering', severity: 'error' }
                    ])
                    // actions.setFieldError(key, value))
                    // console.log([key, value])
                })
            })
            .finally(() => {
                actions.setSubmitting(false)
            })
    }

    if (!hasPermission) return null

    return (
        <GoBack {...props}>
            <FitContentPaper>
                <Box component="h4" mb={4}><IntlMessages id="offering.create" /></Box>
                <FormOffering
                    feeSplitNames={feeSplitNames}
                    temple={temple}
                    buttonLabelId="common.create"
                    api={api}
                    onSubmitCb={handleSubmit}
                    alerts={alerts}
                />
            </FitContentPaper>
        </GoBack>
    )
}

export default AddOffering