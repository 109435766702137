import {makeStyles} from '@material-ui/core';
import {orange} from '@material-ui/core/colors';
import {CremaTheme} from 'types/crema/AppContextPropsType';
import {Fonts, ThemeMode} from 'shared/crema/constants/AppEnums';

const useStyles = makeStyles((theme: CremaTheme) => {
    return {
      crHeaderUser: {
        backgroundColor: (props: {header: boolean; themeMode: ThemeMode}) =>
          props.header ? 'transparent' : 'rgba(0,0,0,.08)',
        paddingTop: 9,
        paddingBottom: 9,
        minHeight: 56,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
          paddingTop: 10,
          paddingBottom: 10,
          minHeight: 70,
        },
      },
      profilePic: {
        fontSize: 24,
        backgroundColor: orange[500],
      },
      userInfo: {
        width: (props: {header: boolean; themeMode: ThemeMode}) =>
          !props.header ? 'calc(100% - 75px)' : '100%',
      },
      userName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        fontSize: 15,
        fontWeight: Fonts.MEDIUM,
        color: (props: {header: boolean; themeMode: ThemeMode}) =>
          props.themeMode === ThemeMode.DARK || !props.header
            ? 'white'
            : '#313541',
      },
      pointer: {
        cursor: 'pointer',
      },
    };
  });

  export default useStyles