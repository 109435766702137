
import { FormControlLabel, Switch } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react'

interface FormikSwitchFieldProps {
  name: string,
  label: string
}

const FormikSwitchField: React.FC<FormikSwitchFieldProps> = ({ name, label }) => {
  const [field, meta, helpers] = useField(name);
  // eslint-disable-next-line
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <FormControlLabel
      label={label}
      checked={field.value ? field.value : false}
      onChange={e => helpers.setValue(!field.value)}
      control={
        <Switch
          name={field.name}
        />}
    />
  )
}

export default FormikSwitchField