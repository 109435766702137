import { Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { Redirect } from 'react-router-dom';

const Staff : React.FC<RouteConfigComponentProps> = ({route, location}) => {
    const {pathname} = location
    return (
        <Container>
            <Box marginBottom={20}>
                {/^\/staff\/?$/gm.test(pathname) ?
                    <Redirect to="/staff/dashboard" /> :
                    renderRoutes(route?.routes)
                }
            </Box>
        </Container>
    );
};

export default Staff;