import React, { useMemo } from 'react';
import { Form, Formik, FormikHelpers } from 'formik'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import { extractError, getEditedPayload, isFieldError } from 'utils/api'
import * as yup from 'yup'
import { ITempleResponse } from 'types/api/temple';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { IApiState } from 'store/types';
import { apiStatus } from 'shared/constants/AppEnum';
import FormikTextField from 'components/Fields/FormikTextField';
import MenuItem from '@material-ui/core/MenuItem';

export type ComplianceFormType = {
    tds: number,
    commissionPercent: ''|number,
    commissionMin: ''|number,
    commissionMax: ''|number,
}

const getInitialValues = (temple?: ITempleResponse): ComplianceFormType => {
    return {
        tds: temple?.tds ?? 0,
        commissionPercent: temple && (temple.commissionPercent ||  temple.commissionPercent === 0) ? temple.commissionPercent : '',
        commissionMin: temple?.commissionMin ?? '',
        commissionMax: temple?.commissionMax ?? ''
    }
}

const formToTemple = (formValues: ComplianceFormType): Partial<ITempleResponse> => {
    return {
        tds: formValues.tds,
        commissionPercent: formValues.commissionPercent || formValues.commissionPercent === 0 ? formValues.commissionPercent : null,
        commissionMin: formValues.commissionMin ? formValues.commissionMin : null,
        commissionMax: formValues.commissionMax ? formValues.commissionMax : null,
    }
}


interface ComplianceFormProps {
    temple?: ITempleResponse
    handleSubmit: (values: Partial<ITempleResponse>, actions: FormikHelpers<ComplianceFormType>) => void
    api: IApiState
}

const ComplianceForm: React.FC<ComplianceFormProps> = ({ temple, handleSubmit, api }) => {
    const { messages } = useIntl()
    const initialValues = useMemo(() => getInitialValues(temple), [temple])

    const validationSchema = yup.object({
        tds: yup.number()
            .required('TDS is required'),
        commissionPercent: yup.number().min(0).max(100),
        commissionMin: yup.number(),
        commissionMax: yup.number().when('commissionMin', (commMin: any, schema: any) => {
            if(commMin !== '')
                return schema.min(commMin)
            return schema
          })
    })

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                handleSubmit(getEditedPayload(formToTemple(initialValues), formToTemple(values)), actions)
            }}
            enableReinitialize
        >
            {({ isValid, dirty, isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <Grid container spacing={2}>
                        
                        <Grid item xs={12}>
                            <FormikTextField
                                select
                                name='tds'
                                variant='outlined'
                                required
                                fullWidth
                                placeholder={messages['compliance.tds']}
                                label={messages['compliance.tds']}
                                size="small"
                            >
                                {[0,1,5,20].map((val, index) => (
                                    <MenuItem key={index} value={val}>
                                        {val}%
                                    </MenuItem>
                                ))}
                            </FormikTextField>
                            <FormikTextField
                                    placeholder={messages['commission.percent']}
                                    name='commissionPercent'
                                    label={messages['commission.percent']}
                                    variant='outlined'
                                    type='number'
                                    fullWidth
                                    size="small"
                                />
                            <FormikTextField
                                    placeholder={messages['commission.min']}
                                    name='commissionMin'
                                    label={messages['commission.min']}
                                    variant='outlined'
                                    type='number'
                                    fullWidth
                                    size="small"
                                />
                            <FormikTextField
                                    placeholder={messages['commission.max']}
                                    name='commissionMax'
                                    label={messages['commission.max']}
                                    variant='outlined'
                                    type='number'
                                    fullWidth
                                    size="small"
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={isSubmitting || !isValid || !dirty}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                {messages['common.save']}
                            </Button>
                        </Grid>
                        {api.status === apiStatus.FAILED && !isFieldError(api.error) && (
                            <Grid item xs={12}>
                                <Alert severity="error">{extractError(api.error)}</Alert>
                            </Grid>
                        )}
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default ComplianceForm;