import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import { DeepPartial, unwrapResult } from '@reduxjs/toolkit'
import FormikTextField from 'components/Fields/FormikTextField'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { apiStatus } from 'shared/constants/AppEnum'
import { useAppDispatch } from 'store/hooks'
import { createTempleThunk, templeUpdateThunk } from 'store/reducers/temple/templeSlice'
import { ITempleResponse } from 'types/api/temple'
import { IApiState } from 'store/types'
import { extractError, getEditedPayload, getFieldErrors, isFieldError } from 'utils/api'
import * as yup from 'yup'
import { FormikPhoneField } from 'components/Fields/FormikPhoneField'
import TempleLocation from './TempleLocation'
import { useState } from 'react'
import { Box, Checkbox, Divider, FormControlLabel, Typography } from '@material-ui/core'
import { countryCodeRegex, phoneNoRegex } from 'shared/constants/AppConst'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { ITempleFormType } from 'types/store/temple'
import { dateToUTCTimeString, timeStringToUTCDate } from 'utils/fns'


interface TempleFormProps {
    temple?: ITempleResponse
    showMap?: boolean
    api: IApiState
    buttonLabelId?: string
    successCb?: () => void
}

const getInitialValues = (temple?: ITempleResponse): ITempleFormType => {
    return {
        name: temple?.name ?? '',
        phone: temple?.phone ?? '',
        email: temple?.email ?? '',
        contact_no_1: temple?.contact_no_1 ?? '',
        contact_no_2: temple?.contact_no_2 ?? '',
        openingTime: temple?.openingTime ? timeStringToUTCDate(temple.openingTime, 1) : '',
        closingTime: temple?.closingTime ? timeStringToUTCDate(temple.closingTime) : '',
        address: {
            address_line_1: temple?.address?.address_line_1 ?? '',
            address_line_2: temple?.address?.address_line_2 ?? '',
            city: temple?.address?.city ?? '',
            district: temple?.address?.district ?? '',
            state: temple?.address?.state ?? '',
            pincode: temple?.address?.pincode ?? ''
        },
        description: temple?.description ?? '',
        whatsappNo: temple?.whatsappNo ?? '',
        dakshinaInConsolidation: temple?.dakshinaInConsolidation ?? false
    }
}

const formToTemple = (values: ITempleFormType): DeepPartial<ITempleResponse> => {
    return {
        ...values,
        whatsappNo:countryCodeRegex.test(values.whatsappNo ?? "") ? '' : values.whatsappNo,
        contact_no_1:countryCodeRegex.test(values.contact_no_1 ?? "") || values.contact_no_1 === '' ? null : values.contact_no_1,
        contact_no_2:countryCodeRegex.test(values.contact_no_2 ?? "") || values.contact_no_2 === '' ? null : values.contact_no_2,
        openingTime: values.openingTime ? dateToUTCTimeString(values.openingTime) : null,
        closingTime: values.closingTime ? dateToUTCTimeString(values.closingTime) : null,
    }
}

const TempleForm: React.FC<TempleFormProps> =
    ({ temple, api, showMap = false, buttonLabelId = 'common.save', successCb }) => {
        const { messages } = useIntl()
        const initialValues = useMemo(() => getInitialValues(temple), [temple])
        const dispatch = useAppDispatch()
        const { enqueueSnackbar } = useSnackbar()
        const [latlng, setLatLng] = useState<{ lat: number, lng: number }>()
        const [latlngErr, setLatLngErr] = useState('')

        const mapCb = (lat: number, lng: number) => {
            setLatLngErr('')
            setLatLng({ lat: lat, lng: lng })
        }

        const validationSchema = yup.object({
            name: yup.string().required(),
            email: yup.string().required().email(),
            address: yup.object({
                address_line_1: yup.string().required(),
                address_line_2: yup.string(),
                city: yup.string().required(),
                state: yup.string().required(),
                pincode: yup.string()
                    .required()
                    .length(6, 'Only 6 digits are allowed')
                    .test('is-digits', 'Pincode should have digits only', (value) => /^\d+$/.test(value ?? ''))
            }),
            phone: yup.string()
                .test('invalid-phone-no', 'Invalid phone number', (value) => phoneNoRegex.test(value ?? '')),
            contact_no_1: yup.string()
                .test('invalid-phone-no', 'Invalid phone number', (value) => value && value !== '' ? phoneNoRegex.test(value) || countryCodeRegex.test(value) : true),
            contact_no_2: yup.string()
                .test('invalid-phone-no', 'Invalid phone number', (value) => value ? phoneNoRegex.test(value) || countryCodeRegex.test(value) : true),
            whatsappNo: yup.string()
                .test('invalid-phone-no', 'Invalid phone number', (value) => value && value !== '' ? phoneNoRegex.test(value) || countryCodeRegex.test(value) : true),
            openingTime: yup.date(),
            closingTime: yup.date().min(yup.ref('openingTime'), 'Closing time can\'t be before starting time'),
            description: yup.string(),
            dakshinaInConsolidation:yup.boolean()
        })


        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    if (temple?.url) {
                        let data = getEditedPayload(formToTemple(initialValues), formToTemple(values))
                        dispatch(templeUpdateThunk({ url: temple.url, data: data }))
                            .then(unwrapResult)
                            .then(() => {
                                enqueueSnackbar('Temple Updated', { variant: 'success' })
                                actions.resetForm()
                            })
                            .catch(err => {
                                Object.entries(getFieldErrors(data, err)).forEach(([key, value]) =>
                                    actions.setFieldError(key, value))
                            })
                            .finally(() => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        if (!latlng) {
                            setLatLngErr('Location is required')
                            actions.setSubmitting(false)
                        }
                        else dispatch(createTempleThunk({
                            ...formToTemple(values),
                            ...{ location: { latlng: { latitude: latlng.lat, longitude: latlng.lng } } }
                        } as ITempleResponse))
                            .then(unwrapResult)
                            .then(() => {
                                if (successCb) successCb()
                                actions.resetForm()
                            })
                            .catch(err => {
                                Object.entries(getFieldErrors(values, err)).forEach(([key, value]) =>
                                    actions.setFieldError(key, value))
                            })
                            .finally(() => {
                                actions.setSubmitting(false)
                            })
                    }
                }}
            >
                {({ isValid, dirty, isSubmitting, setFieldValue, values, errors }) => (
                    <Form noValidate autoComplete='off'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormikTextField
                                    name='name'
                                    variant='outlined'
                                    required
                                    fullWidth
                                    placeholder={messages['temple.name']}
                                    label={messages['temple.name']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField
                                    name='email'
                                    variant='outlined'
                                    required
                                    fullWidth
                                    placeholder={messages['common.email']}
                                    label={messages['common.email']}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <KeyboardTimePicker
                                    label={messages['temple.openingTime']}
                                    onChange={time => setFieldValue('openingTime', time ?? '')}
                                    value={values.openingTime === '' ? null : values.openingTime}
                                    // format="hh:mm aa"
                                    mask="__:__ _M"
                                    inputVariant="outlined"
                                    size="medium"
                                    placeholder="hh:mm AM/PM"
                                    error={errors.openingTime !== undefined}
                                    helperText={errors.openingTime}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <KeyboardTimePicker
                                    label={messages['temple.closingTime']}
                                    onChange={time => setFieldValue('closingTime', time ?? '')}
                                    value={values.closingTime === '' ? null : values.closingTime}
                                    // format="hh:mm aa"
                                    mask="__:__ _M"
                                    inputVariant="outlined"
                                    size="medium"
                                    placeholder="hh:mm AM/PM"
                                    error={errors.closingTime !== undefined}
                                    helperText={errors.closingTime}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormikTextField
                                    name='address.address_line_1'
                                    variant='outlined'
                                    required
                                    fullWidth
                                    placeholder={messages['address.addressLine1']}
                                    label={messages['address.addressLine1']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField
                                    name='address.address_line_2'
                                    variant='outlined'
                                    fullWidth
                                    placeholder={messages['address.addressLine2']}
                                    label={messages['address.addressLine2']}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormikTextField
                                    name='address.city'
                                    variant='outlined'
                                    required
                                    fullWidth
                                    placeholder={messages['address.city']}
                                    label={messages['address.city']}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormikTextField
                                    name='address.state'
                                    variant='outlined'
                                    required
                                    fullWidth
                                    placeholder={messages['address.state']}
                                    label={messages['address.state']}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormikTextField
                                    name='address.district'
                                    variant='outlined'
                                    required
                                    fullWidth
                                    placeholder={messages['address.district']}
                                    label={messages['address.district']}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormikTextField
                                    name='address.pincode'
                                    variant='outlined'
                                    required
                                    fullWidth
                                    placeholder={messages['address.pincode']}
                                    label={messages['address.pincode']}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikPhoneField
                                    name="phone"
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikPhoneField
                                    name="contact_no_1"
                                    label="Contact No. 1"
                                    required={false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikPhoneField
                                    name="contact_no_2"
                                    label="Contact No. 2"
                                    required={false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikPhoneField
                                    name="whatsappNo"
                                    label="Whatsapp No."
                                    required={false}
                                />
                            </Grid>
                            <FormControlLabel control={<Checkbox
                                onChange={(e) => { setFieldValue('dakshinaInConsolidation', e.target.checked) }}
                                checked={values.dakshinaInConsolidation}
                                color='primary'
                            />} label="Enable Dakshina in Consolidation Reports" />

                            <Grid item xs={12}>
                                <FormikTextField
                                    name="description"
                                    label="About Temple"
                                    variant='outlined'
                                    fullWidth
                                    multiline
                                    rowsMax={10}
                                />
                            </Grid>

                            {showMap && (
                                <Grid item xs={12}>
                                    <Box mb={6} mt={3}>
                                        <TempleLocation cb={mapCb} />
                                        {latlngErr && (
                                            <Alert style={{ marginTop: '20px' }} severity="error">{latlngErr}</Alert>
                                        )}
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Box alignContent="flex-end">
                                    <Button
                                        style={{ float: 'right' }}
                                        disabled={isSubmitting || !isValid || !dirty}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        {messages[buttonLabelId]}
                                    </Button>
                                </Box>
                            </Grid>
                            {api.status === apiStatus.FAILED && !isFieldError(api.error) && (
                                <Grid item xs={12}>
                                    <Alert severity="error">{extractError(api.error)}</Alert>
                                </Grid>
                            )}
                        </Grid>
                    </Form>
                )}
            </Formik>
        )
    }

export default TempleForm