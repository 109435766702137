import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { logoutThunk, selectUser } from "store/reducers/auth/authSlice";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Redirect, useHistory, useLocation } from "react-router";
import { useUrlSearchParams } from "use-url-search-params";
import { inviteApi } from "api/temple";

export type UserInvitation = {
    id: number
    temple: string
    userExists: boolean
    status: string
    email: string
    role: string
    createdAt: string
    updatedAt: string
    validTill: string
    accept: string
    reject: string
}

export default function UserInvitation() {
    const classes = useStyles()
    const user = useAppSelector(selectUser)
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory();
    const [invitation, setInvitation] = useState<UserInvitation>()

    const initailValue: InitialType = {
        token: ''
    };
    const types: TypesType = {
        token: String
    };
    const [params] = useUrlSearchParams(initailValue, types);

    const dispatch = useAppDispatch()

    useEffect(() => {
        let timeoutId: any = null

        if (!params?.token) {
            enqueueSnackbar("Invalid invitation link", { variant: 'error' })
            history.push('/404')
        } else if (params?.token) {
            inviteApi.get(params.token as string).then(res => res.data).then(data => {
                if (!data) {
                    enqueueSnackbar("Invalid invitation link", { variant: 'error' })
                    dispatch(logoutThunk())
                    history.push('/')
                }
                
                setInvitation(data)
            }).catch(err => {
                enqueueSnackbar("Invalid invitation link", { variant: 'error' })
                history.push('/')
            })
        }

        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [params, user])

    const handleAccept = () => {
        if (invitation?.userExists) {
            inviteApi.accept(invitation?.accept ?? "").then(() => {
                enqueueSnackbar("Successfully accepted invitation", { variant: 'success' })
                history.push('/')
            }).catch((err) => {
                enqueueSnackbar("Failed to accept, try again", { variant: 'error' })
                dispatch(logoutThunk())
                history.push('/')
            })
        } else {
            if (invitation)
                history.push('/user-invitation/new-account', { email: invitation.email, token: params?.token, accept: invitation?.accept })
        }

    }

    const handleReject = () => {
        inviteApi.reject(invitation?.reject ?? "").then(() => {
            enqueueSnackbar("Successfully rejected invitation", { variant: 'success' })
            history.push('/')
        }).catch((err) => {
            console.error(err)
            enqueueSnackbar("Failed to reject, try again", { variant: 'error' })
        })
    }

    return (
        <Box className={classes.page}>
            <Box className={classes.container}>
                <Typography variant="h5" className={classes.title}>
                    Invitation to Temple Click
                </Typography>
                <Typography variant="body1" className={classes.body}>
                    You are invited as a temple moderator to temple click, you can accept or reject the invitation
                </Typography>
                <Box className={classes.actions}>
                    <Button variant="contained" disableElevation color="secondary" onClick={handleReject}>Reject</Button>
                    <Button variant="contained" disableElevation color="primary" onClick={handleAccept}>Accept</Button>
                </Box>
            </Box>
        </Box>
    )
}