import { IReceiptRow, ReceiptFilterArgsType, ReceiptRequestTypes } from "store/reducers/temple/receiptSlice/types";
import { format as dateFormat } from 'date-fns'
import { apiDateFormat } from "shared/constants/AppConst";
import _ from 'lodash'
import snakecaseKeys from "snakecase-keys";
import { OfferingFinancialFilterType } from "store/reducers/temple/offeringFinancialSlice";
import { DonationReceiptFilterArgsType } from "store/reducers/temple/donationReceiptSlice/types";

const _buildOrderBy = (params: string[], asc: boolean) => 
    params.map((val) => `${asc ? '' : '-'}${_.snakeCase(val)}`).join(',')

const buildOfferingOrderBy = (by: keyof IReceiptRow, asc: boolean) => {
    const params = by === 'time' ? ['fromTime', 'toTime'] : [by]
    return _buildOrderBy(params, asc)
}


const _processReceiptFilterParams = (
    requestType: ReceiptRequestTypes, 
    args: {[key: string]: any},
    filters: {[key: string]: any},
    omitFilterParams: string[],
    setDefault: boolean = true
) => {
    if(requestType !== 'list'){
        omitFilterParams.push('pageSize')
        if(setDefault && !args.dateAfter && !args.dateBefore && !args.bookingDateAfter && !args.bookingDateBefore) 
            args.dateAfter =  dateFormat(new Date(), apiDateFormat)
    }
    Object.entries(args)
        .filter(([key, _]) => !omitFilterParams.includes(key))
        .forEach(([key, value]) => filters[key] = value)
    return snakecaseKeys(filters)
}


export const configureOfferingReceiptFilterParams = (requestType: ReceiptRequestTypes, args: ReceiptFilterArgsType) => 
    _processReceiptFilterParams(
        requestType, 
        args, 
        {'orderBy': buildOfferingOrderBy(args.orderBy, args.asc)}, 
        ['orderBy', 'asc']
    )


export const configureOfferingFinancialFilterParams = (requestType: ReceiptRequestTypes, args:OfferingFinancialFilterType) =>
    _processReceiptFilterParams(
        requestType, args, {}, ['orderBy', 'asc'], false
    )


export const configureDonationReceiptFilterParams = (requestType: ReceiptRequestTypes, args: DonationReceiptFilterArgsType) => 
    _processReceiptFilterParams(
        requestType, 
        args, 
        {'orderBy': _buildOrderBy([args.orderBy], args.asc)}, 
        ['orderBy', 'asc']
    )