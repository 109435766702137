// import React from 'react';
import Login from 'containers/Auth/Login'

// export const authRouteConfig = [
//   {
//     routes: [
//       {
//         path: '/login',
//         // component: React.lazy(() => import('containers/Auth/Login')),
//         component: Login
//       },
//     ],
//   },
//   {
//     routes: [
//       {
//         path: '/signup',
//         component: React.lazy(() => import('./Signup')),
//       },
//     ],
//   },
//   {
//     routes: [
//       {
//         path: '/forget-password',
//         component: React.lazy(() => import('./ForgetPassword')),
//       },
//     ],
//   },
//   {
//     routes: [
//       {
//         path: '/error-pages/error-404',
//         component: React.lazy(() => import('../errorPages/Error404/index')),
//       },
//     ],
//   },
// ];

export const authRouteConfig = [
  {
    path: '/login',
    // component: React.lazy(() => import('containers/Auth/Login')),
    component: Login
  },
]
