import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import React from 'react'

interface FormBlockProps {
    titleId: string
}
const FormBlock : React.FC<FormBlockProps> = ({titleId, children}) => {
    return (
        <Box mb={8}>
            <Box component="h3" fontSize="1rem" mb={4}>
                <IntlMessages id={titleId} />
            </Box>
            <Divider style={{ marginBottom: "1.5rem" }} />
            {children}
        </Box>
    )
}

export default FormBlock