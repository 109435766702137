import { OrderResponseType } from 'types/api/order'
import { DonationReceiptResponseType, ReceiptResponseType } from 'types/api/receipt'
import { BankAccountType, IDeityResponse, IDonationCategoryResponse, IDonationResponse, IOfferingCategoryResponse, IOfferingResponse, IOfferingTimeResponse, ITempleResponse, ParamWithUrlType, TUpdatePermissionsPayload, TUserPermissions, UsersResponseType } from 'types/api/temple'
import { IDeityType, IDonation, IDonationCategory, IDonationMetaType, IOffering, IOfferingCategory, IOfferingMetaType, IOfferingTime, TempleImgType } from 'types/store/temple'
import { getLocalCurrentTemple } from 'utils/temple'
import { apiInstance, deleteWithUrl, uploadInstance } from '../client'
import { DeepPartial } from '@reduxjs/toolkit'
import { objectToFormData } from 'utils/api'
import { ReceiptFilterArgsType } from 'store/reducers/temple/receiptSlice/types'
import { configureDonationReceiptFilterParams, configureOfferingFinancialFilterParams, configureOfferingReceiptFilterParams } from './fns'
import { IOfferingFinancialResponseType } from 'types/api/offeringFinancial'
import { OfferingFinancialFilterType } from 'store/reducers/temple/offeringFinancialSlice'
import { DonationReceiptFilterArgsType } from 'store/reducers/temple/donationReceiptSlice/types'
import { UserInvitation } from 'containers/UserInvitation'
import { TInviteForm } from 'store/reducers/temple/usersSlice'
import { IAddonResponse, TAddonPayload } from 'containers/Dashboard/Bookable/AdditionalFields'

const basePath = 'temple/t-admin'
const orderBasePath = 'order/t-admin'

export const getTempleId = (id?: string | number) => id ? id : getLocalCurrentTemple()


const getOCUrl = () => `${basePath}/offering-category`
const getOfferingUrl = () => `${basePath}/offering`
const getDeityUrl = () => `${basePath}/deity`
const getOrderUrl = () => `${basePath}/orders`
const getReceiptUrl = () => `${orderBasePath}/offering-order`
const getDonationReceiptUrl = () => `${orderBasePath}/donation-order`
const getDCUrl = () => `${basePath}/donation-category`
const getDonationUrl = () => `${basePath}/donation`
const getMarkAsCompleteUrl = () => `${orderBasePath}/offering-order`

const offeringAddonUrl = `${basePath}/offering-addon-field/`


const templeAPI = {
    delete: async(url: string) => await apiInstance.delete(url, { templeId: getTempleId() }),
    list: async () => await apiInstance.get<ITempleResponse[]>(`${basePath}/`, { templeId: getTempleId() }),
    get: async (params?: ParamWithUrlType) => {
        if (params) {
            return await apiInstance.get<ITempleResponse>(
                'url' in params ? params.url :
                    `${basePath}/${params.itemId}/`,
                { templeId: getTempleId() }
            )
        } else {
            return await apiInstance.get<ITempleResponse>(`${basePath}/`, { templeId: getTempleId() })
        }
    },
    update: async (url: string, data: DeepPartial<ITempleResponse>) =>
        await apiInstance.patch<ITempleResponse>(url, data, { templeId: getTempleId(data?.id) }),
    uploadImg: async (url: string, type: TempleImgType, file: File, templeId: string | number) => {
        const formData = new FormData()
        formData.append(`${type}_img`, file)
        return await uploadInstance.put<Partial<ITempleResponse>>(`${url}img/${type}/`, formData, { templeId: getTempleId(templeId) })
    },
    getBankAccount: async (url: string) =>
        await apiInstance.get<BankAccountType>(`${url}bank/`, { templeId: getTempleId() }),
    updateBankAccount: async (url: string, data: Partial<BankAccountType>) =>
        await uploadInstance.put<BankAccountType>(`${url}bank/`, objectToFormData(data), { templeId: getTempleId() }),
    createTemple: async (data: ITempleResponse) =>
        await apiInstance.post<ITempleResponse>(`${basePath}/`, data)
}

export const offeringCategoryAPI = {
    delete: async(url: string) => await apiInstance.delete(url, { templeId: getTempleId() }),
    list: async (templeId: string | number) =>
        await apiInstance.get<IOfferingCategoryResponse[]>(`${getOCUrl()}/`, { templeId: getTempleId(templeId) }),
    get: async (params: ParamWithUrlType, templeId: string | number) =>
        await apiInstance.get<IOfferingCategoryResponse>(
            'url' in params ? params.url :
                `${getOCUrl()}/${params.itemId}/`,
            { templeId: getTempleId(templeId) }
        ),
    post: async (data: IOfferingCategory, templeId: string | number) =>
        await apiInstance.post<IOfferingCategoryResponse>(`${getOCUrl()}/`, data, { templeId: getTempleId(templeId) }),
    update: async (url: string, data: Partial<IOfferingCategory>) =>
        await apiInstance.patch<IOfferingCategoryResponse>(url, data, { templeId: getTempleId() })
}

export const offeringAPI = {
    delete: async(url: string) => await apiInstance.delete(url, { templeId: getTempleId() }),
    list: async (templeId: string | number) =>
        await apiInstance.get<IOfferingResponse[]>(`${getOfferingUrl()}/`, { templeId: getTempleId(templeId) }),
    get: async (params: ParamWithUrlType, templeId: string | number) =>
        await apiInstance.get<IOfferingResponse>(
            'url' in params ? params.url :
                `${getOfferingUrl()}/${params.itemId}/`,
            { templeId: getTempleId(templeId) }
        ),
    post: async (data: IOffering, templeId: string | number) =>
        await apiInstance.post<IOfferingResponse>(`${getOfferingUrl()}/`, data, { templeId: getTempleId(templeId) }),
    update: async (url: string, data: Partial<IOffering>) =>
        await apiInstance.patch<IOfferingResponse>(url, data, { templeId: getTempleId() }),
    addOfferingMeta: async (url: string, data: IOfferingMetaType) =>
        await apiInstance.patch<IOfferingResponse>(`${url}offering_meta/add/`, data, { templeId: getTempleId() }),
    removeOfferingMeta: async (url: string, data: IOfferingMetaType) =>
        await apiInstance.patch<IOfferingResponse>(`${url}offering_meta/remove/`, data, { templeId: getTempleId() }),
    addOfferingTime: async (url: string, data: IOfferingTime) =>
        await apiInstance.post<IOfferingTimeResponse>(`${url}offering_times/`, data, { templeId: getTempleId() }),
    updateOfferingTime: async (url: string, data: Partial<IOfferingTime>) =>
        await apiInstance.patch<IOfferingTimeResponse>(url, data, { templeId: getTempleId() }),
    deleteOfferingTime: async (url: string) =>
        await apiInstance.delete(url, { templeId: getTempleId() })
}

export const offeringAddonAPI = {
    add:  async (field: TAddonPayload) =>
        await apiInstance.post<IAddonResponse>(offeringAddonUrl, field, { templeId: getTempleId() }),
    edit:  async ({ addonId, field }: { addonId: number, field: TAddonPayload }) =>
        await apiInstance.put<IAddonResponse>(`${offeringAddonUrl}${addonId}/`, field, { templeId: getTempleId() }),
    remove:  async (addonId: number) =>
        await apiInstance.delete<IAddonResponse>(`${offeringAddonUrl}${addonId}/`, { templeId: getTempleId() }),
    list:  async (offeringId: string) =>
        await apiInstance.get<IAddonResponse[]>(`${offeringAddonUrl}?offering=${offeringId}`, { templeId: getTempleId() }),
}

export const donationCategoryAPI = {
    list: async (templeId: string | number) =>
        await apiInstance.get<IDonationCategoryResponse[]>(`${getDCUrl()}/`, { templeId: getTempleId(templeId) }),
    get: async (params: ParamWithUrlType, templeId: string | number) =>
        await apiInstance.get<IDonationCategoryResponse>(
            `url` in params ? params.url :
                `${getDCUrl()}/${params.itemId}`,
            { templeId: getTempleId(templeId) }
        ),
    post: async (data: IDonationCategory, templeId: string | number) =>
        await apiInstance.post<IDonationCategoryResponse>(`${getDCUrl()}/`, data, { templeId: getTempleId(templeId) }),
    update: async (url: string, data: Partial<IDonationCategory>) =>
        await apiInstance.patch<IDonationCategoryResponse>(url, data),
    delete: async (url: string) =>
        await apiInstance.delete(url, { templeId: getTempleId() })
}

export const donationAPI = {
    list: async (templeId: string | number) =>
        await apiInstance.get<IDonationResponse[]>(`${getDonationUrl()}/`, { templeId: getTempleId(templeId) }),
    get: async (params: ParamWithUrlType, templeId: string | number) =>
        await apiInstance.get<IDonationResponse>(
            'url' in params ? params.url :
                `${getDonationUrl()}/${params.itemId}/`,
            { templeId: getTempleId(templeId) }
        ),
    post: async (data: IDonation, templeId: string | number) =>
        await apiInstance.post<IDonationResponse>(`${getDonationUrl()}/`, data, { templeId: getTempleId(templeId) }),
    update: async (url: string, data: Partial<IDonation>) =>
        await apiInstance.patch<IDonationResponse>(url, data, { templeId: getTempleId() }),
    delete: async (url: string) =>
        await apiInstance.delete(url, { templeId: getTempleId() }),
    addDonationMeta: async (url: string, data: IDonationMetaType) =>
        await apiInstance.patch<IDonationResponse>(`${url}donation_meta/add/`, data, { templeId: getTempleId() }),
    removeDonationMeta: async (url: string, data: IDonationMetaType) =>
        await apiInstance.patch<IDonationResponse>(`${url}donation_meta/remove/`, data, { templeId: getTempleId() }),
}


export const deityAPI = {
    delete: async(url: string) => await apiInstance.delete(url,{ templeId: getTempleId() }),
    list: async (templeId: string | number) =>
        await apiInstance.get<IDeityResponse[]>(`${getDeityUrl()}/`, { templeId: getTempleId(templeId) }),
    get: async (params: ParamWithUrlType, templeId: string | number) =>
        await apiInstance.get<IDeityResponse>(
            'url' in params ? params.url :
                `${getDeityUrl()}/${params.itemId}/`,
            { templeId: getTempleId(templeId) }
        ),
    update: async (url: string, data: Partial<IDeityType>) =>
        await apiInstance.patch<IDeityResponse>(url, data, { templeId: getTempleId() }),
    post: async (data: IDeityType, templeId: string | number) =>
        await apiInstance.post<IDeityResponse>(`${getDeityUrl()}/`, data, { templeId: getTempleId(templeId) }),
    addOfferings: async (id: string, offerings: (string)[]) =>
        await apiInstance.patch<IDeityResponse>(`${getDeityUrl()}/${id}/`, offerings, { templeId: getTempleId() }),
    removeOfferings: async (url: string, offerings: (number | string)[]) =>
        await apiInstance.patch<IDeityResponse>(`${url}offerings/remove/`, offerings, { templeId: getTempleId() }),
    uploadImg: async (url: string, file: File, templeId: string | number) => {
        const formData = new FormData()
        formData.append(`deity_img`, file)
        console.log(templeId)
        return await uploadInstance.put<Partial<ITempleResponse>>(`${url}img/`, formData, { templeId: getTempleId(templeId) })
    },
}

export const orderAPI = {
    list: async (templeId?: string | number) =>
        await apiInstance.get<OrderResponseType[]>(`${getOrderUrl()}/`, { templeId: getTempleId(templeId) })
}

export const receiptAPI = {
    list: async (params: ReceiptFilterArgsType, templeId?: string | number) =>
        await apiInstance.get<ReceiptResponseType[]>(
            `${getReceiptUrl()}/`,
            { params: configureOfferingReceiptFilterParams('list', params), templeId: getTempleId(templeId) }
        ),
    listWithUrl: async (url: string) =>
        await apiInstance.get<ReceiptResponseType[]>(url, { templeId: getTempleId() }),
    generatePDFDownloadUrl: async (params: ReceiptFilterArgsType, templeId?: string | number) =>
        await apiInstance.get<DownloadUrlResponseType>(
            `${getReceiptUrl()}/generate-download-link`,
            { params: configureOfferingReceiptFilterParams('pdf', params), templeId: getTempleId(templeId) }
        ),
    generatePriestDownloadUrl: async (params: ReceiptFilterArgsType, templeId?: string | number) =>
        await apiInstance.get<DownloadUrlResponseType>(
            `${getReceiptUrl()}/generate-priest-download-link`,
            { params: configureOfferingReceiptFilterParams('priestPDF', params), templeId: getTempleId(templeId) }
        ),
    get: async (params: ParamWithUrlType, templeId?: string | number) =>
        await apiInstance.get<ReceiptResponseType>(
            'url' in params ? params.url :
                `${getReceiptUrl()}/${params.itemId}/`,
            { templeId: getTempleId(templeId) }
        ),
    markAsComplete: async (data: MarkAsCompleteArgsType, templeId?: string | number,) =>
        await apiInstance.patch<ReceiptResponseType[]>(`${getMarkAsCompleteUrl()}/update/`, data, { templeId: getTempleId(templeId) }),
}

export const donationReceiptAPI = {
    list: async (params: DonationReceiptFilterArgsType, templeId?: string | number) =>
        await apiInstance.get<DonationReceiptResponseType[]>(
            `${getDonationReceiptUrl()}/`,
            { params: configureDonationReceiptFilterParams('list', params), templeId: getTempleId(templeId) }
        ),
    listWithUrl: async (url: string) =>
        await apiInstance.get<DonationReceiptResponseType[]>(url, { templeId: getTempleId() }),
    generatePDFDownloadUrl: async (params: DonationReceiptFilterArgsType, templeId?: string | number) =>
        await apiInstance.get<DownloadUrlResponseType>(
            `${getDonationReceiptUrl()}/generate_download_link`,
            { params: configureDonationReceiptFilterParams('pdf', params), templeId: getTempleId(templeId) }
        ),
    get: async (params: ParamWithUrlType, templeId?: string | number) =>
        await apiInstance.get<DonationReceiptResponseType>(
            'url' in params ? params.url :
                `${getDonationReceiptUrl()}/${params.itemId}/`,
            { templeId: getTempleId(templeId) }
        ),
}


export const offeringFinancialApi = {
    list: async (params: OfferingFinancialFilterType, templeId?: string | number) =>
        await apiInstance.get<IOfferingFinancialResponseType>(
            `order/t-admin/offering-order/financial`,
            { params: configureOfferingFinancialFilterParams('list', params), templeId: getTempleId(templeId) }
        ),
    // listWithUrl: async(url: string) =>
    //     await apiInstance.get<IOfferingFinancialResponseType[]>(url),
    generatePDFDownloadUrl: async (params: OfferingFinancialFilterType, templeId?: string | number) =>
        await apiInstance.get<DownloadUrlResponseType>(
            `order/t-admin/offering-order/generate-financial-download-link`,
            { params: {...configureOfferingFinancialFilterParams('pdf', params), file_type: 'pdf' }, templeId: getTempleId(templeId) }
        ),
    generateExcelUrl: async (params: OfferingFinancialFilterType, templeId?: string | number) =>
        await apiInstance.get<DownloadUrlResponseType>(
            `order/t-admin/offering-order/generate-financial-download-link`,
            { params: { ...configureOfferingFinancialFilterParams('xlsx', params), file_type: 'xlsx' }, templeId: getTempleId(templeId) }
        ),

}



export const inviteApi = {
    send: async (data: TInviteForm) =>
        await apiInstance.post(`${basePath}/admins/invite-user/`, data, { templeId: getTempleId() }),
    get: async (token: string) =>
        await apiInstance.get<UserInvitation>(`${basePath}/admins/invite/`, { params: { token } }),
    accept: async (token: string) =>
        await apiInstance.post(`${basePath}/admins/accept-invite/`, undefined, { params: { token } }),
    reject: async (token: string) =>
        await apiInstance.post(`${basePath}/admins/reject-invite/`, undefined, { params: { token } })
}

export const usersApi = {
    self: async (templeId: string | number) =>
        await apiInstance.get<UsersResponseType>(`${basePath}/admins/me`, { templeId }),
    list: async () =>
        await apiInstance.get<UsersResponseType[]>(`${basePath}/admins`, { templeId: getTempleId() }),
    permissions: async () =>
        await apiInstance.get<TUserPermissions>(`${basePath}/admins/list-permissions`, { templeId: getTempleId() }),
    updatePermissions: async ({ userId, data }: TUpdatePermissionsPayload) =>
        await apiInstance.patch<UsersResponseType>(`${basePath}/admins/${userId}/`, data, { templeId: getTempleId() }),
    delete: async (id:string) =>
        await apiInstance.delete(`${basePath}/admins/${id}`, { templeId: getTempleId() }),

}

export default templeAPI