import React from 'react';
import List from '@material-ui/core/List';

import { NavItemProps, dashboardConfig } from 'routes/navConfig';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import VerticalNavGroup from './VerticalNavGroup';
import TempleSelect from 'components/TempleSelect'
import { ListItem } from '@material-ui/core';
import { useAppSelector } from 'store/hooks';
import { selectCurrentTemple } from 'store/reducers/temple/templeSlice';
import { selectUser } from 'store/reducers/auth/authSlice';

interface NavigationProps { }

const Navigation: React.FC<NavigationProps> = () => {
  // const dispatch = useAppDispatch()
  const currentTemple = useAppSelector(selectCurrentTemple)
  const user = useAppSelector(selectUser)

  const getNavMenu = (item: NavItemProps) => (
    <React.Fragment key={item.id}>
      {item.type === 'group' && (item.id === 'temple' ?
        currentTemple && <VerticalNavGroup item={item} level={0} /> :
        <VerticalNavGroup item={item} level={0} />)
      }

      {item.type === 'collapse' && (
        <VerticalCollapse item={item} level={0} />
      )}

      {item.type === 'item' && <VerticalItem item={item} level={0} />}
    </React.Fragment>
  )

  return (
    <List>
      <React.Fragment>
        {user && user.role === 'user' && (
          <ListItem>
            <TempleSelect />
          </ListItem>
        )}
        {dashboardConfig.map((item: NavItemProps) => getNavMenu(item))}
      </React.Fragment>

    </List>
  );
};

export default Navigation;
