
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CremaTheme } from 'types/crema/AppContextPropsType';

export const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        link: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.secondary.main
            }
        }
    }),
);