import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import BankAccountCard from 'components/Temple/BankAccountCard';
import TempleAdminsCard from 'components/Temple/TempleAdminsCard';
import TempleInfoCard from 'components/Temple/TempleInfoCard';
import TempleLocationCard from 'components/Temple/TempleLocationCard';
import { FormikHelpers } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { mapsApiKey } from 'shared/constants/AppConst';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectBankAccountByUrl, selectBankAccountUpdateApi, staffBankRetrieveThunk, staffBankUpdateThunk } from 'store/reducers/temple/bankAccountSlice';
import { deityListThunk } from 'store/reducers/temple/deitySlice';
import { selectTempleByUrl, selectTempleUpdateApi, staffTempleRetrieveThunk, staffTempleUpdateThunk } from 'store/reducers/temple/templeSlice';
import { selectDeitiesByTempleId } from 'store/reducers/temple/deitySlice';
import { IApiState } from 'store/types';
import { BankAccountType, ITempleResponse } from 'types/api/temple';
import DeityListCard from 'components/Temple/DeityListCard';
import { offeringCategoryListThunk, selectOfferingCategoriesByTempleId } from 'store/reducers/temple/offeringCategorySlice';
import OfferingCategoryListCard from 'components/Temple/OfferingCategoryListCard';
import { offeringListThunk, selectOfferingsByTempleId } from 'store/reducers/temple/offeringSlice';
import OfferingListCard from 'components/Temple/OfferingListCard';
import ComplianceCard from 'components/Temple/ComplianceCard';
import { useSnackbar } from 'notistack';
import { ComplianceFormType } from 'components/Temple/ComplianceForm';
import TempleAdminTable from 'components/Temple/TempleAdminTable';

interface TempleProps {
    url: string,
    handleDelete: (data: IdAndUrlType) => void,
    deleteApi: IApiState,
    handleActivation: (data: IdAndUrlType & { shouldActivate: boolean }) => void,
    activationApi: IApiState,
}

const Temple: React.FC<TempleProps> = ({ url, deleteApi, handleDelete, handleActivation, activationApi }) => {
    const { enqueueSnackbar } = useSnackbar();
    const temple = useAppSelector(state => selectTempleByUrl(state, url))
    const bankAccount = useAppSelector(state => selectBankAccountByUrl(state, temple?.bankAccountUrl ?? ''))
    const dispatch = useAppDispatch()
    const bankAccountUpdateApi = useAppSelector(selectBankAccountUpdateApi)
    const templeUpdateApi = useAppSelector(selectTempleUpdateApi)
    const deities = useAppSelector(state => selectDeitiesByTempleId(state, temple?.id ?? ''))
    const offeringCategories = useAppSelector(state => selectOfferingCategoriesByTempleId(state, temple?.id ?? ''))
    const offerings = useAppSelector(state => selectOfferingsByTempleId(state, temple?.id ?? ''))
    useEffect(() => {
        dispatch(staffTempleRetrieveThunk({ url: url }))
    }, [dispatch, url])
    useEffect(() => {
        if (temple?.bankAccountUrl) {
            dispatch(staffBankRetrieveThunk({ url: temple.bankAccountUrl }))
        }
    }, [dispatch, temple?.bankAccountUrl])
    useEffect(() => {
        if (temple?.id) {
            dispatch(deityListThunk({ templeId: temple.id }))
            dispatch(offeringCategoryListThunk({ templeId: temple.id }))
            dispatch(offeringListThunk({ templeId: temple.id }))
        }
    }, [dispatch, temple?.id])
    const handleBankAccountSubmit = (values: Partial<BankAccountType>, actions: FormikHelpers<Partial<BankAccountType>>) => {
        if (temple?.bankAccountUrl)
            dispatch(staffBankUpdateThunk({ url: temple.bankAccountUrl, data: values }))
                .then(() => enqueueSnackbar('Bank account updated', { variant: 'success' }))
                .catch(() => enqueueSnackbar('Bank account updation failed, try again', { variant: 'error' }))
        actions.setSubmitting(false)
    }
    const handleTempleUpdate = (values: Partial<ITempleResponse>, actions: FormikHelpers<ComplianceFormType>) => {
        if (temple?.url)
            dispatch(staffTempleUpdateThunk({ url: temple.url, data: values }))
                .then(() => enqueueSnackbar('Compliances updated', { variant: 'success' }))
                .catch(() => enqueueSnackbar('Compliances updation failed, try again', { variant: 'error' }))
        actions.setSubmitting(false)
    }
    return (
        <Container>
            <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                    <Grid container direction="column" spacing={4}>
                        <Grid item>
                            {temple && (
                                <TempleInfoCard
                                    temple={temple}
                                    handleDelete={handleDelete}
                                    deleteApi={deleteApi}
                                    handleActivation={handleActivation}
                                    activationApi={activationApi}
                                />
                            )}
                        </Grid>
                        <Grid item>
                            {temple?.location && (
                                <TempleLocationCard location={temple.location} apiKey={mapsApiKey} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            {temple && <TempleAdminTable temple={temple}/>}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <BankAccountCard
                                bankAccount={bankAccount}
                                handleSubmit={handleBankAccountSubmit}
                                updateApi={bankAccountUpdateApi}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            {temple && (
                                <ComplianceCard
                                    temple={temple}
                                    handleSubmit={handleTempleUpdate}
                                    updateApi={templeUpdateApi}
                                />
                            )}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            {offeringCategories && (
                                <OfferingCategoryListCard offeringCategories={offeringCategories} />
                            )}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            {deities && (
                                <DeityListCard deities={deities} />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {offerings && (
                                <OfferingListCard offerings={offerings} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Temple;