import { createStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { CremaTheme } from 'types/crema/AppContextPropsType'

export const useStyles = makeStyles((theme: CremaTheme) => (
    createStyles({
        wrapper: {
            height: "40vh",
            width: '100%'
        },
        headerRoot: {
            background: theme.palette.grey[100]
        },
    })
))