import { CancelToken } from "axios"
import { BankAccountType, ITempleResponse, ParamsOrUrlType } from "types/api/temple"
import { apiInstance } from "../client"
import { StaffBankAccountParamsType, StaffTempleAdminCreateRequest, StaffTempleAdminParams, StaffTempleAdminResponse, StaffTempleParamsType } from "./types"
import { getTempleId } from "api/temple"


const basePath = 'temple/staff'

const templeUrl =`${basePath}/temple` 
const getBankAccountUrl = () => `${basePath}/bank-account`
const getTempleAdminUrl = () => `${basePath}/temple-admin`

export const templeAPI = {
    search: async(data: ParamsOrUrlType<{query: string}>, cancelToken: CancelToken) => {
        return 'url' in data ?
            await apiInstance.get<ITempleResponse[]>(data.url, {cancelToken: cancelToken, templeId: getTempleId() }) :
            await apiInstance.get<ITempleResponse[]>(`${templeUrl}/`, {params: {name: data.query}, cancelToken: cancelToken, templeId: getTempleId() })
    },
    list: async(data: StaffTempleParamsType | UrlType) =>
        'url' in data ? 
            await apiInstance.get<ITempleResponse[]>(data.url, { templeId: getTempleId() }) :
            await apiInstance.get<ITempleResponse[]>(`${templeUrl}/`, {params: data, templeId: getTempleId() }),
    get: async(url: string) =>
        await apiInstance.get<ITempleResponse>(url, { templeId: getTempleId() }),
    activate: async(url: string) => await apiInstance.post(`${url}approve/`, { templeId: getTempleId() }),
    update: async(url:string, data: Partial<ITempleResponse>) => await apiInstance.patch(url, data, { templeId: getTempleId() }),
    deactive: async(url: string) => await apiInstance.post(`${url}disapprove/`, { templeId: getTempleId() }),
}

export const bankAccountApi = {
    list: async(data: StaffBankAccountParamsType | UrlType) =>
        'url' in data ? 
            await apiInstance.get<BankAccountType[]>(data.url) :
            await apiInstance.get<BankAccountType[]>(`${getBankAccountUrl()}/`, {params: data, templeId: getTempleId() }),
    update: async(url: string, data: Partial<BankAccountType>) =>
        await apiInstance.patch<BankAccountType>(url, data, { templeId: getTempleId(data.templeId) }),
    get: async(url: string) => await apiInstance.get<BankAccountType>(url, { templeId: getTempleId() }),
    activate: async(url: string) =>
        await apiInstance.post(`${url}approve/`, { templeId: getTempleId() }),
    deactivate: async(url: string) =>
        await apiInstance.post(`${url}disapprove/`, { templeId: getTempleId() })
}

export const adminTempleAdminApi = {
    list: async(params: StaffTempleAdminParams) => apiInstance.get<StaffTempleAdminResponse[]>(`${getTempleAdminUrl()}/`, {params, templeId: getTempleId(params.temple) }),
    get: async(url: string) => apiInstance.get<StaffTempleAdminResponse>(url),
    create: async(data: StaffTempleAdminCreateRequest) => apiInstance.post<StaffTempleAdminResponse>(`${getTempleAdminUrl()}/`, data, { templeId: getTempleId(data.temple) }),
    update: async(url: string, data: Partial<StaffTempleAdminCreateRequest>) => apiInstance.patch<StaffTempleAdminResponse>(url, data, { templeId: getTempleId(data.temple) }),
    delete: async(url: string) => apiInstance.delete(url, { templeId: getTempleId() })
}