export enum apiStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed'
}

export enum ImageSizes{
    FULL = 0,
    SMALL = 1,
    MEDIUM = 2,
    LARGE = 3,
}

export enum OfferingTypes {
    CUSTOM = 'custom',
    YEARLY = 'yearly',
    MONTHLY = 'monthly',
    WEEKLY = 'weekly',
    DAILY = 'daily'
}

export enum DonationTypes {
    CUSTOM = 'custom',
    YEARLY = 'yearly',
    MONTHLY = 'monthly',
    WEEKLY = 'weekly',
    DAILY = 'daily'
}

// export enum WeeklyDays {
//     SUN = 'sun',
//     MON = 'mon',
//     TUE = 'tue',
//     WED = 'wed',
//     THU = 'thu',
//     FRI = 'fri',
//     SAT = 'sat'
// }

// export enum WeeklyDays {SUN , MON, TUE, WED, THU, FRI, SAT}

export enum BlockPeriods {
    NA = 'not_applicable',
    YEAR = 'year',
    MONTH = 'month',
    WEEK = 'week',
    DAY = 'day',
    HOUR = 'hour'
}
