import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import React from 'react'
import { useIntl } from 'react-intl'
import { WeeklyDaysFlagType, WeeklyDayType } from 'types/store/temple'
import FormBlock from '../../../components/Form/FormBlock'

interface WeeklyDaysProps{
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    formState: WeeklyDaysFlagType,
    formContext: string
}

const WeeklyDays : React.FC<WeeklyDaysProps> = (props) => {
    const {formState, setFieldValue, formContext} = props
    const { messages } = useIntl();

    const handleLocalChange = (day: WeeklyDayType) => {
        setFieldValue(formContext, {...formState, [day]: !formState[day]})
    }

    return (
        <FormBlock titleId="offering.weeklyOffering.action.title">
            <FormGroup>
                {Object.entries(formState).map(([key, value]) => (
                    <FormControlLabel
                        style={{ minWidth: "60px" }}
                        key={key}
                        control={
                            <Checkbox
                                checked={value}
                                onChange={() => handleLocalChange(key as WeeklyDayType)}
                                name={key}
                                color="primary"
                            />
                        }
                        label={messages[`offering.weeklyOffering.${key}`]}
                    />
                ))}
            </FormGroup>
        </FormBlock>
    )
}

export default React.memo(WeeklyDays)