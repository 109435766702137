import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router"
import { useAppSelector } from "store/hooks"
import { selectCurrentUser, selectSelfPermissions, UserRoleEnum } from "store/reducers/temple/usersSlice"
import { PERMISSIONS } from "utils/permissions"
import routePermission from "utils/routePermissionHandler"

export default function useRestricter(access?: PERMISSIONS, redirectTo?: string) {
    const permissions = useAppSelector(selectSelfPermissions)
    const { pathname } = useLocation()
    const history = useHistory()
    const [allow, setAllow] = useState<boolean>(false)
    const self = useAppSelector(selectCurrentUser)

    useEffect(() => {
        if (self?.role === UserRoleEnum.admin) setAllow(true)
        else if (permissions && !routePermission(pathname ?? '/', permissions, access ?? PERMISSIONS.VIEW)) {
            setAllow(false)
            history.push(redirectTo ?? '/403') //nextViewAllowedRoute(pathname, permissions))
        } else setAllow(true)
    }, [permissions, pathname, access, redirectTo, self?.role])

    return allow
}