import { useHistory } from 'react-router-dom';
import { initialUrl } from 'shared/constants/AppConst';
import useStyles from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import IntlMessages from '@crema/utility/IntlMessages';


const Error403 = () => {
    const history = useHistory();

    const onGoBackToHome = () => {
        history.push(initialUrl);
    };

    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Box className={classes.box}>
                <ReportProblemOutlinedIcon style={{ color: '#f28500', fontSize: '80px' }} />
                <Typography variant='body1' className={classes.text}>
                    <IntlMessages id="error.invalidpermission" />
                </Typography>
                <Button variant='contained' color='primary' onClick={onGoBackToHome}>
                    Go back to home
                </Button>
            </Box>
        </Box>
    )

};

export default Error403;
