// import React from 'react';
import Dashboard from 'containers/Dashboard'
import BookingsTable from 'containers/Dashboard/Bookings/BookingsTable'
import OfferingCategories from 'containers/Dashboard/OfferingCategory'
import Offerings from 'containers/Dashboard/Offering'
import ListOfferingCategory from 'containers/Dashboard/OfferingCategory/ListOfferingCategory'
import AddOfferingCategory from 'containers/Dashboard/OfferingCategory/AddOfferingCategory'
import EditOfferingCategory from 'containers/Dashboard/OfferingCategory/EditOfferingCategory'
import ListOffering from 'containers/Dashboard/Offering/ListOffering'
import AddOffering from 'containers/Dashboard/Offering/AddOffering'
import EditOffering from 'containers/Dashboard/Offering/EditOffering'
import DeityLayout from 'containers/Dashboard/Deities'
import ListDeity from 'containers/Dashboard/Deities/ListDeity'
import EditDeity from 'containers/Dashboard/Deities/EditDeity'
import AddDeity from 'containers/Dashboard/Deities/AddDeity'
import ReceiptLayout from 'containers/Dashboard/Bookings/ReceiptLayout'
import Bookings from 'containers/Dashboard/Bookings'
import TempleProfile from 'containers/Dashboard/TempleProfile'
import DonationCategories from 'containers/Dashboard/DonationCategories'
import AddDonationCategory from 'containers/Dashboard/DonationCategories/AddDonationCategory'
import EditDonationCategory from 'containers/Dashboard/DonationCategories/EditDonationCategory'
import ListDonationCategory from 'containers/Dashboard/DonationCategories/ListDonationCategory'
import Donation from 'containers/Dashboard/Donation'
import AddDonation from 'containers/Dashboard/Donation/AddDonation'
import EditDonation from 'containers/Dashboard/Donation/EditDonation'
import ListDonation from 'containers/Dashboard/Donation/ListDonation'
import Report from 'containers/Dashboard/reports/Reports'
import ReportTable from 'containers/Dashboard/reports/ReportTable'
import DonationBooking from 'containers/Dashboard/DonationBooking'
import DonationBookingTable from 'containers/Dashboard/DonationBooking/DonationBookingTable'
import DonationReceiptLayout from 'containers/Dashboard/DonationBooking/DonationReceiptLayout'
import ManageUsers from 'containers/Dashboard/ManageUsers'
import UsersList from 'containers/Dashboard/ManageUsers/UsersList'
import Permissions from 'containers/Dashboard/ManageUsers/Permissions'

export const dashboardConfig = [
  {
    auth: ['user'],
    component: Dashboard,
    path: '/dashboard',
    routes: [
      // {
      //   path: '/dashboard/bookings',
      //   // component: React.lazy(() => import('containers/Dashboard/Bookings')),
      //   component: Bookings,
      //   routes: [
      //     {
      //       path: '/dashboard/bookings/:id',
      //       component: ReceiptLayout
      //     },
      //     {
      //       path: '/dashboard/bookings',
      //       component: BookingsTable
      //     }
      //   ]
      // },
      // {
      //   path: '/dashboard/donation-receipts',
      //   // component: React.lazy(() => import('containers/Dashboard/Bookings')),
      //   component: DonationBooking,
      //   routes: [
      //     {
      //       path: '/dashboard/donation-receipts/:id',
      //       component: DonationReceiptLayout
      //     },
      //     {
      //       path: '/dashboard/donation-receipts',
      //       component: DonationBookingTable
      //     }
      //   ]
      // },
      {
        path: '/dashboard/profile',
        // component: React.lazy(() => import('containers/Dashboard/Bookings')),
        component: TempleProfile
      },
      {
        path: '/dashboard/deities',
        // component: React.lazy(() => import('containers/Dashboard/Bookings')),
        component: DeityLayout,
        routes: [
          {
            path: '/dashboard/deities/add',
            component: AddDeity
          },
          {
            path: '/dashboard/deities/edit/:id',
            component: EditDeity
          },
          {
            path: '/dashboard/deities',
            component: ListDeity
          }
        ]
      },
      {
        path: '/dashboard/offering-categories',
        // component: React.lazy(() => import('containers/Dashboard/Bookings')),
        component: OfferingCategories,
        routes: [
          {
            path: '/dashboard/offering-categories/add',
            component: AddOfferingCategory,
          },
          {
            path: '/dashboard/offering-categories/edit/:id',
            component: EditOfferingCategory
          },
          {
            path: '/dashboard/offering-categories',
            component: ListOfferingCategory,
          },
        ]
      },
      {
        path: '/dashboard/offerings',
        // component: React.lazy(() => import('containers/Dashboard/Bookings')),
        component: Offerings,
        routes: [
          {
            path: '/dashboard/offerings/add',
            component: AddOffering
          },
          {
            path: '/dashboard/offerings/edit/:id',
            component: EditOffering
          },
          {
            path: '/dashboard/offerings',
            component: ListOffering
          }
        ]
      },
      // {
      //   path: '/dashboard/offerings-completion',
      //   // component: React.lazy(() => import('containers/Dashboard/Bookings')),
      //   component: OfferingCompletion,
      //   routes: [
      //     {
      //       path: '/dashboard/offerings-completion',
      //       component: OfferingCompletionTable
      //     },

      //   ]
      // },
      {
        path: '/dashboard/donation-categories',
        // component: React.lazy(() => import('containers/Dashboard/Bookings')),
        component: DonationCategories,
        routes: [
          {
            path: '/dashboard/donation-categories/add',
            component: AddDonationCategory,
          },
          {
            path: '/dashboard/donation-categories/edit/:id',
            component: EditDonationCategory
          },
          {
            path: '/dashboard/donation-categories',
            component: ListDonationCategory,
          },
        ]
      },
      {
        path: '/dashboard/donations',
        // component: React.lazy(() => import('containers/Dashboard/Bookings')),
        component: Donation,
        routes: [
          {
            path: '/dashboard/donations/add',
            component: AddDonation
          },
          {
            path: '/dashboard/donations/edit/:id',
            component: EditDonation
          },
          {
            path: '/dashboard/donations',
            component: ListDonation
          }
        ]
      },
      // {
      //   path: '/dashboard/reports',
      //   // component: React.lazy(() => import('containers/Dashboard/Bookings')),
      //   component: Report,
      //   routes: [
      //     {
      //       path: '/dashboard/reports',
      //       component: ReportTable
      //     }
      //   ]
      // },
      {
        path: '/dashboard/manage-users',
        component: ManageUsers,
        routes: [
          {
            path: '/dashboard/manage-users',
            component: UsersList,
            exact: true
          },
          {
            path: '/dashboard/manage-users/:id',
            component: Permissions,
            exact: true
          }
        ]
      }
    ],
  },
];
