import { CancelToken } from 'axios'
import { apiInstance } from '../client'
import { getTempleId } from 'api/temple'

const userAPI = {
    create: async(payload: CreateUserRequestType) => apiInstance.post<ILoginResponse>('/user/v2/create/', payload),
    login: async (payload : ILoginRequest) => await apiInstance.post<ILoginResponse>('/user/v2/auth/', payload),
    register: async (payload : IRegisterRequest) => await apiInstance.post<ILoginResponse>('/user/v2/create/', payload),
    logout: async () => await apiInstance.delete('/user/logout/'),
    getUser: async() => await apiInstance.get('/user/', { templeId: getTempleId() }),
    updateUser: async(payload: Partial<UserResponseType>) => await apiInstance.patch<UserResponseType>('/user/', payload, { templeId: getTempleId() }),
    listUser: async(params: {email?: string, phone?: string}, cancelToken?: CancelToken) => 
        apiInstance.get<PublicUserResponseType[]>('/user/user/', {params: params, cancelToken: cancelToken, templeId: getTempleId() })
}

export default userAPI