import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import userAPI from 'api/user'
import { apiStatus } from 'shared/constants/AppEnum'
// circular import
import { AppState } from 'store/reduxStore'
import { tokenKey } from 'shared/constants/AppConst'
import { getJWT, getLocalUserRole, setJWT, setLocalUserRole } from 'utils/auth'
import { getDeviceId } from 'utils/auth'
import { resetState as resetDeityState } from 'store/reducers/temple/deitySlice'
import { resetState as resetOfferingState } from 'store/reducers/temple/offeringSlice'
import { resetState as resetOfferingCategoryState } from 'store/reducers/temple/offeringCategorySlice'
import { resetState as resetOrderState } from 'store/reducers/temple/orderSlice'
import { resetState as resetReceiptState } from 'store/reducers/temple/receiptSlice'
import { resetState as resetTempleState } from 'store/reducers/temple/templeSlice'
import { resetState as resetSettingsState } from 'store/reducers/settings/settingsSlice'
import { IApiState } from 'store/types'
import { resetUsersState } from '../temple/usersSlice'

export type UserRoleType = 'user' | 'staff'

export type TJWTResponse = {
    access: string,
    refresh: string
}

export interface IAuth extends IApiState {
    jwt: TJWTResponse | null
    user: UserResponseType | null
}

const getInitialState = (): IAuth => {
    return {
        status: apiStatus.IDLE,
        jwt: getJWT(),
        user: null,
    }
}

export const loginThunk = createAsyncThunk('auth/login', async (payload: ILoginRequest, { rejectWithValue }) => {
    localStorage.removeItem(tokenKey)
    try {
        const response = await userAPI.login({ ...payload, deviceId: getDeviceId() })
        return response.data
    } catch (err: any) {
        return rejectWithValue(err.response.data)
    }
})

export const registerThunk = createAsyncThunk('auth/register', async (payload: IRegisterRequest, { rejectWithValue }) => {
    localStorage.removeItem(tokenKey)
    try {
        const response = await userAPI.register({ ...payload, deviceId: getDeviceId() })
        return response.data
    } catch (err: any) {
        return rejectWithValue(err.response.data)
    }
})

export const logoutThunk = createAsyncThunk('auth/logout', async (payload: null | undefined, { rejectWithValue, dispatch }) => {
    try {
        // await userAPI.logout()
        dispatch(resetDeityState())
        dispatch(resetOfferingState())
        dispatch(resetOfferingCategoryState())
        dispatch(resetOrderState())
        dispatch(resetReceiptState())
        dispatch(resetTempleState())
        dispatch(resetSettingsState())
        dispatch(resetUsersState())
        setJWT(null)
        return true
    } catch (err: any) {
        return rejectWithValue(err.response.data)
    }
})

export const userThunk = createAsyncThunk(
    'auth/user',
    async (
        payload: undefined | null | Partial<UserResponseType>, { rejectWithValue }
    ) => {
        try {
            const response = payload ? await userAPI.updateUser(payload) : await userAPI.getUser()
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)


const authSlice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        setJwtToken(state, action: PayloadAction<TJWTResponse | null>) {
            state.jwt = action.payload
            setJWT(null)
        },
        setUserRole(state, action: PayloadAction<UserRoleType>) {
            if (state.user) {
                state.user.role = action.payload
                setLocalUserRole(action.payload)
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginThunk.pending, (state, action) => {
            state.status = apiStatus.LOADING
            state.error = ''
        })
        builder.addCase(loginThunk.fulfilled, (state, action) => {
            const { jwt, user } = action.payload
            state.status = apiStatus.SUCCEEDED
            state.jwt = jwt
            let userrole: UserRoleType = user.isStaff ? 'staff' : 'user'
            setLocalUserRole(userrole)
            state.user = { ...user, role: userrole }
        })
        builder.addCase(loginThunk.rejected, (state, action) => {
            state.status = apiStatus.FAILED
            state.error = action.payload
        })
        builder.addCase(registerThunk.pending, (state, action) => {
            state.status = apiStatus.LOADING
            state.error = ''
        })
        builder.addCase(registerThunk.fulfilled, (state, action) => {
            // const { jwt, user} = action.payload
            state.status = apiStatus.SUCCEEDED
            // state.jwt = jwt
            // let userrole : UserRoleType = user.isStaff ? 'staff' : 'user'
            // setLocalUserRole(userrole)
            // state.user = {...user, role:userrole}
        })
        builder.addCase(registerThunk.rejected, (state, action) => {
            state.status = apiStatus.FAILED
            state.error = action.payload
        })
        builder.addCase(logoutThunk.pending, (state, action) => {
            state.status = apiStatus.LOADING
        })
        builder.addCase(logoutThunk.fulfilled, (state, action) => {
            state.status = apiStatus.SUCCEEDED
            state.jwt = null
            state.user = null
        })
        builder.addCase(logoutThunk.rejected, (state, action) => {
            state.status = apiStatus.FAILED
            state.jwt = null
            state.user = null
        })
        builder.addCase(userThunk.pending, (state, action) => {
            state.status = apiStatus.LOADING
        })
        builder.addCase(userThunk.fulfilled, (state, action) => {
            state.status = apiStatus.SUCCEEDED
            state.user = { ...action.payload, role: getLocalUserRole() }
        })
        builder.addCase(userThunk.rejected, (state, action) => {
            state.status = apiStatus.FAILED
            state.error = action.payload
        })
    }
})

export default authSlice.reducer

export const { setJwtToken, setUserRole } = authSlice.actions
export const selectUser = (state: AppState) => state.auth.user
export const selectAuth = (state: AppState) => state.auth
export const selectIsAuthenticated = (state: AppState) => state.auth.jwt?.access ? true : false
export type AuthState = ReturnType<typeof selectAuth>