import { TUserPermissions } from "types/api/temple"
import { PERMISSION_IDENTIFIERS, PERMISSIONS, RESTRICTED_PATHS, routesOrdered } from "./permissions"
import { getLocalStorageItem, setLocalStorageItem } from "./fns"
import { permissionsKey } from "shared/constants/AppConst"

export default function routePermission(path: string, permissions: string[]): PERMISSIONS[];
export default function routePermission(path: string, permissions: string[], access: PERMISSIONS): boolean;
export default function routePermission(path: string, permissions: string[], access?: PERMISSIONS): boolean | PERMISSIONS[] {
    if (path === '/') return true

    const pathKey = getRestrictedPathKey(path)
    if (!pathKey) return true

    let allowed: PERMISSIONS[] = []

    Object.keys(PERMISSION_IDENTIFIERS[pathKey]).map(access => {
        if (PERMISSION_IDENTIFIERS[pathKey]?.[access] && permissions.includes(PERMISSION_IDENTIFIERS[pathKey][access]))
            allowed.push(PERMISSIONS[access as PERMISSIONS])
    })

    if (!access) return allowed
    return allowed.includes(access)
}

export const nextViewAllowedRoute = (path: string, permissions: string[]): string => {
    const restrictedPaths: string[] = Object.values(RESTRICTED_PATHS)
    if (!restrictedPaths.find(p => path.startsWith(p))) return path

    if (routePermission(path, permissions, PERMISSIONS.VIEW)) return path

    const index = routesOrdered.findIndex(p => p === path)
    if (index >= 0 && index < routesOrdered.length - 1) return nextViewAllowedRoute(routesOrdered[index + 1], permissions)

    return '/403'
}

export function getRestrictedPathKey(path: string): string | null {
    for (const [key, value] of Object.entries(RESTRICTED_PATHS))
        if (path.startsWith(value)) return key
    return null;
}

export const cachePermissions = (permissions: TUserPermissions) => {
    try {
        setLocalStorageItem(permissionsKey, JSON.stringify(permissions))
    } catch (err) {
        console.log(err)
    }
}

export const loadPermissions = (): TUserPermissions | null => {
    try {
        return JSON.parse(getLocalStorageItem(permissionsKey) ?? "")
    } catch (err) {
        console.log(err)
        return null
    }
}