import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { apiStatus } from 'shared/constants/AppEnum'
import { AppState } from 'store/reduxStore'
import { IApiState } from 'store/types'
import { FeeSplitConfigResponseType } from 'api/report_app/report_app.response'
import { ContentObjectBaseType } from 'api/report_app/report_app.base'
import { feeSplitConfigAPI } from 'api/report_app'
import { FeeSplitConfigRequestType } from 'api/report_app/report_app.request'

const feeSplitConfigAdapter = createEntityAdapter<FeeSplitConfigResponseType>({
    // sortComparer: (a, b) => a.name.localeCompare(b.name),
    // selectId: (obj) => obj.url
})

type InitialStateType = {
    api: IApiState
}

const addOnIntitalState: InitialStateType = {
    api: {
        status: apiStatus.IDLE,
        error: null
    }
}

export const feeSplitConfigListThunk = createAsyncThunk(
    'feeSplitConfig/list',
    async(payload: ContentObjectBaseType, {rejectWithValue}) => {
        try{
            const response = await feeSplitConfigAPI.list(payload)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const feeSplitConfigCreateThunk = createAsyncThunk(
    'feeSplitConfig/create',
    async(payload: FeeSplitConfigRequestType, {rejectWithValue, getState}) => {
        try{
            const response = await feeSplitConfigAPI.post(payload)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const feeSplitConfigUpdateThunk = createAsyncThunk(
    'feeSplitConfig/update',
    async(payload: PayloadWithUrl<Partial<FeeSplitConfigRequestType>>, {rejectWithValue}) => {
        try{
            const response = await feeSplitConfigAPI.update(payload.url, payload.data)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const feeSplitConfigRetrieveThunk = createAsyncThunk(
    'feeSplitConfig/retrieve',
    async(payload: UrlType, {rejectWithValue}) => {
        try{
            const response = await feeSplitConfigAPI.get(payload.url)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const feeSplitConfigDeleteThunk = createAsyncThunk(
    'feeSplitConfig/delete',
    async (payload: IdAndUrlType) => {
        await feeSplitConfigAPI.delete(payload.url)
        return payload.id
    }
)


const feeSplitConfigSlice = createSlice({
    name: 'feeSplitConfig',
    initialState: feeSplitConfigAdapter.getInitialState(addOnIntitalState),
    reducers: {
        resetState(state) {
            state.api = {
                status: apiStatus.IDLE,
                error: null
            }
            feeSplitConfigAdapter.removeAll(state)
        }
    },
    extraReducers: builder => {

        builder.addCase(feeSplitConfigListThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            feeSplitConfigAdapter.upsertMany(state, action.payload)
        })
        builder.addCase(feeSplitConfigDeleteThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            feeSplitConfigAdapter.removeOne(state, action.payload)
        })

        builder.addMatcher(
            isAnyOf(
               feeSplitConfigListThunk.pending,
               feeSplitConfigCreateThunk.pending,
               feeSplitConfigUpdateThunk.pending,
               feeSplitConfigDeleteThunk.pending,
               feeSplitConfigRetrieveThunk.pending,
            ),(state,action) =>{
                state.api ={
                    status:apiStatus.LOADING,
                    error:null
                }
            }
        )
        builder.addMatcher(
            isAnyOf(
               feeSplitConfigListThunk.rejected,
               feeSplitConfigCreateThunk.rejected,
               feeSplitConfigUpdateThunk.rejected,
               feeSplitConfigDeleteThunk.rejected,
               feeSplitConfigRetrieveThunk.rejected,
            ),(state, action) =>{
                state.api = {
                    status: apiStatus.FAILED,
                    error: action.payload
                }
            }
        )
        builder.addMatcher(
            isAnyOf(
               feeSplitConfigCreateThunk.fulfilled,
               feeSplitConfigUpdateThunk.fulfilled,
               feeSplitConfigRetrieveThunk.fulfilled,
            ),(state, action) =>{
                state.api = {
                    status: apiStatus.SUCCEEDED,
                    error: null
                }
                feeSplitConfigAdapter.upsertOne(state, action.payload)
            }
        )
    }
})

export default feeSplitConfigSlice.reducer

export const { resetState } = feeSplitConfigSlice.actions

export const {
    selectAll: selectAllfeeSplitConfigs,
    selectById: selectfeeSplitConfigById,
    selectIds: selectfeeSplitConfigIds,
} = feeSplitConfigAdapter.getSelectors((state: AppState) => state.feeSplitConfig)


export const selectfeeSplitConfigStatus = (state: AppState): IApiState => state.feeSplitConfig.api

export const selectfeeSplitConfigByContentObject = createSelector(
    (state: AppState) => state, 
    (_:any, contentObject: string) => contentObject,
    (state, contentObject) => selectAllfeeSplitConfigs(state).find(feeSplitConfig => feeSplitConfig.contentObject === contentObject)
)

