import React, { useState } from 'react'
import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import GoBack from 'components/GoBack'
import { RouteConfigComponentProps } from 'react-router-config'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { offeringCategoryCreateThunk, selectOfferingCategoryStatus } from 'store/reducers/temple/offeringCategorySlice'
import FitContentPaper from 'components/Paper/FitContentPaper'
import FormOfferingCategory from './FormOfferingCategory'
import {IOfferingCategoryResponse } from 'types/api/temple'
import { FormikHelpers } from 'formik'
import { unwrapResult } from '@reduxjs/toolkit'
import { getFieldErrors } from 'utils/api'
import { IOfferingCategory } from 'types/store/temple'
import usePermissionCheck from 'hooks/usePermissionCheck'
import { PERMISSIONS, RESTRICTED_PATHS } from 'utils/permissions'
import useRestricter from 'hooks/useRestricter'


const AddOfferingCategory: React.FC<RouteConfigComponentProps> = (props) => {
    const api = useAppSelector(selectOfferingCategoryStatus)
    const dispatch = useAppDispatch()
    const [addedItem, setAddedItem] = useState<IOfferingCategoryResponse | null>(null)
    const hasAddPermission = useRestricter(PERMISSIONS.ADD, RESTRICTED_PATHS.OFFERING_CATEGORIES)

    const handleSubmit = (data: IOfferingCategory, actions: FormikHelpers<IOfferingCategory>) => {
        setAddedItem(null)
        dispatch(offeringCategoryCreateThunk(data))
            .then(unwrapResult)
            .then(response => {
                if(response && !Array.isArray(response))
                    setAddedItem(response)
                actions.resetForm()
            })
            .catch(err => {
                Object.entries(getFieldErrors(data, err)).forEach(([key, value]) => 
                    actions.setFieldError(key, value))
            })
            .finally(() => {
                actions.setSubmitting(false)
            })
    }
    const handleSuccess = () => addedItem ? `Offering Category ${addedItem.name} Successfully added` : ''

    if(!hasAddPermission) return null

    return (
        <GoBack {...props}>
            <FitContentPaper>
                <Box component="h5" mb={4}><IntlMessages id="offeringCategory.add.title" /></Box>
                <FormOfferingCategory
                    buttonLabelId="common.create"
                    api={api}
                    initialValues={{ name: '' }}
                    onSubmitCb={handleSubmit}
                    isSuccess={Boolean(addedItem)}
                    successCb={handleSuccess}
                />
            </FitContentPaper>
        </GoBack>
    )
}

export default AddOfferingCategory