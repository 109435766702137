import Box from '@material-ui/core/Box'
import { createStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import TempleLocationMap from 'components/Temple/TempleLocationMap'
import { useSnackbar } from 'notistack'
import React from 'react'
import { mapsApiKey } from 'shared/constants/AppConst'
import { useAppDispatch } from 'store/hooks'
import { templeUpdateThunk } from 'store/reducers/temple/templeSlice'
import { ITempleResponse } from 'types/api/temple'
import { CremaTheme } from 'types/crema/AppContextPropsType'

interface TempleLocationProps {
    temple?: ITempleResponse
    cb?: (lat: number, lng: number) => void
}

const useStyles = makeStyles((theme: CremaTheme) => (
    createStyles({
        wrapper: {
            height: "50vh",
            width: '100%'
        }
    })
))

const TempleLocation: React.FC<TempleLocationProps> = ({ temple, cb }) => {
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const onChangeLocation = (lat: number, lng: number) => {
        if (temple?.url){
            dispatch(templeUpdateThunk({url: temple.url, data: {location: {latlng: {latitude: lat, longitude: lng}}}}))
                .then(unwrapResult)
                .then(() => enqueueSnackbar('Location updated', {variant: 'success'}))
                .catch(() => enqueueSnackbar('Location updated failed', {variant: 'error'}))
        }
        if (cb) cb(lat, lng)
    }
    return (
        <Box className={classes.wrapper}>
            <TempleLocationMap
                apiKey={mapsApiKey}
                location={temple?.location}
                onChangeLocation={onChangeLocation}
            />
        </Box>
    )
}

export default TempleLocation