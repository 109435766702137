import Avatar from '@material-ui/core/Avatar';
import React from 'react';
import { ImageSizes } from 'shared/constants/AppEnum';
import { ITempleResponse } from 'types/api/temple';
import { getImage } from 'utils/fns';

interface TempleAvatarProps {
    temple: ITempleResponse
}

const TempleAvatar : React.FC<TempleAvatarProps> = ({temple}) => {
    return (
        <Avatar 
            src={temple.displayImg ? 
                    getImage(temple.displayImg, ImageSizes.SMALL) : 
                    '/assets/images/placeholders/temple-square.png'
                }
            alt={temple.name}
            variant="rounded"
        />
    );
};

export default TempleAvatar;