import HeadingCard from 'components/Card/HeadingCard';
import { FormikHelpers } from 'formik';
import React from 'react';
import { IApiState } from 'store/types';
import { ITempleResponse } from 'types/api/temple';
import ComplianceForm, { ComplianceFormType } from '../ComplianceForm';

interface ComplianceCardProps {
    temple: ITempleResponse
    updateApi: IApiState,
    handleSubmit: (values: Partial<ITempleResponse>, actions: FormikHelpers<ComplianceFormType>) => void 
}

const ComplianceCard: React.FC<ComplianceCardProps> = ({ temple, updateApi, handleSubmit }) => {
    return (
        <HeadingCard title="Other">
            <ComplianceForm temple={temple} handleSubmit={handleSubmit} api={updateApi} />
        </HeadingCard>
    );
};


export default ComplianceCard;