import React from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FullWidthTableCell from 'components/Table/FullwidthTableCell';
import { useStyles } from '../common/style';
import { CommonTableHeadCell, CommonTableOrder } from '../common/types';
import Checkbox from '@material-ui/core/Checkbox';

export interface TableSelectAllProps {
  selectAllHandler: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  indeterminate: boolean,
  checked: boolean,
}

interface NormalTableHeadProps<T extends {}> {
  headCells: CommonTableHeadCell<T>[]
  orderBy: string,
  order: CommonTableOrder,
  sortHandler: (id: keyof T) => void,
  selectAllProps?: TableSelectAllProps
}

const NormalTableHead = <T extends {},>(props: React.PropsWithChildren<NormalTableHeadProps<T>>) => {
  const {headCells, orderBy, order, sortHandler, selectAllProps} = props
  const classes = useStyles()
  return (
    <TableHead>
      <TableRow>
        {selectAllProps && (
          <FullWidthTableCell>
              <Checkbox
                color="primary"
                indeterminate={selectAllProps.indeterminate}
                checked={selectAllProps.checked}
                onChange={selectAllProps.selectAllHandler}
                inputProps={{
                  "aria-label": "select all desserts"
              }}/>
          </FullWidthTableCell>
        )}
        {headCells.map((headCell) => (
          <FullWidthTableCell
            key={headCell.id as string}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.hasSorting ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => sortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (<>{headCell.label}</>)
            }
          </FullWidthTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default NormalTableHead;