import userAPI from 'api/user';
import HeadingCard from 'components/Card/HeadingCard';
import CreateUserForm, { FormValuesType } from 'components/User/CreateUserForm';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { getDeviceId } from 'utils/auth';

const CreateUser : React.FC = props => {
    const { enqueueSnackbar } = useSnackbar()
    const handleCreate = (values: FormValuesType, helpers: FormikHelpers<FormValuesType>) => {
        userAPI.create({...values, deviceId: getDeviceId()})
            .then(res => enqueueSnackbar('User created', {variant: 'success'}))
            .catch(err => enqueueSnackbar('Error : Please try again', {variant: 'error'}))
            .finally(() => {
                helpers.resetForm()
                helpers.setSubmitting(false)
            })
    }
    return (
        <HeadingCard title="Create User">
            <CreateUserForm handleSubmit={handleCreate} />
        </HeadingCard>
    );
};



export default CreateUser;