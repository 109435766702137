import React from 'react';
import { useField } from 'formik'
// import { makeStyles, createStyles } from '@material-ui/core/styles';
// import { CremaTheme } from 'types/crema/AppContextPropsType';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

// const useStyles = makeStyles((theme: CremaTheme) =>
//     createStyles({
        
//     }),
// );

interface FormikRadioGroupProps {
    options: {value: string | number | boolean, label: string}[]
    name: string
    label: string
    ariaLabel?: string,
    isBoolean?: boolean,
    row?: boolean
}
export const FormikRadioGroup : React.FC<FormikRadioGroupProps> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, meta, helpers] = useField(props);
    const {isBoolean=false, row=false} = props
    // const classes = useStyles()
    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{props.label}</FormLabel>
            <RadioGroup 
                aria-label="activation" 
                name={field.name} 
                value={isBoolean ? field.value ? 'active' : 'inactive' : field.value} 
                onChange={e => helpers.setValue(isBoolean ? e.currentTarget.value === 'active' ? true : false : e.currentTarget.value)}
                row={row}
            >
                {props.options.map(option => (
                    <FormControlLabel key={option.label} value={isBoolean ? option.value ? 'active' : 'inactive' : option.value} control={<Radio />} label={option.label} />
                ))}
            </RadioGroup>
        </FormControl>
    );
};