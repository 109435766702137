import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { apiStatus } from 'shared/constants/AppEnum'
import templeAPI from 'api/temple'
import { AppState } from 'store/reduxStore'
import { BankAccountType, ITempleResponse } from 'types/api/temple'
import { IApiState } from 'store/types'
import { selectCurrentTempleId, upsertTemple } from "../templeSlice";
import { bankAccountApi } from 'api/admin'
import { extractAxiosHeaderLink } from 'utils/api'
import { StaffBankAccountParamsType } from 'api/admin/types'

const bankAccountAdapter = createEntityAdapter<BankAccountType>()

type BankAccountStateType = {
    listApi: IApiState,
    readApi: IApiState,
    updateApi: IApiState,
    activationApi: IApiState,
}

const addOnInitialState: BankAccountStateType = {
    readApi: {
        status: apiStatus.IDLE,
        error: null,
    },
    listApi: {
        status: apiStatus.IDLE,
        error: null,
    },
    updateApi: {
        status: apiStatus.IDLE,
        error: null,
    },
    activationApi: {
        status: apiStatus.IDLE,
        error: null,
    },
}


export const staffBankListThunk = createAsyncThunk(
    'staff/bankAccount/list',
    async (payload: StaffBankAccountParamsType | UrlType, { rejectWithValue, dispatch }) => {
        
        try {
            const response = await bankAccountApi.list(payload)
            const temples : ITempleResponse[] = []
            response.data.forEach(bankAccount => {
                if(bankAccount.temple){
                    bankAccount.templeId = bankAccount.temple.id
                    temples.push(bankAccount.temple)
                    // delete bankAccount.temple
                } 
            })
            dispatch(upsertTemple(temples))
            return {
                data: response.data,
                nextLink: extractAxiosHeaderLink(response, 'next')
            }
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    })


export const staffBankRetrieveThunk = createAsyncThunk(
    'staff/bankAccount/retrive',
    async(payload: UrlType, { rejectWithValue, dispatch }) => {
        try{
            const response = await bankAccountApi.get(payload.url)
            if (response.data.temple){
                response.data.templeId = response.data.temple.id
                dispatch(upsertTemple({...response.data.temple}))
                // delete response.data.temple
            }
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)


export const staffBankUpdateThunk = createAsyncThunk(
    'staff/bankAccount/update',
    async (payload: PayloadWithUrl<Partial<BankAccountType>>, { rejectWithValue, dispatch }) => {
        try {
            const response = await bankAccountApi.update(payload.url, payload.data)
            if (response.data.temple){
                response.data.templeId = response.data.temple.id
                dispatch(upsertTemple({...response.data.temple}))
                // delete response.data.temple
            }
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const BankAccountActivationThunk = createAsyncThunk(
    'staff/bank-account/approve',
    async(payload: IdAndUrlType & { shouldActivate: boolean}) => {
        payload.shouldActivate ? 
            await bankAccountApi.activate(payload.url):
            await bankAccountApi.deactivate(payload.url)
    }
)

export const readBankAccount = createAsyncThunk(
    'temple/bankAccount/read',
    async(payload: IdAndUrlType, {rejectWithValue}) => {
        try{
            const response = await templeAPI.getBankAccount(payload.url)
            return {...response.data, templeId: payload.id}
        }
        catch (err){
            return rejectWithValue(err.response.data)
        }
    }
)

export const updateBankAccount = createAsyncThunk(
    'temple/bankAccount/update',
    async(payload: PayloadWithIdAndUrl<Partial<BankAccountType>>, {rejectWithValue}) => {
        try{
            const response = await templeAPI.updateBankAccount(payload.url, payload.data)
            return {...response.data, templeId: payload.id}
        }
        catch (err){
            return rejectWithValue(err.response.data)
        }
    }
)


const bankAccountSlice = createSlice({
    name: 'bankAccount',
    initialState: bankAccountAdapter.getInitialState(addOnInitialState),
    reducers: {
        resetState(state){
            bankAccountAdapter.removeAll(state)
            state.listApi = {
                status: apiStatus.IDLE,
                error: null
            }
            state.readApi = {
                status: apiStatus.IDLE,
                error: null
            }
            state.updateApi = {
                status: apiStatus.IDLE,
                error: null
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(staffBankListThunk.pending, (state, action) => {
            state.listApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(staffBankListThunk.fulfilled, (state, action) => {
            state.listApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            bankAccountAdapter.upsertMany(state, action.payload.data)
        })
        builder.addCase(staffBankListThunk.rejected, (state, action) => {
            state.listApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(staffBankUpdateThunk.pending, (state, action) => {
            state.updateApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(staffBankUpdateThunk.fulfilled, (state, action) => {
            state.updateApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            bankAccountAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(staffBankUpdateThunk.rejected, (state, action) => {
            state.updateApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(staffBankRetrieveThunk.pending, (state, action) => {
            state.readApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(staffBankRetrieveThunk.fulfilled, (state, action) => {
            state.readApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            bankAccountAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(staffBankRetrieveThunk.rejected, (state, action) => {
            state.readApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(readBankAccount.pending, (state, action) => {
            state.readApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(readBankAccount.fulfilled, (state, action) => {
            state.readApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            bankAccountAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(readBankAccount.rejected, (state, action) => {
            state.readApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(updateBankAccount.pending, (state, action) => {
            state.updateApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(updateBankAccount.fulfilled, (state, action) => {
            state.updateApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            bankAccountAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(updateBankAccount.rejected, (state, action) => {
            state.updateApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(BankAccountActivationThunk.pending, (state, action) => {
            state.activationApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(BankAccountActivationThunk.fulfilled, (state, action) => {
            state.activationApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            const bankAccount = bankAccountAdapter.getSelectors()
                .selectById(state, action.meta.arg.id)
            if (bankAccount)
                bankAccountAdapter.upsertOne(state, {...bankAccount, isActive: action.meta.arg.shouldActivate})
        })
        builder.addCase(BankAccountActivationThunk.rejected, (state, action) => {
            state.activationApi = {
                status: apiStatus.FAILED,
                error: action.error.message
            }
        })
    }
})

export default bankAccountSlice.reducer


export const { resetState } = bankAccountSlice.actions

export const {
    selectAll: selectAllBankAccounts,
    selectById: selectBankAccountById,
    selectIds: selectBankAccountIds,
} = bankAccountAdapter.getSelectors((state: AppState) => state.bankAccount)

export const selectBankAccountListApi = (state: AppState) => state.bankAccount.listApi
export const selectBankAccountReadApi = (state: AppState) => state.bankAccount.readApi
export const selectBankAccountUpdateApi = (state: AppState) => state.bankAccount.updateApi
export const selectCurrentTempleBankAccount = createSelector(
    [selectAllBankAccounts, selectCurrentTempleId],
    (bankAccounts, currentTemple) => bankAccounts.find(bankAccount => bankAccount.templeId?.toString() === currentTemple.toString())
)

export const selectBankAccountByUrl = createSelector(
    (state: AppState) => state,
    (_: any, url: string) => url,
    (state, url) => {
        if (!url) return
        return selectAllBankAccounts(state).find(bankAccount => bankAccount.url === url)
    }
)

export const selectAllInactiveBankAccounts = (state: AppState) => 
    selectAllBankAccounts(state).filter(bankAccount => !bankAccount.isActive)