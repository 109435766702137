import Box from '@material-ui/core/Box';
import HeadingCard from 'components/Card/HeadingCard';
import React from 'react';
import { IDeityResponse } from 'types/api/temple';
import DeityTable from '../DeityTable';

interface DeityListCardProps {
    deities: IDeityResponse[]
}

const DeityListCard: React.FC<DeityListCardProps> = ({ deities }) => {
    return (
        <HeadingCard title="Deities" cardContent={false}>
            {deities.length > 0 ? (
                <DeityTable deities={deities} />
            ) : (
                <Box component="p" textAlign="center" my={10}>Deity List is empty</Box>
            )}
        </HeadingCard>
    );
};


export default DeityListCard;