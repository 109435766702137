export enum PERMISSIONS {
    VIEW = 'VIEW',
    CHANGE = 'CHANGE',
    ADD = 'ADD',
    DELETE = 'DELETE',
    VIEW_TRANSACTION = "VIEW_TRANSACTION",
    VIEW_INVENTORY = "VIEW_INVENTORY",
    VIEW_OFFERING = "VIEW_OFFERING",
    VIEW_DONATION = "VIEW_DONATION",
}

export enum RESTRICTED_PATHS {
    // OFFERING_RECEIPT = '/dashboard/bookings',
    // DONATION_RECEIPT = '/dashboard/donation-receipts',
    OFFERINGS = '/dashboard/offerings',
    OFFERING_CATEGORIES = '/dashboard/offering-categories',
    DONATION = '/dashboard/donations',
    DONATION_CATEGORIES = '/dashboard/donation-categories',
    DEITY = '/dashboard/deities',
    REPORTS = '/dashboard/reports',
    TEMPLE = '/dashboard/profile',
    USER = '/dashboard/manage-users'
}

type TPermissionIdentifiers = {
    [category: string]: {
        [access: string]: string
    }
}

export const PERMISSION_IDENTIFIERS: TPermissionIdentifiers = {
    TEMPLE: {
        VIEW: "view_temple",
        CHANGE: "change_temple"
    },
    USER: {
        ADD: "add_user",
        CHANGE: "change_user",
        DELETE: "delete_user",
        VIEW: "view_user"
    },
    DEITY: {
        ADD: "add_deity",
        CHANGE: "change_deity",
        DELETE: "delete_deity",
        VIEW: "view_deity"
    },
    OFFERINGS: {
        ADD: "add_offering",
        CHANGE: "change_offering",
        DELETE: "delete_offering",
        VIEW: "view_offering"
    },
    OFFERING_CATEGORIES: {
        ADD: "add_offering",
        CHANGE: "change_offering",
        DELETE: "delete_offering",
        VIEW: "view_offering"
    },
    DONATION: {
        ADD: "add_donation",
        CHANGE: "change_donation",
        DELETE: "delete_donation",
        VIEW: "view_donation"
    },
    DONATION_CATEGORIES: {
        ADD: "add_donation",
        CHANGE: "change_donation",
        DELETE: "delete_donation",
        VIEW: "view_donation"
    },
    ORDER: {
        ADD: "add_order",
        CHANGE: "change_order",
        DELETE: "delete_order",
        VIEW: "view_order"
    },
    OFFERING_RECEIPT: {
        ADD: "add_offeringorder",
        CHANGE: "change_offeringorder",
        DELETE: "delete_offeringorder",
        VIEW: "view_offeringorder"
    },
    DONATION_RECEIPT: {
        ADD: "add_donationorder",
        CHANGE: "change_donationorder",
        DELETE: "delete_donationorder",
        VIEW: "view_donationorder"
    },
    INVENTORY: {
        ADD: "add_inventory",
        CHANGE: "change_inventory",
        DELETE: "delete_inventory",
        VIEW: "view_inventory"
    },
    INVENTORY_ITEM: {
        ADD: "add_inventory_item",
        CHANGE: "change_inventory_item",
        DELETE: "delete_inventory_item",
        VIEW: "view_inventory_item"
    },
    INVENTORY_UPDATE: {
        ADD: "add_inventory_update",
        CHANGE: "change_inventory_update",
        DELETE: "delete_inventory_update",
        VIEW: "view_inventory_update"
    },
    INVENTORY_CATEGORY: {
        ADD: "add_inventory_category",
        CHANGE: "change_inventory_category",
        DELETE: "delete_inventory_category",
        VIEW: "view_inventory_category"
    },
    INVENTORY_SUBCATEGORY: {
        ADD: "add_inventory_subcategory",
        CHANGE: "change_inventory_subcategory",
        DELETE: "delete_inventory_subcategory",
        VIEW: "view_inventory_subcategory"
    },
    INVENTORY_VENDOR: {
        ADD: "add_inventory_vendor",
        CHANGE: "change_inventory_vendor",
        DELETE: "delete_inventory_vendor",
        VIEW: "view_inventory_vendor"
    },
    TRANSACTION: {
        ADD: "add_transaction",
        CHANGE: "change_transaction",
        DELETE: "delete_transaction",
        VIEW: "view_transaction"
    },
    TRANSACTION_CATEGORY: {
        ADD: "add_transaction_category",
        CHANGE: "change_transaction_category",
        DELETE: "delete_transaction_category",
        VIEW: "view_transaction_category"
    },
    TRANSACTION_SUBCATEGORY: {
        ADD: "add_transaction_subcategory",
        CHANGE: "change_transaction_subcategory",
        DELETE: "delete_transaction_subcategory",
        VIEW: "view_transaction_subcategory"
    },
    TRANSACTION_CONFIG: {
        ADD: "add_transaction_config",
        CHANGE: "change_transaction_config",
        DELETE: "delete_transaction_config",
        VIEW: "view_transaction_config"
    },
    REPORTS: {
        VIEW: 'view_offering_report',
        VIEW_TRANSACTION: "view_transaction_report",
        VIEW_INVENTORY: "view_inventory_report",
        VIEW_OFFERING: "view_offering_report",
        VIEW_DONATION: "view_donation_report"
    }
}

export const routesOrdered: string[] = [
    '/dashboard/profile',
    '/dashboard/deities',
    // '/dashboard/bookings',
    // '/dashboard/donation-receipts',
    '/dashboard/offering-categories',
    '/dashboard/offerings',
    '/dashboard/donation-categories',
    '/dashboard/donations',
    // '/dashboard/reports',
    '/dashboard/manage-users',
    '/staff/dashboard',
    '/register-temple'
]

export const BLACKLISTED_PERMISSIONS: string[] = [
    'inventoryitem',
    'inventoryupdate',
    'inventorycategory',
    'inventorysubcategory',
    'inventoryvendor',
    'transactioncategory',
    'transactionsubcategory'
]