import { useEffect, useState } from "react"
import { useAppSelector } from "store/hooks"
import { selectCurrentUser, selectSelfPermissions, UserRoleEnum } from "store/reducers/temple/usersSlice"
import { PERMISSIONS } from "utils/permissions"
import routePermission from "utils/routePermissionHandler"

const allowAll = [PERMISSIONS.ADD, PERMISSIONS.CHANGE, PERMISSIONS.DELETE, PERMISSIONS.VIEW, PERMISSIONS.VIEW_DONATION, PERMISSIONS.VIEW_INVENTORY, PERMISSIONS.VIEW_OFFERING, PERMISSIONS.VIEW_TRANSACTION]

export default function usePermissionCheck(path: string): PERMISSIONS[];
export default function usePermissionCheck(path: string, access: PERMISSIONS): boolean;
export default function usePermissionCheck(path: string, access?: PERMISSIONS): boolean | PERMISSIONS[] {
    const permissions = useAppSelector(selectSelfPermissions)
    const [allowed, setAllowed] = useState<PERMISSIONS[] | boolean>([])
    const self = useAppSelector(selectCurrentUser)

    useEffect(() => {
        if (self?.role === UserRoleEnum.admin) setAllowed(access ? true : allowAll)
        else if (permissions) {
            if (!access) setAllowed(routePermission(path ?? '/', permissions))
            else setAllowed(routePermission(path ?? '/', permissions, access))
        } else setAllowed(true)
    }, [permissions, path, self?.role])

    return allowed
}