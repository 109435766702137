import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { donationReceiptAPI } from "api/temple";
import { apiStatus } from "shared/constants/AppEnum";
import { AppState } from "store/reduxStore";
import { IApiState } from "store/types";
import { DonationReceiptResponseType } from "types/api/receipt";
import { extractAxiosHeaderLink } from "utils/api";
import { DonationReceiptFilterArgsType,  } from "./types";
import { ReceiptRequestTypes } from "../receiptSlice/types";

const donationReceiptAdapter = createEntityAdapter<DonationReceiptResponseType>()

type ReceiptListArgsType = {
    filter: DonationReceiptFilterArgsType
    pagination: PaginationType
}

type InitialStateType = {
    api: IApiState
    listArgs: ReceiptListArgsType
    // completionListArgs: ReceiptListArgsType
}


const addOnInitialState: InitialStateType = {
    api: {
        status: apiStatus.IDLE,
        error: null
    },
    listArgs: {
        filter: {
            pageSize: 25,
            asc: false,
            orderBy: 'id',
            status: 'paid'
        },
        pagination: {},
    },
    // completionListArgs: {
    //     filter: {
    //         pageSize: 50,
    //         asc: false,
    //         orderBy: 'id',
    //         status: 'paid',
    //         completionStatus: 'pending',
    //     },
    //     pagination: {},
    // }
}

export const donationReceiptDownloadPdfThunk = createAsyncThunk(
    'donationReceipt/download/pdf',
    async (requestType: ReceiptRequestTypes, { rejectWithValue, getState }) => {
        let state = (getState() as AppState).donationReceipt
        try {
            if(requestType === 'pdf'){
                const response = await donationReceiptAPI.generatePDFDownloadUrl({...state.listArgs.filter})
                window.open(response.data.downloadUrl, '_self')
            }
            return
        }
        catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const donationReceiptListThunk = createAsyncThunk(
    'donationReceipt/list',
    async (_, { rejectWithValue, getState }) => {
        const args : ReceiptListArgsType = (getState() as AppState).donationReceipt.listArgs
        try {
            const response = args.pagination.next ?
                await donationReceiptAPI.listWithUrl(args.pagination.next) :
                await donationReceiptAPI.list({...args.filter})
            return {
                data: response.data,
                pagination: { next: extractAxiosHeaderLink(response, 'next') },
                reset: args.pagination.next ? false : true
            }
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

// export const receiptCompletionListThunk = createAsyncThunk(
//     'receipt/list/complete',
//     async (_, { rejectWithValue, getState }) => {
//         const args  = (getState() as AppState).receipt.completionListArgs
//         try {
//             const response = args.pagination.next ?
//                 await receiptAPI.listWithUrl(args.pagination.next) :
//                 await receiptAPI.list(args.filter)
//             return {
//                 data: response.data,
//                 pagination: { next: extractAxiosHeaderLink(response, 'next') },
//                 reset: args.pagination.next ? false : true
//             }
//         } catch (err: any) {
//             return rejectWithValue(err.response.data)
//         }
//     }
// )

export const donationReceiptRetrieveThunk = createAsyncThunk(
    'donationReceipt/retrieve',
    async (payload: IdOrUrlType, { rejectWithValue }) => {
        try {
            let params
            if ('id' in payload && payload.id)
                params = { itemId: payload.id }
            else if ('url' in payload)
                params = { url: payload.url }
            else
                return rejectWithValue({ detail: 'Identifier not provided' })
            const response = await donationReceiptAPI.get(params)
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

const donationReceiptSlice = createSlice({
    name: 'donationReceipt',
    initialState: donationReceiptAdapter.getInitialState(addOnInitialState),
    reducers: {
        resetState(state) {
            state.api = {
                status: apiStatus.IDLE,
                error: null
            }
            donationReceiptAdapter.removeAll(state)
            state.listArgs = {
                filter: {
                    status: 'paid',
                    pageSize: 25,
                    asc: false,
                    orderBy: 'id'
                },
                pagination: {}
            }
            // state.completionListArgs = {
            //     filter: {
            //         pageSize: 50,
            //         asc: false,
            //         orderBy: 'id',
            //         status: 'paid',
            //         completionStatus: 'pending'
            //     },
            //     pagination: {},
            // }
        },
        setFilters(state, action: PayloadAction<Partial<DonationReceiptFilterArgsType>>) {
            state.listArgs = {
                filter: {
                    pageSize: state.listArgs.filter.pageSize,
                    asc: state.listArgs.filter.asc,
                    status: state.listArgs.filter.status,
                    orderBy: state.listArgs.filter.orderBy,
                    ...action.payload
                },
                pagination: {}
            }
        },
        // setCompletionFilters(state, action) {
        //     state.completionListArgs = {
        //         filter: {
        //             pageSize: state.completionListArgs.filter.pageSize,
        //             asc: state.completionListArgs.filter.asc,
        //             status: state.completionListArgs.filter.status,
        //             completionStatus: state.completionListArgs.filter.completionStatus,
        //             ...action.payload
        //         },
        //         pagination: {}
        //     }
        //     receiptAdapter.removeAll(state)
        // },
    },
    extraReducers: builder => {
        builder.addCase(donationReceiptListThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            state.listArgs.pagination = action.payload.pagination
            if (action.payload.reset)
                donationReceiptAdapter.removeAll(state)
            donationReceiptAdapter.upsertMany(state, action.payload.data)
        })
        // builder.addCase(receiptCompletionListThunk.fulfilled, (state, action) => {
        //     state.api = {
        //         status: apiStatus.SUCCEEDED,
        //         error: null
        //     }
        //     state.completionListArgs.pagination = action.payload.pagination
        //     if (action.payload.reset)
        //         receiptAdapter.removeAll(state)
        //     receiptAdapter.upsertMany(state, action.payload.data)
        // })
        builder.addCase(donationReceiptDownloadPdfThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
        })
        builder.addCase(donationReceiptRetrieveThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            donationReceiptAdapter.upsertOne(state, action.payload)
        })
        builder.addMatcher(
            isAnyOf(
                donationReceiptListThunk.pending,
                // receiptCompletionListThunk.pending,
                donationReceiptDownloadPdfThunk.pending,
                donationReceiptRetrieveThunk.pending,
            ),
            (state, action) => {
                state.api = {
                    status: apiStatus.LOADING,
                    error: null
                }
            })
        builder.addMatcher(
            isAnyOf(
                donationReceiptListThunk.rejected,
                // receiptCompletionListThunk.rejected,
                donationReceiptDownloadPdfThunk.rejected,
                donationReceiptRetrieveThunk.rejected,
            ), (state, action) => {
                state.api = {
                    status: apiStatus.FAILED,
                    error: action.payload
                }
            }
        )
    }
})

export default donationReceiptSlice.reducer

export const { resetState: resetDonationReceiptState, setFilters: setDonationReceiptFilters } = donationReceiptSlice.actions

export const {
    selectAll: selectAllDonationReceipt,
    selectById: selectDonationReceiptById,
    selectIds: selectDonationReceiptIds,
} = donationReceiptAdapter.getSelectors((state: AppState) => state.donationReceipt)

export const selectDonationFilterArgs = (state: AppState) => state.donationReceipt.listArgs.filter

export const selectDonationPagination = (state: AppState) => state.donationReceipt.listArgs.pagination

// export const selectCompletionFilterArgs = (state: AppState) => state.receipt.completionListArgs.filter

// export const selectCompletionPagination = (state: AppState) => state.receipt.completionListArgs.pagination

export const selectDonationReceiptStatus = (state: AppState) => state.donationReceipt.api
