
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CremaTheme } from 'types/crema/AppContextPropsType';

export const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        logo: {
            width: '120px',
            height: 'auto'
        }
    }),
);