import React from 'react';
import Box from '@material-ui/core/Box';
import Temple from '../Temple';
import { IApiState } from 'store/types';
import FullWidthDialog from 'components/Dialog/FullWidthDialog';

interface TempleDialogProps {
    url: string,
    open: boolean,
    handleClose: () => void,
    handleDelete: (data: IdAndUrlType) => void,
    handleActivation: (data: IdAndUrlType & { shouldActivate: boolean }) => void,
    deleteApi: IApiState,
    activationApi: IApiState,
}

const TempleDialog: React.FC<TempleDialogProps> =
    ({ url, open, handleClose, handleDelete, deleteApi, handleActivation, activationApi }) => {
        return (
            <FullWidthDialog open={open} handleClose={handleClose}>
                <Box mb={10}>
                    <Temple
                        url={url}
                        handleDelete={handleDelete}
                        deleteApi={deleteApi}
                        handleActivation={handleActivation}
                        activationApi={activationApi}
                    />
                </Box>
            </FullWidthDialog>
        );
    };

export default TempleDialog;