import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, TableRow, Typography } from "@material-ui/core";
import { useStyles } from "../Bookings/Bookings.style";
import NormalTable from "components/Table/NormalTable";
import UsersTableHead from "./UsersTableHead";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect, useState } from "react";
import { permissionsListThunk, selectAllUsers, selectCurrentUser, selectUsersApiStatus, userDeleteThunk, usersListThunk } from "store/reducers/temple/usersSlice";
import FullWidthTableCell from "components/Table/FullwidthTableCell";
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from "react-router";
import InviteUserForm from "./InviteUserForm";
import usePermissionCheck from "hooks/usePermissionCheck";
import { PERMISSIONS, RESTRICTED_PATHS } from "utils/permissions";
import { Delete } from "@material-ui/icons";
import RedButton from "components/Button/RedButton";
import IntlMessages from "@crema/utility/IntlMessages";
import { UsersResponseType } from "types/api/temple";

export default function UsersList() {
  const classes = useStyles();
  const dispatch = useAppDispatch()
  const api = useAppSelector(selectUsersApiStatus)
  const rows = useAppSelector(selectAllUsers)
  const history = useHistory()
  const user = useAppSelector(selectCurrentUser)
  const permissions = usePermissionCheck(RESTRICTED_PATHS.USER)
  const handleRequestSort = (property: any) => {
  };

  useEffect(() => {
    dispatch(usersListThunk())
    dispatch(permissionsListThunk())
  }, [])

  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleteItem, setDeleteItem] = useState<UsersResponseType | undefined>(undefined)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <NormalTable
          tableHead={
            <UsersTableHead
              order={'asc'}
              orderBy={'fullName'}
              onRequestSort={handleRequestSort}
            />
          }
          toolbar={
            <Box display="flex" justifyContent="space-between" p={5}>
              <Typography variant="h6" id="tableTitle">
                Manage Users
              </Typography>
              {permissions.includes(PERMISSIONS.ADD) && <InviteUserForm />}
            </Box>
          }
          api={api}
        >
          {rows
            .map((row, index) => {
              return (
                <TableRow
                  key={row.id}
                >
                   <FullWidthTableCell align="left">#{row.user.id}</FullWidthTableCell>
                  <FullWidthTableCell align="left" style={{ verticalAlign: 'center', whiteSpace: 'nowrap' }}>
                    {row.user.fullName}
                    {row.user.id === user?.user.id && <Chip size="small" color="primary" label="You" style={{ marginLeft: '12px' }} />}
                  </FullWidthTableCell>
                  <FullWidthTableCell align="left">{row.user.email}</FullWidthTableCell>
                  <FullWidthTableCell align="left">{row.user.phone}</FullWidthTableCell>
                  <FullWidthTableCell align="left">{row.role}</FullWidthTableCell>
                  {(permissions.includes(PERMISSIONS.CHANGE) || permissions.includes(PERMISSIONS.DELETE)) && (
                    <FullWidthTableCell align="right">
                      {permissions.includes(PERMISSIONS.CHANGE) && <IconButton onClick={() => history.push(`/dashboard/manage-users/${row.id}`)}>
                        <EditIcon fontSize="small" />
                      </IconButton>}
                      {permissions.includes(PERMISSIONS.DELETE) && (
                        <IconButton onClick={
                          () => {
                            setDialogOpen(true)
                            setDeleteItem(row)
                          }
                        }>
                          <Delete fontSize="small" />
                        </IconButton>
                      )}

                    </FullWidthTableCell>
                  )}
                </TableRow>
              );
            })}
        </NormalTable>
      </Paper>
      {
        <Dialog open={Boolean(dialogOpen && deleteItem)}>
          <DialogTitle>{`Are you sure you want to delete ${deleteItem?.user?.fullName}?`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Caution: This action is permanent and cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={() => setDialogOpen(false)}>
              <IntlMessages id="common.cancel" />
            </Button>
            <RedButton variant="outlined" onClick={() => {
              if (deleteItem)
                dispatch(userDeleteThunk(deleteItem?.id))
              setDialogOpen(false)
            }
            } autoFocus>
              <IntlMessages id="common.delete" />
            </RedButton>
          </DialogActions>
        </Dialog>
      }
    </div>
  )
}