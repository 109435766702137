import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import { extractError, getEditedPayload, isFieldError } from 'utils/api'
import Chip from '@material-ui/core/Chip'
import { useStyles } from './style';
import * as yup from 'yup'
import { BankAccountType } from 'types/api/temple';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { IApiState } from 'store/types';
import { apiStatus } from 'shared/constants/AppEnum';
import FormikTextField from 'components/Fields/FormikTextField';
import { FormikRadioGroup } from 'components/Fields/FormikRadioGroup';
import { allowedFileTypes, maxFileSize } from 'shared/constants/AppConst';
import FormikFileField from 'components/Fields/FormikFileField';

const getInitialValues = (forStaff: boolean, bankAccount?: BankAccountType): Partial<BankAccountType> => {
    return {
        accName: bankAccount?.accName ?? '',
        accNumber: bankAccount?.accNumber ?? '',
        ifsc: bankAccount?.ifsc ?? '',
        branchName: bankAccount?.branchName ?? '',
        cancelledCheque: undefined,
        ...(forStaff ? {
            merchantId: bankAccount?.merchantId ?? '',
            isActive: bankAccount?.isActive === true ?? false
        } : {})
    }
}


interface BankAccountFormProps {
    bankAccount?: BankAccountType
    handleSubmit: (values: Partial<BankAccountType>, actions: FormikHelpers<Partial<BankAccountType>>) => void
    api: IApiState
    forStaff?: boolean
}

const BankAccountForm: React.FC<BankAccountFormProps> = ({ bankAccount, handleSubmit, api, forStaff = false }) => {
    const classes = useStyles()
    const { messages } = useIntl()
    const [chipAttrs, setChipAttrs] = useState<ChipType>({ text: 'na', className: classes.na })
    const initialValues = useMemo(() => getInitialValues(forStaff, bankAccount), [forStaff, bankAccount])

    useEffect(() => {
        if (bankAccount)
            setChipAttrs({
                text: bankAccount.isActive ? 'active' : 'inactive',
                className: bankAccount.isActive ? classes.active : classes.inactive
            })
    }, [bankAccount, setChipAttrs, classes])
    const validationSchema = yup.object({
        accName: yup.string()
            .required('Account name is required'),
        accNumber: yup.string()
            .required('Account number is required')
            .test(
                'is-acc-no',
                'Account number should only have [A-Z,0-9]',
                (value) => Boolean(value && /^[A-Z\d]+$/.test(value))
            ),
        branchName: yup.string().required('Branch name is required'),
        ifsc: yup.string()
            .required('IFSC is required')
            .test(
                'is-ifsc',
                'Invalid IFS Code',
                (value) => Boolean(value && /^[A-Z]{4}0[\dA-Z]{6}$/.test(value))
            ),
        ...(!forStaff ? {
            cancelledCheque: yup.mixed()
                .required('Cancelled Cheque is required')
                .test('max-file-size', 'File should be less than 5 MB', (value: File) => {
                    return value && value.size <= maxFileSize
                })
                .test('allowed-file-formats', 'Only jpeg, jpg, png & pdf files are allowed', (value: File) => {
                    return value && allowedFileTypes.includes(value.type)
                })
        }: {}),
        ...(forStaff ? {
            merchantId: yup.string().required('Merchant Id is required'),
            isActive: yup.boolean().required()
        } : {})
    })

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                handleSubmit(getEditedPayload(initialValues, values), actions)
            }}
            enableReinitialize
        >
            {({ isValid, dirty, isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Chip
                                label={messages[`account.status.${chipAttrs.text}`]}
                                classes={{ colorPrimary: chipAttrs.className }}
                                color='primary'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikTextField
                                name='accName'
                                variant='outlined'
                                required
                                fullWidth
                                placeholder={messages['account.name']}
                                label={messages['account.name']}
                                size="small"
                                disabled={forStaff}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikTextField
                                name='accNumber'
                                variant='outlined'
                                required
                                fullWidth
                                placeholder={messages['account.no']}
                                label={messages['account.no']}
                                size='small'
                                disabled={forStaff}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikTextField
                                name='branchName'
                                variant='outlined'
                                required
                                fullWidth
                                placeholder={messages['account.branch']}
                                label={messages['account.branch']}
                                size='small'
                                disabled={forStaff}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikTextField
                                name='ifsc'
                                variant='outlined'
                                fullWidth
                                placeholder={messages['ifsc']}
                                label={messages['ifsc']}
                                size='small'
                                disabled={forStaff}
                            />
                        </Grid>
                        {!forStaff && (
                            <Grid item xs={12}>
                                <FormikFileField 
                                    name="cancelledCheque" 
                                    label="Upload Cancelled Cheque"
                                    accept={['image/jpeg', 'image/png', 'application/pdf']}
                                />
                            </Grid>
                        )}
                        {forStaff && (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <FormikTextField
                                        name='merchantId'
                                        variant='outlined'
                                        fullWidth
                                        placeholder={messages['bank.merchantId']}
                                        label={messages['bank.merchantId']}
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikRadioGroup
                                        row={true}
                                        name='isActive'
                                        label={messages['account.status'].toString()}
                                        isBoolean={true}
                                        options={[
                                            {value: true, label: messages['account.status.active'].toString()}, 
                                            {value: false, label: messages['account.status.inactive'].toString()},
                                        ]}
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                        <Grid item xs={12}>
                            <Button
                                disabled={isSubmitting || !isValid || !dirty}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                {messages['common.save']}
                            </Button>
                        </Grid>
                        {api.status === apiStatus.FAILED && !isFieldError(api.error) && (
                            <Grid item xs={12}>
                                <Alert severity="error">{extractError(api.error)}</Alert>
                            </Grid>
                        )}
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default BankAccountForm;