import { IRow, MemoizedBasicTable, StringNumberArrayType } from 'components/Table/BasicTable';
import React, { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectDonationCategoryAsMapWithUrl, TDonationCategoryResponseMapType } from 'store/reducers/temple/donationCategorySlice';
import { IDonationResponse} from 'types/api/temple';

interface DonationTableProps {
    editHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deleteHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deletingIds?: StringNumberArrayType,
    donations: IDonationResponse[]
}

const getRows = (donations: IDonationResponse[],donCatMap:TDonationCategoryResponseMapType) : IRow[] => {
    let rows : IRow[] = []
    donations.forEach(donation => {
        rows.push({
            id: donation.id,
            values: [
                donation.name,
                donCatMap[donation.category]?.name,
                donation.isDateRequired ? 'Yes' : 'No',
                `${donation.isAmountFixed?'Fixed':'Varying'} (${
                    donation.isAmountFixed ? 
                        donation.amount ?? 'Not Set' :
                        `Min: ${ donation.minAmount ?? 'Not set'}, Max: ${ donation.maxAmount ?? 'Not set' }`
                })`,
            ]
        })
    })
    return rows
}

const DonationTable : React.FC<DonationTableProps> = ({editHandler, deleteHandler, deletingIds, donations}) => {
    const donationCategoryMap = useAppSelector(selectDonationCategoryAsMapWithUrl) 

    const rows = useMemo(() => getRows(donations,donationCategoryMap),[donations,donationCategoryMap])
    return (
        <MemoizedBasicTable
            rows={rows}
            headRow={[
                'common.name',
                'common.category',
                'donation.IsDateRequired',
                'common.amount',
            ]}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            deletingIds={deletingIds}
        />
    );
};

export default DonationTable;