import { Box, CircularProgress, createStyles, makeStyles, Paper, Typography } from "@material-ui/core"
import { ITempleResponse } from "types/api/temple"
import PanoramaIcon from '@material-ui/icons/Panorama';
import { useAppDispatch, useAppSelector } from "store/hooks";
import { TempleImgType } from "types/store/temple";
import { useState } from "react";
import { CremaTheme } from 'types/crema/AppContextPropsType';
import { DropzoneDialog } from "material-ui-dropzone";
import { allowedImgTypes, maxFileSize } from "shared/constants/AppConst";
import IntlMessages from "@crema/utility/IntlMessages";
import { getImage } from "utils/fns";
import { apiStatus, ImageSizes } from "shared/constants/AppEnum";
import clsx from 'clsx'
import { selectImgUploadApi, uploadImgThunk } from "store/reducers/temple/templeSlice";

const useStyles = makeStyles((theme: CremaTheme) => (
    createStyles({
        bgImg: {
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            backgroundColor: '#ffffff'
        },
        imgsContainer: {
            position: 'relative',
            textAlign: 'center',
            padding : '20px'
        },
        coverImg: {
            width: "100%",
            height: '15vh',
            fontSize: '1.2rem',
        },
        changeContainer: {
            width: '100%',
            height: '100%',
            padding: 10,
            background: 'rgba(0,0,0,0.5)',
            color: theme.palette.primary.contrastText,
        },
        cvrChangeContainer: {
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            padding: 20
        },
        displayChangeContainer: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        loader: {
            background: 'rgba(0,0,0,0.5)',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0
        },
        circularProgess: {
            color: "#fff"
        },
        flex: {
            display: 'flex',
        },
        hidden: {
            display: 'none'
        },
    })
))

type Props = {
    temple: ITempleResponse
}

export default function LogoUpload({ temple }: Props) {
    const classes = useStyles()
    const [coverCls, setCoverCls] = useState(classes.hidden)
    const [dialog, setDialog] = useState(false)
    const api = useAppSelector(selectImgUploadApi)

    const dispatch = useAppDispatch()
    const openDialog = () => {
        setDialog(true)
    }
    const closeDialog = () => {
        setDialog(false)
    }
    const saveDialog = (files: File[]) => {
        if (files.length === 1) {
            dispatch(uploadImgThunk({
                id: temple.id,
                url: temple.url,
                file: files[0],
                imgType: 'logo'
            }))
        }
        setDialog(false)
    }

    return (
        <Paper style={{ marginBottom: '24px' }}>
            <Box p={3}>
                <Box display="flex" style={{ gap: 7, alignItems: 'center', marginBottom: '20px'}}>
                    <PanoramaIcon fontSize="small" />
                    <Typography style={{ fontWeight: 700 }}>Temple Logo</Typography>
                </Box>

                <Box className={classes.imgsContainer}>
                    <Box
                        className={clsx(classes.bgImg, classes.coverImg)}
                        style={{
                            backgroundImage: temple.logoImg ? `url(${getImage(temple.logoImg, ImageSizes.LARGE)})` : '',
                        }}
                        onMouseEnter={() => setCoverCls(classes.flex)}
                        onMouseLeave={() => setCoverCls(classes.hidden)}
                    >
                        <Box
                            onClick={() => openDialog()}
                            className={clsx(
                                classes.changeContainer,
                                classes.cvrChangeContainer,
                                api.logo.status === apiStatus.LOADING ? classes.hidden : coverCls
                            )}
                        >
                            <Box component="span">
                                <IntlMessages id="logo.change" />
                            </Box>
                        </Box>
                        <Box className={clsx(classes.loader, api.logo.status !== apiStatus.LOADING ? classes.hidden : '')}>
                            <CircularProgress size="2em" classes={{ colorPrimary: classes.circularProgess }} />
                        </Box>
                    </Box>
                </Box>
                <DropzoneDialog
                    open={dialog}
                    onSave={saveDialog}
                    acceptedFiles={allowedImgTypes}
                    showPreviews={false}
                    maxFileSize={maxFileSize}
                    onClose={() => closeDialog()}
                    filesLimit={1}
                    showPreviewsInDropzone={true}
                // useChipsForPreview={true}
                />
            </Box>
        </Paper >
    )
}