import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { offeringFinancialApi } from "api/temple";
import { apiStatus } from "shared/constants/AppEnum";
import { AppState } from "store/reduxStore";
import { IApiState } from "store/types";
import { IOfferingFinancialItemSType } from "types/api/offeringFinancial";
import dateUtil from "utils/date.util";
import { formToFilterArgs } from "utils/temple/receipt";
import { ReceiptFilterArgsType } from "./receiptSlice/types";


const offeringFinancialAdapter = createEntityAdapter<IOfferingFinancialItemSType>()

const defaultDurationType: FinancialDurationType = 'today' 


export type OfferingFinancialFilterType = Omit<ReceiptFilterArgsType,'orderBy'|'asc'|'pageSize'>
export type OfferingFinancialListArgs = {
    filter: OfferingFinancialFilterType,
    durationType: FinancialDurationType
}

type TotalValuesType = {
    appCount: number,
    counterCount: number,
    posCount: number,
    websiteCount: number
    count:number,
    amount:number,
    dakshina:number
}

type addOnInitialStateType = {
    api: IApiState,
    listArgs: OfferingFinancialListArgs,
    total:TotalValuesType
    
}
export type EditedOfferingFinancialType ={
    item:IOfferingFinancialItemSType,
    total:TotalValuesType
} 

type FinancialDurationType = 'today' | 'thisMonth' | 'lastMonth' | 'thisYear' | '' 

const _getFilterParams = (dates: {start: Date, end: Date}) => formToFilterArgs({
    fromBookingDate: dates.start,
    toBookingDate: dates.end,
    fromDate: '',
    toDate: '',
    offering: '',
    category: '',
    deity: '',
    fromTime: '',
    toTime: ''
})

const processDurationType = (durationType: FinancialDurationType) => {
    switch(durationType){
        case 'today': {
            const [start, end] = dateUtil.getDayStartEnd(new Date())
            return _getFilterParams({start: start, end: end})
        }
        case 'thisMonth':{
            const date = new Date()
            const [start, end] = dateUtil.getMonthStartEnd(date.getFullYear(), date.getMonth())
            return _getFilterParams({start: start, end: end})
        }
        case 'lastMonth': {
            const date = new Date();
            const [start, end] = dateUtil.getMonthStartEnd(date.getFullYear(), date.getMonth() - 1)
            return _getFilterParams({start: start, end: end})
        }
        case 'thisYear': {
            const year = new Date().getFullYear()
            const date = new Date(year, 0, 1)
            return _getFilterParams({start: date, end: new Date()})
        }
        default: {
            return {}
        }
    }
}

const addOnInitialState : addOnInitialStateType = 
    {
        api: {
            status: apiStatus.IDLE,
            error: null
        },
        listArgs: {
            filter: {
                status: 'paid',
                ...processDurationType(defaultDurationType)
            },
            durationType: defaultDurationType
        },
        total:{
            appCount: 0,
            counterCount: 0,
            posCount: 0,
            websiteCount: 0,
            count:0,
            amount:0,
            dakshina:0
        }
    }


export const listOfferingFinancialThunk = createAsyncThunk(
    'offeringFinancial/list',
    async (payload: string|number, { rejectWithValue, getState }) => {
        const appState = getState() as AppState
        const args : OfferingFinancialListArgs = appState.offeringFinancial.listArgs
            try {
                const response = await offeringFinancialApi.list({...args.filter},payload)
                const editedItemsList = response.data.items.map((item,index)=>{
                    return { ...item,id:index+1}
                })
                const editedData = {items:{...editedItemsList},total:{amount:response.data.totalAmount,appCount:response.data.totalAppCount,counterCount:response.data.totalCounterCount,posCount:response.data.totalPosCount,websiteCount:response.data.totalWebsiteCount,count:response.data.totalCount,dakshina:response.data.totalDakshina}}
                return editedData
            } catch (err: any) {
                return rejectWithValue(err.response.data)
            }
    }
)

export const listOfferingDownloadPdfThunk = createAsyncThunk(
    'receipt/download/pdf',
    async (payload:{templeId:string|number}, { rejectWithValue, getState }) => {
        const appState = getState() as AppState
        const args : OfferingFinancialListArgs = appState.offeringFinancial.listArgs
        try {
            const response = await offeringFinancialApi.generatePDFDownloadUrl({...args.filter},payload.templeId) 
            window.open(response.data.downloadUrl, '_self')
            return
        }
        catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const downloadExcelThunk = createAsyncThunk(
    'receipt/download/excel',
    async (payload:{templeId:string|number}, { rejectWithValue, getState }) => {
        const appState = getState() as AppState
        const args : OfferingFinancialListArgs = appState.offeringFinancial.listArgs
        try {
            const response = await offeringFinancialApi.generateExcelUrl({...args.filter},payload.templeId) 
            window.open(response.data.downloadUrl, '_self')
            return
        }
        catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const offeringFinancialSlice = createSlice({
    name: 'offeringFinancial',
    initialState: offeringFinancialAdapter.getInitialState(addOnInitialState),
    reducers: {
        resetState(state) {
            state.api = {
                status: apiStatus.IDLE,
                error: null
            }
            offeringFinancialAdapter.removeAll(state)
        },
        setFilters(state, action: PayloadAction<Partial<OfferingFinancialFilterType>>) {
            state.listArgs = {
                filter: {
                    status: state.listArgs.filter.status,
                    ...action.payload
                },
                durationType: state.listArgs.durationType
            }
        },
        setDuration(state, action: PayloadAction<FinancialDurationType>) {
            state.listArgs = {
                filter: {
                    status: state.listArgs.filter.status,
                    ...processDurationType(action.payload)
                },
                durationType: action.payload
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(listOfferingFinancialThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error:null
            }
            state.total=action.payload.total
            offeringFinancialAdapter.removeAll(state)
            offeringFinancialAdapter.addMany(state,action.payload.items)
        })

        builder.addMatcher(isAnyOf(listOfferingFinancialThunk.pending), (state) => {
            state.api = {
                status: apiStatus.LOADING
            }
        })
        builder.addMatcher(isAnyOf(listOfferingFinancialThunk.rejected), (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
    },
})

export default offeringFinancialSlice.reducer

export const { resetState, 
    setFilters:setOfferingFinancialFilters,
    setDuration: setOfferingFinancialDuration
} = offeringFinancialSlice.actions

export const {
    selectAll: selectAllOfferingFinancial,
    // selectById: selectOfferingFinancialById,
    // selectIds: selectOfferingFinancialIds,
} = offeringFinancialAdapter.getSelectors((state: AppState) => state.offeringFinancial)

export const selectTotalvalues = (state:AppState) => state.offeringFinancial.total

// export const selectTotalEntities = (state:AppState) =>state.offeringFinancial.entities

export const selectOfferingFinancialFilterArgs = (state: AppState) => state.offeringFinancial.listArgs.filter

export const selectOfferingFinancialDuration = (state: AppState) => state.offeringFinancial.listArgs.durationType

export const selectOfferingFinancialApi = (state: AppState) => state.offeringFinancial.api

export const selectAllOfferingFinancialData = createSelector(
    [selectTotalvalues,selectAllOfferingFinancial],
    (total,items) => {
        return{item:items,total:total}
    }
)