import { DeepPartial } from '@reduxjs/toolkit'
import { weekDays } from 'shared/constants/AppConst'
import { BlockPeriods, DonationTypes, OfferingTypes } from 'shared/constants/AppEnum'
import { ITempleResponse } from 'types/api/temple'

export interface IOfferingCategory {
    name: string,
}

export interface IDonationCategory {
    name: string
}



export type WeeklyDayType = typeof weekDays[number]

export type WeeklyDaysFlagType = Record<WeeklyDayType, boolean>

export enum BlockCalcEnum {
    FROM = 'from_time',
    TO = 'to_time'
}

export type IOfferingTime = {
    fromTime: string
    toTime?: string | null
    maxLimit: number | null
    blockCalcChooser: BlockCalcEnum
}

export type IOfferingMetaType = {
    dates?: string[],
    excludes?: string[],
    days?: (number | WeeklyDayType)[]
}

export type IDonationMetaType = {
    dates?: string[],
    excludes?: string[],
    days?: (number | WeeklyDayType)[]
}

export type IOfferingBaseType = {
    name: string
    price: number,
    canChangePrice: boolean,
    offeringType: OfferingTypes
    perDayLimit: null | number
    blockPeriod?: BlockPeriods
    blockUnit: null | number
    repeatUnit: null | number
    offeringMeta?: IOfferingMetaType
    useClosingTime?: Boolean
    isRecommended?: Boolean
    isCourierAvailable?: Boolean
    description?: null | string,
    isDakshinaRequired?: boolean,
    minDakshinaAmount?: null | number,
    availableOffline: boolean,
    availableOnline: boolean,
    assignedDeities:string[]
}
export type IDonationBaseType = {
    name: string,
    amount: number | null,
    minAmount: number | null,
    maxAmount: number | null,
    donationType: DonationTypes,
    perDayLimit?: null | number
    blockPeriod?: BlockPeriods
    blockUnit?: null | number
    repeatUnit?: null | number,
    donationMeta?: IDonationMetaType,
    description?: null | string,
    isDateRequired: boolean,
    isAmountFixed: boolean,

}
export type IOffering = IOfferingBaseType & {
    category: number | string
    offeringTimes: IOfferingTime[]
}

export type IOfferingFormDatesType = {
    fieldDate: Date | '',
    dates: Date[]
}

export type IDonation = IDonationBaseType & {
    category: number | string,
}
export type IDonationFormDatesTypes = {
    fieldDate: Date | '',
    dates: Date[]
}

export type IOfferingFormTimeType = {
    id?: number | string,
    url?: string,
    fromTime: Date
    toTime: Date | ''
    blockCalcChooser: BlockCalcEnum
    maxLimit: number | ''
}

// export type CurrentEditingTimeType = 
//     AddEmptyStringType<Pick<IOfferingFormTimeType, 'fromTime' | 'toTime'>> & 
//     Omit<IOfferingFormTimeType, 'fromTime' | 'toTime'> &
//     {index: number | ''}


export type CurrentEditingTimeType =
    AddEmptyStringType<Pick<IOfferingFormTimeType, 'fromTime'>> &
    Omit<IOfferingFormTimeType, 'fromTime'> &
    { index: number | '' }

export type IOfferingFormTimesType = {
    currentEditing: CurrentEditingTimeType
    times: IOfferingFormTimeType[]
}

export type IOfferingFormFeeSplitEntryType = {
    name: '' | string,
    percent: '' | number,
    value: '' | number
}

export type TAdditionalField = {
    fieldName: string,
    fieldType: string,
    description?: string;
    required: boolean;
    minValue?: number | string | null,
    maxValue?: number | string | null,
    minLength?: number | string | null,
    maxLength?: number | string | null,
    addToPrice: boolean,
    allowMultiplier: boolean,
    multiplierLabel?: string
}

export type IOfferingFormType = {
    name: string,
    category: string | number,
    price: '' | number,
    canChangePrice: boolean,
    offeringType: OfferingTypes,
    description: string,
    isRecommended: Boolean,
    isCourierAvailable: Boolean,
    blockPeriod: BlockPeriods,
    blockUnit: '' | number,
    repeatUnit: '' | number,
    useClosingTime?: Boolean,
    offeringTimes: IOfferingFormTimesType,
    isDakshinaRequired: boolean,
    minDakshinaAmount?: '' | number,
    perDayLimit: number | '',
    availableOnline: boolean,
    availableOffline: boolean,
    additionalField: TAdditionalField,
    offeringMeta: {
        customDates: IOfferingFormDatesType,
        monthDays: { [key: string]: boolean; },
        weekDays: WeeklyDaysFlagType,
        excludedDates: IOfferingFormDatesType,
    }
    splitEntries?: IOfferingFormFeeSplitEntryType[],
    assignedDeities: string[]
}

export type IDonationFormType = {
    name: string,
    donationType: DonationTypes,
    category: string ,
    amount: '' | number,
    perDayLimit: number | '',
    minAmount: '' | number,
    maxAmount: '' | number,
    description: string,
    blockPeriod: BlockPeriods,
    blockUnit: '' | number,
    repeatUnit: '' | number,
    isDateRequired: boolean,
    isAmountFixed: boolean,
    donationMeta: {
        customDates: IDonationFormDatesTypes,
        monthDays: { [key: string]: boolean; },
        weekDays: WeeklyDaysFlagType,
        excludedDates: IDonationFormDatesTypes,
    }
}

export type IDeityType = {
    name: string,
    isMain: boolean,
    deityImg?: ImageResponse,
    url: string,
    offerings?:string[]
}


export type TempleImgType = 'cover' | 'display' | 'logo'

export type ITempleFormType = Omit<DeepPartial<ITempleResponse>, 'openingTime' | 'closingTime'> & {
    openingTime: Date | '',
    closingTime: Date | '',

}