import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import { useStyles } from './style';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface FullWidthDialogProps {
    open: boolean,
    handleClose: () => void,
}

const FullWidthDialog: React.FC<FullWidthDialogProps> = ({ handleClose, children, open }) => {
    const classes = useStyles()
    return (
        <Dialog fullScreen open={open} TransitionComponent={Transition}>
            <Box width="100%" p={4} display="flex" justifyContent="space-between" alignItems="center">
                <img alt="logo" className={classes.logo} src="/assets/images/logo/tclick_logo.svg" />
                <IconButton edge="start" onClick={handleClose} aria-label="close">
                    <CloseIcon color="primary" />
                </IconButton>
            </Box>
            {children}
        </Dialog>
    );
};

export default FullWidthDialog;