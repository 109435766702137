import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { apiStatus } from 'shared/constants/AppEnum'
import { AppState } from 'store/reduxStore'
import { IApiState } from 'store/types'
import { FeeSplitNameResponseType } from 'api/report_app/report_app.response'
import { ContentObjectBaseType } from 'api/report_app/report_app.base'
import { feeSplitNameAPI } from 'api/report_app'
import { FeeSplitNameRequestType } from 'api/report_app/report_app.request'

const feeSplitNameAdapter = createEntityAdapter<FeeSplitNameResponseType>({
    // sortComparer: (a, b) => a.name.localeCompare(b.name),
    // selectId: (obj) => obj.url
})

type InitialStateType = {
    api: IApiState
}

const addOnIntitalState: InitialStateType = {
    api: {
        status: apiStatus.IDLE,
        error: null
    }
}

export const feeSplitNameListThunk = createAsyncThunk(
    'feeSplitName/list',
    async(payload: ContentObjectBaseType, {rejectWithValue}) => {
        try{
            const response = await feeSplitNameAPI.list(payload)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const feeSplitNameCreateThunk = createAsyncThunk(
    'feeSplitName/create',
    async(payload: FeeSplitNameRequestType, {rejectWithValue, getState}) => {
        try{
            const response = await feeSplitNameAPI.post(payload)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const feeSplitNameUpdateThunk = createAsyncThunk(
    'feeSplitName/update',
    async(payload: PayloadWithUrl<Partial<FeeSplitNameRequestType>>, {rejectWithValue}) => {
        try{
            const response = await feeSplitNameAPI.update(payload.url, payload.data)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const feeSplitNameRetrieveThunk = createAsyncThunk(
    'feeSplitName/retrieve',
    async(payload: UrlType, {rejectWithValue}) => {
        try{
            const response = await feeSplitNameAPI.get(payload.url)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const feeSplitNameDeleteThunk = createAsyncThunk(
    'feeSplitName/delete',
    async (payload: IdAndUrlType) => {
        await feeSplitNameAPI.delete(payload.url)
        return payload.id
    }
)


const feeSplitNameSlice = createSlice({
    name: 'feeSplitName',
    initialState: feeSplitNameAdapter.getInitialState(addOnIntitalState),
    reducers: {
        resetState(state) {
            state.api = {
                status: apiStatus.IDLE,
                error: null
            }
            feeSplitNameAdapter.removeAll(state)
        }
    },
    extraReducers: builder => {

        builder.addCase(feeSplitNameListThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            feeSplitNameAdapter.upsertMany(state, action.payload)
        })
        builder.addCase(feeSplitNameDeleteThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            feeSplitNameAdapter.removeOne(state, action.payload)
        })

        builder.addMatcher(
            isAnyOf(
               feeSplitNameListThunk.pending,
               feeSplitNameCreateThunk.pending,
               feeSplitNameUpdateThunk.pending,
               feeSplitNameDeleteThunk.pending,
               feeSplitNameRetrieveThunk.pending,
            ),(state,action) =>{
                state.api ={
                    status:apiStatus.LOADING,
                    error:null
                }
            }
        )
        builder.addMatcher(
            isAnyOf(
               feeSplitNameListThunk.rejected,
               feeSplitNameCreateThunk.rejected,
               feeSplitNameUpdateThunk.rejected,
               feeSplitNameDeleteThunk.rejected,
               feeSplitNameRetrieveThunk.rejected,
            ),(state, action) =>{
                state.api = {
                    status: apiStatus.FAILED,
                    error: action.payload
                }
            }
        )
        builder.addMatcher(
            isAnyOf(
               feeSplitNameCreateThunk.fulfilled,
               feeSplitNameUpdateThunk.fulfilled,
               feeSplitNameRetrieveThunk.fulfilled,
            ),(state, action) =>{
                state.api = {
                    status: apiStatus.SUCCEEDED,
                    error: null
                }
                feeSplitNameAdapter.upsertOne(state, action.payload)
            }
        )
    }
})

export default feeSplitNameSlice.reducer

export const { resetState } = feeSplitNameSlice.actions

export const {
    selectAll: selectAllFeeSplitNames,
    selectById: selectfeeSplitNameById,
    selectIds: selectfeeSplitNameIds,
} = feeSplitNameAdapter.getSelectors((state: AppState) => state.feeSplitName)

export const selectfeeSplitNameStatus = (state: AppState): IApiState => state.feeSplitName.api

export const selectFeeSplitNameByContentObject = createSelector(
    (state: AppState) => state, 
    (_:any, contentObject: string) => contentObject,
    (state, contentObject) => selectAllFeeSplitNames(state).filter(feeSplitName => feeSplitName.contentObject === contentObject)
)

