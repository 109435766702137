import React, {useContext} from 'react';
import Avatar from '@material-ui/core/Avatar';
import {useAppDispatch} from 'store/hooks'
import {logoutThunk, UserRoleType} from 'store/reducers/auth/authSlice';
import {useAuthUser} from '@crema/utility/AppHooks';
import AppContext from '@crema/utility/AppContext';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import {Fonts, ThemeMode} from 'shared/crema/constants/AppEnums';
import Hidden from '@material-ui/core/Hidden';
import AppContextPropsType from 'types/crema/AppContextPropsType';
import useStyles from 'assets/theme/components/headerUser'
import IntlMessages from '@crema/utility/IntlMessages';
import { useHistory } from 'react-router';

interface HeaderUserProps {
  header?: boolean;
}

const HeaderUser: React.FC<HeaderUserProps> = ({header = true}) => {
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const user: UserResponseType | null = useAuthUser();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserAvatar = () => user?.fullName?.charAt(0).toUpperCase() ?? '';

  const getUserAvatarView = () => {
    // if (user && user.photoURL) {
    //   return <Avatar className={classes.profilePic} src={user.photoURL} />;
    // } else {
    //   return <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>;
    // }
    return <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>;
  };

  const classes = useStyles({themeMode, header});

  const getSwitchUserText = (role: UserRoleType) => role === 'staff' ? 'sidebar.use.temple' : 'sidebar.use.staff'

  const switchUser = () => history.push('/user-switch')

  const handleLogout = () => {
    handleClose()
    dispatch(logoutThunk())
  }

  const handleSwitchUser = () => {
    handleClose()
    switchUser()
  } 

  return (
    <Box
      px={{xs: 2, xl: 6}}
      className={clsx(classes.crHeaderUser, 'cr-user-info')}>
      <Box display='flex' alignItems='center'>
        <Hidden mdDown> {getUserAvatarView()}</Hidden>
        <Box className={clsx(classes.userInfo, 'user-info')}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'>
            <Hidden mdDown>
              <Box ml={4} mb={1.5} className={classes.userName}>
                {user && user.fullName}
                <Box
                  fontSize={13}
                  fontWeight={Fonts.LIGHT}
                  color='text.secondary'>
                  {user && ( user?.email ?? user?.phone)}
                </Box>
              </Box>
            </Hidden>
            <Box
              ml={{md: 3}}
              className={classes.pointer}
              color={
                themeMode === ThemeMode.DARK || !header ? 'white' : '#313541'
              }>
              <Hidden mdDown>
                <ExpandMoreIcon onClick={handleClick} />
              </Hidden>
              <Hidden lgUp>
                <Box component='span' onClick={handleClick}>
                  {getUserAvatarView()}
                </Box>
              </Hidden>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {user?.role && user.isStaff && (
                  <MenuItem onClick={() => handleSwitchUser()}>
                    <IntlMessages id={getSwitchUserText(user.role)} />
                  </MenuItem>
                )}
                <MenuItem onClick={() => handleLogout()}>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default HeaderUser;
HeaderUser.defaultProps = {
  header: true,
};
