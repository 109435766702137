import IntlMessages from "@crema/utility/IntlMessages"
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, MenuItem, TextField, Typography } from "@material-ui/core"
import { unwrapResult } from "@reduxjs/toolkit"
import { useFormik } from "formik"
import { useEffect } from "react"
import { useParams } from "react-router"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { addonFieldAddThunk, addonFieldEditThunk, selectAddonApiStatus } from "store/reducers/temple/offeringAddonSlice"
import { selectOfferingById } from "store/reducers/temple/offeringSlice"
import { TAdditionalField } from "types/store/temple"
import { extractError } from "utils/api"
import { removeNullEntries } from "utils/fns"
import { defaultNewField } from "utils/temple/offering"
import * as Yup from 'yup'
import { IAddonResponse, TAddonPayload } from "./AdditionalFields"
import { apiStatus } from "shared/constants/AppEnum"
import { Alert } from "@material-ui/lab"
import XIcon from '@material-ui/icons/Close'

const validationSchema = Yup.object({
    fieldName: Yup.string().required("Field name is required"),
    fieldType: Yup.string().required("Field type is required"),
    description: Yup.string().optional().nullable(),
    minValue: Yup.number().when("addToPrice", {
        is: true,
        then: Yup.number().min(0).optional().nullable(),
    }).optional().nullable(),
    minLength: Yup.number().min(1, "Minimum Length must be greater than or equal to 1").optional().nullable(),
    maxValue: Yup.number().optional().nullable(),
    maxLength: Yup.number().optional().nullable(),
    addToPrice: Yup.boolean().required(),
    allowMultiplier: Yup.boolean().required(),
    multiplierLabel: Yup.string().when('allowMultiplier', { is: true, then: Yup.string().required(" Multiplier label is required").nullable() }).nullable()
})

type Props = {
    edit?: TAdditionalField & IAddonResponse | null,
    cancelEdit?: () => void
}

interface RouteParams {
    id: string | undefined,
}

const addonFieldTypes = [
    {
        type: 'str',
        label: 'Text'
    },
    {
        type: 'int',
        label: 'Number(Int)'
    },
    {
        type: 'float',
        label: 'Number(Float)'
    }
]


export default function FieldsForm({ edit, cancelEdit }: Props) {
    const { id } = useParams<RouteParams>()
    const dispatch = useAppDispatch()
    const offering = useAppSelector(state => selectOfferingById(state, id ? id : ''))
    const api = useAppSelector(selectAddonApiStatus)
    const { values: formState, setFieldValue, setValues, resetForm, errors, handleSubmit } = useFormik<TAdditionalField>({
        initialValues: defaultNewField,
        enableReinitialize: true,
        validationSchema,
        validateOnChange: true,
        onSubmit: (values) => {
            const field: TAddonPayload = {
                ...values,
                dataType: values.fieldType,
                offering: offering?.url ?? '',
                minValue: undefined,
                minLength: undefined,
                maxValue: undefined,
                maxLength: undefined,
                description: values?.description || undefined,
                multiplierLabel: values?.multiplierLabel || undefined
            }
            if(field?.dataType !== 'str') {
                field.minValue = values?.minValue === '' ? null : values?.minValue ?? undefined
                field.maxValue = values?.maxValue === '' ? null : values?.maxValue ?? undefined
            } else {
                field.minLength = values?.minLength === '' ? null : values?.minLength ?? undefined
                field.maxLength = values?.maxLength === '' ? null : values?.maxLength ?? undefined
            }
            if (edit) {
                dispatch(addonFieldEditThunk({
                    addonId: edit.id,
                    field
                    }))
                    .then(unwrapResult)
                    .then(() => {
                        if(cancelEdit) cancelEdit()
                        resetForm()
                    }).catch(() => { })
            } else {
                dispatch(addonFieldAddThunk(field))
                    .then(unwrapResult)
                    .then(() => {
                        resetForm()
                    }).catch(() => { })
            }
        }
    })

    useEffect(() => {
        if (edit) setValues(edit)
    }, [edit])

    useEffect(() => {
        if (!formState) setValues(defaultNewField)
    }, [formState])

    return (
        <form onSubmit={handleSubmit}>
                <Box style={{ backgroundColor: 'rgba(231, 245, 255, 0.1)', border: '1px solid #d0ebff', borderRadius: '8px', padding: "12px" }}>
                    <Box style={{display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='body1' style={{ fontWeight: 500, marginBottom: '16px' }}>
                            {edit ? "Edit Field" : "Add Field"}
                        </Typography>
                        {edit && (
                          <IconButton size='small' onClick={() => {
                                resetForm()
                                if(cancelEdit) cancelEdit()
                            }}>
                                <XIcon />
                            </IconButton> 
                        )}
                    </Box>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={4}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                                label={<IntlMessages id='offering.additional.fieldName' />}
                                onChange={(e) => setFieldValue('fieldName', e.target.value)}
                                variant='outlined'
                                fullWidth
                                value={formState?.fieldName}
                                size='small'
                                helperText={errors.fieldName}
                                error={Boolean(errors?.fieldName)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                required
                                label={<IntlMessages id='offering.additional.fieldType' />}
                                variant='outlined'
                                onChange={(e) => {
                                    setFieldValue('fieldType', e.target.value)
                                    if (e.target.value !== "str") {
                                        setFieldValue('minValue', formState?.minValue ? e.target.value === 'int' ? Math.floor(Number(formState.minValue)) : formState.minValue : undefined)
                                        setFieldValue('maxValue', formState?.maxValue ? e.target.value === 'int' ? Math.ceil(Number(formState.maxValue)) : formState.maxValue : undefined)
                                        setFieldValue('minLength', undefined)
                                        setFieldValue('maxLength', undefined)
                                    } else {
                                        setFieldValue('minLength', formState?.minLength ? Math.floor(Number(formState.minLength)) : undefined)
                                        setFieldValue('maxLength', formState?.maxLength ? Math.floor(Number(formState.maxLength)) : undefined)
                                        setFieldValue('minValue', undefined)
                                        setFieldValue('maxValue', undefined)
                                    }
                                    if (e.target.value !== "float") {
                                        setFieldValue('addToPrice', false)
                                        setFieldValue('allowMultiplier', false)
                                    }
                                }}
                                fullWidth
                                value={formState?.fieldType}
                                helperText={errors.fieldType}
                                error={Boolean(errors?.fieldType)}
                                size='small'
                            >
                                {addonFieldTypes.map(f => (
                                    <MenuItem value={f.type} key={f.label}>
                                        {f.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={<IntlMessages id='common.description' />}
                                onChange={(e) => setFieldValue('description', e.target.value)}
                                variant='outlined'
                                fullWidth
                                value={formState?.description}
                                size='small'
                                helperText={errors.description}
                                error={Boolean(errors?.description)}
                            />
                        </Grid>
                        {formState?.fieldType === "str" ? (
                            <>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => setFieldValue('minLength', e.target.value && Number(e.target.value) > 0 ? Math.floor(Number(e.target.value)) : e.target.value)}
                                        label={<IntlMessages id='offering.additional.minLength' />}
                                        variant='outlined'
                                        type='number'
                                        fullWidth
                                        value={formState.minLength}
                                        size='small'
                                        helperText={errors.minLength}
                                        error={Boolean(errors?.minLength)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label={<IntlMessages id='offering.additional.maxLength' />}
                                        variant='outlined'
                                        fullWidth
                                        type='number'
                                        onChange={(e) => setFieldValue('maxLength', e.target.value && Number(e.target.value) > 0 ? Math.ceil(Number(e.target.value)) : e.target.value)}
                                        value={formState.maxLength}
                                        size='small'
                                        helperText={errors.maxLength}
                                        error={Boolean(errors?.maxLength)}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label={<IntlMessages id='offering.additional.minValue' />}
                                        variant='outlined'
                                        onChange={(e) => setFieldValue('minValue', formState?.fieldType === "int" && e.target.value && Number(e.target.value) > 0 ? Math.floor(Number(e.target.value)) : e.target.value)}
                                        fullWidth
                                        type='number'
                                        value={formState?.minValue}
                                        size='small'
                                        helperText={errors.minValue}
                                        error={Boolean(errors?.minValue)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label={<IntlMessages id='offering.additional.maxValue' />}
                                        variant='outlined'
                                        onChange={(e) => setFieldValue('maxValue', formState?.fieldType === "int" && e.target.value && Number(e.target.value) > 0 ? Math.ceil(Number(e.target.value)) : e.target.value)}
                                        fullWidth
                                        type='number'
                                        value={formState?.maxValue}
                                        size='small'
                                        helperText={errors.maxValue}
                                        error={Boolean(errors?.maxValue)}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} >
                            {formState.fieldType === "float" && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formState?.allowMultiplier}
                                            name="allowMultiplier"
                                            style={{ marginLeft: "4px" }}
                                            size='small'
                                            onChange={(e) => {
                                                setFieldValue('allowMultiplier', e.target.checked)
                                            }}
                                        />
                                    }
                                    label="Use Multiplier"
                                />
                            )}
                            
                            {formState.fieldType === "float" && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formState?.addToPrice}
                                            name="addToPrice"
                                            style={{ marginLeft: "4px" }}
                                            size='small'
                                            onChange={(e) => {
                                                setFieldValue('addToPrice', e.target.checked)}}
                                        />
                                    }
                                    label="Add to Price"
                                />
                            )}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formState?.required}
                                        name="required"
                                        style={{ marginLeft: "4px" }}
                                        size='small'
                                        onChange={(e) => setFieldValue('required', e.target.checked)}
                                    />
                                }
                                label="Required"
                            />
                        </Grid>
                        {formState?.allowMultiplier && (
                            <Grid item xs={12}>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Multiplier Label"
                                    onChange={(e) => setFieldValue('multiplierLabel', e.target.value)}
                                    variant='outlined'
                                    fullWidth
                                    value={formState?.multiplierLabel}
                                    size='small'
                                    helperText={errors.multiplierLabel}
                                    error={Boolean(errors?.multiplierLabel)}
                                />
                            </Grid>
                        )}
                        
                        
                        {api.status === apiStatus.FAILED && (
                            <Grid item xs={12}>
                                <Alert severity="error">{extractError(api.error)}</Alert>
                            </Grid>
                        )}
                        <Grid item style={{ gap: '8px', display: "flex" }}>
                            <Button
                                variant="outlined"
                                type='submit'
                            >
                                {edit ? <IntlMessages id="common.save" /> : <IntlMessages id="offering.additional.add" />}
                            </Button>
                            {edit && (
                                <Button
                                    onClick={() => {
                                        resetForm()
                                        if(cancelEdit) cancelEdit()
                                    }}
                                    type='button'
                                >
                                    <IntlMessages id="common.cancel" />
                                </Button>
                            )}
                        </Grid>
                        {api.status === apiStatus.LOADING && (
                            <Grid item>
                                <CircularProgress size='small' />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </form>
    )
}