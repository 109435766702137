import React from 'react'
import { makeStyles, createStyles} from '@material-ui/core/styles'
import { CremaTheme } from 'types/crema/AppContextPropsType'
import Button, { ButtonProps } from '@material-ui/core/Button'

const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        outlined: {
            border: `1px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            '&:hover': {
                color: theme.palette.primary.contrastText,
                background: theme.palette.error.main,
            }
        }
    })
)

const RedButton = (props: ButtonProps) => {
    const classes = useStyles()
    return <Button {...{...props, classes: {outlined: classes.outlined}}}  />
}

export default RedButton