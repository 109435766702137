import { Box, Button, FormControl, FormControlLabel, LinearProgress, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { useStyles } from "../Bookings/Bookings.style";
import { Redirect, useParams } from "react-router";
import { permissionsListThunk, permissionsUpdateThunk, selectListPermissionApiStatus, selectPermissions, selectUpdatePermissionApiStatus, selectUserById, UserRoleEnum } from "store/reducers/temple/usersSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ChevronLeft } from "@material-ui/icons";
import { apiStatus } from "shared/constants/AppEnum";
import React, { useEffect, useState } from "react";
import IntlMessages from "@crema/utility/IntlMessages";
import { Link } from "react-router-dom";
import { areArraysEqual } from "utils/fns";
import { useSnackbar } from "notistack";
import { extractError, getAxiosError } from "utils/api";
import { unwrapResult } from "@reduxjs/toolkit";
import { BLACKLISTED_PERMISSIONS } from "utils/permissions";

export default function Permissions() {
    const classes = useStyles();
    const { id } = useParams<{ id: string }>()
    const userItem = useAppSelector(state => selectUserById(state, id))
    const api = useAppSelector(selectListPermissionApiStatus)
    const dispatch = useAppDispatch()
    const permissions = useAppSelector(selectPermissions)
    const permissionApi = useAppSelector(selectUpdatePermissionApiStatus)
    const [userPermissions, setPermission] = useState<string[]>([])
    const [role, setRole] = useState<UserRoleEnum>(UserRoleEnum.staff)
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (role === UserRoleEnum.admin) {
            let newPermissions: string[] = []
            Object.values(permissions).forEach(c => {
                newPermissions = [...newPermissions, ...c.map(p => p[0])]
            })
            setPermission(newPermissions)
        }
    }, [role])


    useEffect(() => {
        if (userItem?.permissions) setPermission(userItem?.permissions ?? [])
        if (userItem?.role) setRole(userItem?.role as UserRoleEnum)
    }, [userItem?.permissions, userItem?.role])

    const handleTogglePermission = (permission: string) => {
        let newPermissions = [...userPermissions]
        if (userPermissions.includes(permission)) {
            newPermissions = userPermissions.filter(p => p !== permission)
        } else {
            newPermissions = [...(userPermissions ?? []), permission]
        }
        setPermission(newPermissions)
    }

    const savePermissions = () => {
        dispatch(permissionsUpdateThunk({ userId: id, data: { permissions: userPermissions, role } })).then(unwrapResult).then(() => {
            enqueueSnackbar("Updated permissions", { variant: 'success' })
        }).catch(err => {
            enqueueSnackbar(extractError(err), { variant: 'error' })
        })
    }

    if (!userItem || !permissions) return <Redirect to='/dashboard/manage-users' />

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} style={{ maxWidth: '1000px' }}>

                <Box p={5} position="sticky" top={-32} bgcolor={'#fff'} zIndex={999}>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/dashboard/manage-users">
                        <Box display="flex" ml={-1} mb={2}>
                            <ChevronLeft fontSize="small" />
                            <Typography variant="body1">Manage Users</Typography>
                        </Box>
                    </Link>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" id="tableTitle">
                            Permissions for {userItem.user.fullName}
                        </Typography>

                        <Box display="flex" style={{ gap: '20px' }}>
                            <TextField select size="small" variant="outlined" value={role} onChange={(e) => setRole(e.target.value as UserRoleEnum)}>
                                <MenuItem value={UserRoleEnum.staff}>Staff</MenuItem>
                                <MenuItem value={UserRoleEnum.admin}>Admin</MenuItem>
                            </TextField>

                            <Button onClick={savePermissions} disabled={(areArraysEqual(userItem?.permissions, userPermissions) && userItem.role === role) || permissionApi.status === apiStatus.LOADING} color="primary" variant="contained">
                                Update
                            </Button>
                        </Box>
                    </Box>
                </Box>

                <Table size="small" style={{ tableLayout: 'auto', maxWidth: '100%', overflowX: 'auto' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Category</TableCell>
                            <TableCell>Permission Name</TableCell>
                            <TableCell align="right">Permission Access</TableCell>
                        </TableRow>
                    </TableHead>
                    {api.status === apiStatus.LOADING && <LinearProgress />}
                    <TableBody>
                        {Object.keys(permissions).map(category =>
                            permissions[category].map((permission, index) => BLACKLISTED_PERMISSIONS.includes(category) ? null : (
                                <TableRow key={`${category}_${permission[0]}-${index}`}>
                                    {index === 0 && (
                                        <TableCell rowSpan={permissions[category].length} style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                                            <IntlMessages id={`users.permission.${category}`} />
                                        </TableCell>
                                    )}
                                    <TableCell >{permission[1]}</TableCell>
                                    <TableCell align="right">
                                        <FormControlLabel
                                            label={userPermissions.includes(permission[0]) ? "Allowed" : "Restricted"}
                                            labelPlacement="start"
                                            control={<Switch onChange={() => handleTogglePermission(permission[0])} color="primary" checked={userPermissions.includes(permission[0])} />} disabled={role === UserRoleEnum.admin} />
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
}