import React from "react";
// @material-ui/core components
import Box from "@material-ui/core/Box";
// import Container from "@material-ui/core/Container";
// import Grid from "@material-ui/core/Grid";

import {ContentView} from '@crema';

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import AuthFooter from "components/Footers/AuthFooter";

import useStyles from "assets/theme/containers/auth";



const Auth = () => {
  const classes = useStyles();

  return (
    <Box className={classes.appAuth}>
      <AuthNavbar />
        <ContentView />
      <AuthFooter />
    </Box>
  )
};

export default Auth;
