import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

const AppRefresh : React.FC=  () => {
    const history = useHistory()
    useEffect(() => {
        history.replace('/')
    }, [history])
    return <></>
}

export default AppRefresh