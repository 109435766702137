import React from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import IntlMessages from '@crema/utility/IntlMessages';
import {Fonts} from 'shared/crema/constants/AppEnums';
import useStyles from 'assets/theme/containers/auth/login/login'
import JwtSignIn from './Jwt';

const Signin: React.FC<{}> = () => {
  const classes = useStyles();

  return (
    <Box 
      flex={1} 
      display='flex' 
      flexDirection='column' 
      justifyContent='center'
      margin="6vh 5vw"
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'>
        <Card className={classes.cardRoot}>
          <Box px={{xs: 6, sm: 10, xl: 15}}>
            <Box
              component='h2'
              mb={{xs: 3, xl: 6}}
              color='text.primary'
              fontWeight={Fonts.REGULAR}
              fontSize={{xs: 24, xl: 26}}>
              <IntlMessages id='common.login' />
            </Box>
          </Box>
          <JwtSignIn />
        </Card>
      </Box>
    </Box>
  );
};

export default Signin;
