import {AuthUser} from 'types/crema/models/AuthUser';
import {AuthType, LayoutType, NavStyle} from './AppEnums';

export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'],
};

export const defaultUser: AuthUser = {
  uid: 'RFedvhji876rfhjuecvh7',
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: authRole.user,
  authType: AuthType.AUTH0,
  photoURL: '/assets/images/avatar/A11.jpg',
};

export const navStyles = [
  {
    id: 1,
    alias: NavStyle.STANDARD,
    image: '/assets/images/navigationStyle/nav4.png',
  },
  {
    id: 2,
    alias: NavStyle.DEFAULT,
    image: '/assets/images/navigationStyle/nav2.png',
  },
  {
    id: 3,
    alias: NavStyle.MINI_SIDEBAR_TOGGLE,
    image: '/assets/images/navigationStyle/nav3.png',
  },
  {
    id: 4,
    alias: NavStyle.HEADER_USER,
    image: '/assets/images/navigationStyle/nav9.png',
  },
  {
    id: 5,
    alias: NavStyle.HEADER_USER_MINI,
    image: '/assets/images/navigationStyle/nav10.png',
  },
  {
    id: 6,
    alias: NavStyle.MINI,
    image: '/assets/images/navigationStyle/nav3.png',
  },
  {
    id: 7,
    alias: NavStyle.DRAWER,
    image: '/assets/images/navigationStyle/nav5.png',
  },
  {
    id: 8,
    alias: NavStyle.BIT_BUCKET,
    image: '/assets/images/navigationStyle/nav1.png',
  },
  {
    id: 9,
    alias: NavStyle.H_DEFAULT,
    image: '/assets/images/navigationStyle/nav8.png',
  },
  {
    id: 10,
    alias: NavStyle.HOR_LIGHT_NAV,
    image: '/assets/images/navigationStyle/nav6.png',
  },
  {
    id: 11,
    alias: NavStyle.HOR_DARK_LAYOUT,
    image: '/assets/images/navigationStyle/nav7.png',
  },
];

export const layoutTypes = [
  {
    id: 1,
    alias: LayoutType.FULL_WIDTH,
    image: '/assets/images/layouts/full width.png',
  },
  {
    id: 2,
    alias: LayoutType.BOXED,
    image: '/assets/images/layouts/boxed.png',
  },
];

