import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { ITempleResponse } from 'types/api/temple';
import TempleAvatar from '../TempleAvatar';
import TempleStatusChip from '../TempleStatusChip';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CremaTheme } from 'types/crema/AppContextPropsType';

export const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        statusChip: {
            marginTop: '3px'
        }
    }),
);

interface TempleSearchItemProps {
    handleClick: (url: string) => void,
    temple: ITempleResponse
}
const TempleSearchItem: React.FC<TempleSearchItemProps> = ({ handleClick, temple }) => {
    const classes = useStyles()
    return (
        <Grid onClick={() => handleClick(temple.url)} item container spacing={2} direction="row" alignItems="center" style={{ cursor: 'pointer' }}>
            <Grid item>
                <TempleAvatar temple={temple} />
            </Grid>
            <Grid item>
                <Box fontSize=".8rem">
                    {temple.name} <br />
                    <TempleStatusChip temple={temple} className={classes.statusChip} />
                </Box>
            </Grid>
        </Grid>
    );
};



export default TempleSearchItem;