import React from 'react'
import Button from '@material-ui/core/Button'
import FormikTextField from 'components/Fields/FormikTextField'
import { Form, Formik, FormikHelpers } from 'formik'
import { IOfferingCategory } from 'types/store/temple'
import * as yup from 'yup'
import Alert from '@material-ui/lab/Alert'
import { extractError, isFieldError } from 'utils/api'
import { useIntl } from 'react-intl'
import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import { IApiState } from 'store/types'
import { apiStatus} from 'shared/constants/AppEnum'


type FormSubmitHandleType =  ((values: IOfferingCategory, formikHelpers: FormikHelpers<IOfferingCategory>) => void | Promise<any>) & Function

interface FormProps {
    buttonLabelId: string,
    initialValues: IOfferingCategory,
    successCb: (() => string) & Function
    onSubmitCb: FormSubmitHandleType
    api: IApiState
    isSuccess: boolean
}
const FormOfferingCategory: React.FC<FormProps> = (props) => {
    const {buttonLabelId, initialValues, successCb, onSubmitCb, api, isSuccess} = props
    const { messages } = useIntl();
    const validationSchema = yup.object({
        name: yup
            .string()
            .required('Name is required')
    })
    return (
        <>
            <Formik
                enableReinitialize
                validateOnChange={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmitCb}
            >
                {({ isSubmitting, dirty }) => (
                    <Form noValidate autoComplete='off'>
                        <Box mb={4}>
                            <FormikTextField
                                placeholder={messages['common.name']}
                                name='name'
                                label={<IntlMessages id='common.name' />}
                                variant='outlined'
                                fullWidth
                            />
                        </Box>
                        <Button disabled={!dirty || isSubmitting} variant="contained" color="primary" type="submit">
                            <IntlMessages id={buttonLabelId} />
                        </Button>
                    </Form>
                )}
            </Formik>
            <Box marginTop={4}>
                {isSuccess && <Alert severity="success">{successCb()}</Alert>}
                {api.status === apiStatus.FAILED && !isFieldError(api.error) && (
                    <Alert severity="error">{extractError(api.error)}</Alert>
                )}
            </Box>
        </>
    )
}

export default FormOfferingCategory