import React, { useState } from 'react'
import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import GoBack from 'components/GoBack'
import { RouteConfigComponentProps } from 'react-router-config'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import FitContentPaper from 'components/Paper/FitContentPaper'
import { FormikHelpers } from 'formik'
import { getFieldErrors } from 'utils/api'
import { IDonationFormType} from 'types/store/temple'
import { unwrapResult } from '@reduxjs/toolkit'
import { donationAddThunk, selectDonationStatus } from 'store/reducers/temple/donationSlice'
import { formToDonation } from 'utils/temple/donation'
import FormDonation from "./FormDonation"
import useRestricter from 'hooks/useRestricter'
import { PERMISSIONS, RESTRICTED_PATHS } from 'utils/permissions'


const AddDonation: React.FC<RouteConfigComponentProps> = (props) => {
    const api = useAppSelector(selectDonationStatus)
    const dispatch = useAppDispatch()
    const [alerts, setAlerts] = useState<TqAlert[]>([])
    const hasAddPermission = useRestricter(PERMISSIONS.ADD, RESTRICTED_PATHS.DONATION)
    
    const handleSubmit = (data: IDonationFormType, actions: FormikHelpers<IDonationFormType>) => {
        setAlerts([])
        dispatch(donationAddThunk(formToDonation(data)))
            .then(unwrapResult)
            .then(response => {
                setAlerts(a => [
                    ...a, 
                    {message:`Donation ${response.name} Successfully created`, severity: 'success'}
                ])
                actions.resetForm()
            })
            .catch(err => {
                Object.entries(getFieldErrors(data, err)).forEach(([key, value]) => {
                    setAlerts(a => [
                        ...a, 
                        {message:'Error adding Donation', severity: 'error'}
                    ])
                    // actions.setFieldError(key, value))
                    // console.log([key, value])
                })
            })
            .finally(() => {
                actions.setSubmitting(false)
            })
    }

    if(!hasAddPermission) return null

    return (
        <GoBack {...props}>
            <FitContentPaper>
                <Box component="h4" mb={4}><IntlMessages id="donation.create" /></Box>
                <FormDonation
                    buttonLabelId="common.create"
                    api={api}
                    onSubmitCb={handleSubmit}
                    alerts={alerts}
                />
            </FitContentPaper>
        </GoBack>
    )
}

export default AddDonation