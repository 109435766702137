import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { orderAPI } from "api/temple";
import { apiStatus } from "shared/constants/AppEnum";
import { AppState } from "store/reduxStore";
import { OrderResponseType } from "types/api/order";
import { IApiState } from "store/types";
import { OrderFilterArgsType } from "types/store/order";

type InitialStateType = {
    api: IApiState
    filterArgs: OrderFilterArgsType
}

const addOnInitialState : InitialStateType  = {
    api: {
        status: apiStatus.IDLE,
        error: null
    },
    filterArgs: {
        pageSize: 20,
        asc: false
    }
}

const orderAdapter = createEntityAdapter<OrderResponseType>()

export const orderListThunk = createAsyncThunk(
    'order/list',
    async(_,  {rejectWithValue}) => {
        try{
            const response = await orderAPI.list()
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

const orderSlice = createSlice({
    name: 'order',
    initialState: orderAdapter.getInitialState(addOnInitialState),
    reducers: {
        resetState(state) {
            state.api = {
                status: apiStatus.IDLE,
                error: null
            }
            orderAdapter.removeAll(state)
        }
    },
    extraReducers: builder => {
        builder.addCase(orderListThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(orderListThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            orderAdapter.upsertMany(state, action.payload)
        })
        builder.addCase(orderListThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
    }
})

export default orderSlice.reducer

export const { resetState } = orderSlice.actions

export const {
    selectAll: selectAllOrder,
    selectById: selectOrderById,
    selectIds: selectOrderIds,
} = orderAdapter.getSelectors((state: AppState) => state.order)