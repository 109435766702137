import { createStyles, lighten, makeStyles } from '@material-ui/core/styles';
import React, { MouseEventHandler } from 'react'
import { CremaTheme } from 'types/crema/AppContextPropsType';
import clsx from 'clsx'
import DeleteIcon from '@material-ui/icons/Delete';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import IntlMessages from '@crema/utility/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: CremaTheme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      borderBottom: "1px solid rgba(224, 224, 224, 1)"
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      fontSize: 16
    },
  }),
);

interface EnhancedTableToolbarProps {
  title: string | React.ReactNode
  numSelected?: number
  showFilter?: boolean
  addNew?: { text?: string | React.ReactNode, handle: MouseEventHandler<HTMLButtonElement> }
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useStyles();
  const { title, numSelected, addNew, showFilter } = props
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected && numSelected > 0,
      })}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        {numSelected && numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" component="div">
            {title}
          </Typography>
        )}
        <Box>
          {addNew && <Button onClick={addNew.handle} variant="contained" color="primary">
            {addNew.text ? addNew.text : <IntlMessages id="scrumboard.addNew" />}
          </Button>}
          {numSelected && numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            showFilter && <Tooltip title="Filter list">
              <IconButton aria-label="filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Grid>
    </Toolbar>
  );
};

export default EnhancedTableToolbar