import IntlMessages from '@crema/utility/IntlMessages'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { KeyboardDatePicker } from '@material-ui/pickers'
import RemoveIcon from '@material-ui/icons/Remove';
import React from 'react'
import Chip from '@material-ui/core/Chip'
import { useIntl } from 'react-intl'
import { format as dateFormat } from 'date-fns'
import { defaultDateFormat } from 'shared/constants/AppConst'
import { createStyles, makeStyles } from '@material-ui/core'
import { CremaTheme } from 'types/crema/AppContextPropsType'
import { IOfferingFormDatesType } from 'types/store/temple'
import { FieldArray, FormikErrors } from 'formik'
import FormBlock from '../../../components/Form/FormBlock'


const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        chipRoot: {
            margin: "4px 8px"
        }
    })
)

interface OfferingDatesProps {
    titleId: string,
    formState: IOfferingFormDatesType,
    dateContext: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    errors?: FormikErrors<IOfferingFormDatesType>
}

const FullDates : React.FC<OfferingDatesProps> = (props) => {
    const { titleId, dateContext, formState, setFieldValue, errors } = props
    const classes = useStyles()
    const { messages } = useIntl();
    const isDateExists = () => Boolean(
        formState.fieldDate && 
        formState.dates.find(d => (
            formState.fieldDate &&
            d.toDateString() === formState.fieldDate?.toDateString()
        ))
    )
    const addDateHandler = () => {
        if(!errors?.fieldDate && !isDateExists()){
            setFieldValue(`${dateContext}.dates`, [...formState.dates, formState.fieldDate])
            setFieldValue(`${dateContext}.fieldDate`, '')
        }
    }
    const isButtonDisabled = () => Boolean(
        errors?.fieldDate || !formState.fieldDate || isDateExists()
    )
    return (
        <FormBlock titleId={titleId}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={4}
            >

                <Grid item>
                    <KeyboardDatePicker
                        autoOk
                        size="small"
                        label={messages['offering.chooseDate']}
                        format={defaultDateFormat}
                        value={formState.fieldDate === '' ? null : formState.fieldDate}
                        placeholder={defaultDateFormat}
                        variant="inline"
                        inputVariant="outlined"
                        name={`${dateContext}.fieldDate`}
                        onChange={date => setFieldValue(`${dateContext}.fieldDate`, date ?? '')}
                    />
                </Grid>
                <Grid item>
                    <Button 
                        onClick={() => addDateHandler()} 
                        disabled={isButtonDisabled()}
                        variant="outlined" 
                        size="large"
                    >
                        <IntlMessages id="offering.addDate" />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <FieldArray
                        name={`${dateContext}.dates`}
                        render={arrayHelpers => (
                            formState.dates.map((date, index) => (
                                <Chip
                                    key={index}
                                    label={dateFormat(date, defaultDateFormat)}
                                    color="primary"
                                    onDelete={() => arrayHelpers.remove(index)}
                                    deleteIcon={<RemoveIcon />}
                                    variant="outlined"
                                    classes={{root: classes.chipRoot}}
                                />
                            ))
                        )}
                    />
                </Grid>
            </Grid>
        </FormBlock>
    )
}

export default React.memo(FullDates)