import React from 'react';
import Button from '@material-ui/core/Button';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import InfoView from '@crema/core/InfoView';
import Box from '@material-ui/core/Box';
import IntlMessages from '@crema/utility/IntlMessages';
import { useIntl } from 'react-intl';
// import clsx from 'clsx';
// import { Link, useHistory } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { loginThunk, selectAuth } from 'store/reducers/auth/authSlice'
import useStyles from 'assets/theme/containers/auth/login/jwt'
import FormikTextField from 'components/Fields/FormikTextField';
import { Alert } from '@material-ui/lab';
import { extractError } from 'utils/api';
import { apiStatus } from 'shared/constants/AppEnum';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Invalid Email')
    .required('Email required'),
  password: yup.string().required('Password required'),
});

interface UserSigninProps { }

const JwtSignIn: React.FC<UserSigninProps> = props => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const auth = useAppSelector(selectAuth)
  const { messages } = useIntl();
  const classes = useStyles(props);


  const onGoToForgetPassword = () => {
    history.push('/forget-password');
  };

  return (
    <Box flex={1} display='flex' flexDirection='column'>
      <Box
        px={{ xs: 6, sm: 10, xl: 15 }}
        pt={8}
        flex={1}
        display='flex'
        flexDirection='column'>
        <Formik
          validateOnChange={true}
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(data: ILoginRequest) => {
            dispatch(loginThunk(data))
              // .finally(() => setSubmitting(false))
          }}>
          {() => (
            <Form className={classes.formRoot} noValidate autoComplete='off'>
              <Box mb={{ xs: 5, xl: 8 }}>
                <FormikTextField
                  placeholder={messages['common.email']}
                  name='email'
                  label={<IntlMessages id='common.email' />}
                  variant='outlined'
                  fullWidth
                />
              </Box>

              <Box mb={{ xs: 3, lg: 4 }}>
                <FormikTextField
                  type='password'
                  placeholder={messages['common.password']}
                  label={<IntlMessages id='common.password' />}
                  name='password'
                  variant='outlined'
                  fullWidth
                />
              </Box>

              <Box
                mb={10}
                display='flex'
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ sm: 'center' }}
                justifyContent={{ sm: 'space-between' }}
                fontSize={15}>
                <Button
                  variant='contained'
                  color='secondary'
                  type='submit'
                  disabled={auth.status===apiStatus.LOADING}
                  className={classes.btnRoot}>
                  <IntlMessages id='common.login' />
                </Button>
              </Box>
              {auth.error && <Box mb={6}>
                <Alert severity="error">{extractError(auth.error)}</Alert>
              </Box>}
              <Box
                mb={6}
                display='flex'
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ sm: 'center' }}
                justifyContent={{ sm: 'space-between' }}>
                <Box
                  color='primary.main'
                  component='span'
                  className={classes.pointer}
                  onClick={onGoToForgetPassword}
                  fontSize={15}>
                  <IntlMessages id='common.forgetPassword' />
                </Box>
                {/* <Box
                  ml={{ xs: 0, sm: 4 }}
                  mt={{ xs: 3, sm: 0 }}
                  color='text.secondary'
                  fontSize={15}>
                  <Box className={classes.textGrey} component='span' mr={2}>
                    <IntlMessages id='common.dontHaveAccount' />
                  </Box>
                  <Box component='span'>
                    <Link
                      to='/signup'
                      className={clsx(
                        classes.underlineNone,
                        classes.colorTextPrimary,
                      )}>
                      <IntlMessages id='common.signup' />
                    </Link>
                  </Box>
                </Box> */}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>

      <InfoView />
    </Box>
  );
};

export default JwtSignIn;