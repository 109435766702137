import { BlockPeriods, OfferingTypes } from "shared/constants/AppEnum";
import { BlockCalcEnum, IOffering, IOfferingFormTimeType, IOfferingFormType, IOfferingTime, TAdditionalField, WeeklyDayType } from "types/store/temple";
import { format as dateFormat, parse as parseDate } from 'date-fns'
import { apiDateFormat, apiTimeFormat, defaultTimeFormat } from "shared/constants/AppConst";
import { genBooleanArray, weekDayWithFlag } from "utils/fns";
import { IDeityResponse, IOfferingResponse, IOfferingTimeResponse } from "types/api/temple";
import { FeeSplitConfigResponseType } from "api/report_app/report_app.response";
import { round } from "mathjs";
import { TOfferingCategoryResponseMapType } from "store/reducers/temple/offeringCategorySlice";

export const defaultNewField: TAdditionalField = {
    fieldName: '',
    fieldType: 'str',
    description: '',
    minLength: '',
    minValue: '',
    maxLength: '',
    maxValue: '',
    required: false,
    addToPrice: false,
    allowMultiplier: false,
    multiplierLabel: ''
}

export const getOfferingFormInitValues = (has_closing_time: boolean): IOfferingFormType =>  ({
    name: '' ,
    category: '',
    price: '',
    canChangePrice: false,
    offeringType: OfferingTypes.CUSTOM,
    blockPeriod: BlockPeriods.NA,
    perDayLimit: '',
    blockUnit: '',
    repeatUnit: '',
    description: '',
    isRecommended: false,
    isCourierAvailable: false,
    assignedDeities:[],
    isDakshinaRequired:false,
    minDakshinaAmount:'',
    useClosingTime: has_closing_time,
    availableOffline: true,
    availableOnline: true,
    additionalField: defaultNewField,
    offeringTimes: {
        currentEditing: {
            fromTime: '',
            toTime: '',
            blockCalcChooser: BlockCalcEnum.FROM,
            maxLimit: '',
            index: ''
        },
        times: []
    },
    offeringMeta: {
        customDates: {
            fieldDate: '',
            dates: []
        },
        monthDays: genBooleanArray(31),
        weekDays: weekDayWithFlag(),
        excludedDates: {
            fieldDate: '',
            dates: []
        },
    }
})

export const formToOfferingTime = (time: IOfferingFormTimeType) : IOfferingTime => {
    let result : IOfferingTime = {
        fromTime: dateFormat(time.fromTime.setSeconds(0, 0), apiTimeFormat),
        blockCalcChooser: time.blockCalcChooser,
        toTime: time.toTime ?  dateFormat(time.toTime.setSeconds(0, 0), apiTimeFormat) : undefined,
        maxLimit: time.maxLimit ? time.maxLimit : null
    }
    return result
}

export const offeringTimeToForm = (time: IOfferingTimeResponse) : IOfferingFormTimeType => {
    return {
        id: time.id,
        url: time.url,
        fromTime: parseDate(time.fromTime, apiTimeFormat, new Date()),
        toTime: time?.toTime ? parseDate(time.toTime, apiTimeFormat, new Date()) : '',
        maxLimit: time?.maxLimit ?? '',
        blockCalcChooser: time.blockCalcChooser
    }
}

export const getTimeChipText = (time: IOfferingFormTimeType) => {
    let times: string[] = [dateFormat(time.fromTime, defaultTimeFormat)]
    if (time?.toTime)
        times.push(dateFormat(time.toTime, defaultTimeFormat))
    return times.join(' - ')
}

export const formToOffering = (formState: IOfferingFormType) : IOffering => {
    let offering : IOffering = {
        name: formState.name,
        assignedDeities:formState.assignedDeities,
        price: +formState.price,
        canChangePrice: formState.canChangePrice,
        offeringType: formState.offeringType,
        category: formState.category,
        availableOnline: formState.availableOnline,
        availableOffline: formState.availableOffline,
        offeringTimes: [],
        offeringMeta: {},
        blockPeriod: formState.blockPeriod,
        isRecommended: formState.isRecommended,
        isCourierAvailable: formState.isCourierAvailable,
        useClosingTime: formState.useClosingTime && formState.useClosingTime === true ? true : false,
        perDayLimit: formState.perDayLimit ? formState.perDayLimit : null,
        blockUnit: formState.blockPeriod !== BlockPeriods.NA && formState.blockUnit ? formState.blockUnit : null,
        repeatUnit: formState.repeatUnit && formState.offeringType !== OfferingTypes.CUSTOM ? formState.repeatUnit : null,
        isDakshinaRequired:formState.isDakshinaRequired,
        minDakshinaAmount:formState.minDakshinaAmount ? formState.minDakshinaAmount:null

    }

    if(formState.offeringTimes.times.length > 0)
        offering.offeringTimes = (formState.offeringTimes.times.map(time => formToOfferingTime(time)))
    switch(formState.offeringType){
        case OfferingTypes.CUSTOM: {
            if(formState.offeringMeta.customDates.dates.length > 0)
                offering.offeringMeta = {
                    dates: formState.offeringMeta.customDates.dates.map(d => dateFormat(d, apiDateFormat))
                }
            break
        }
        // add case yearly
        case OfferingTypes.MONTHLY: {
            let monthDays = Object.entries(formState.offeringMeta.monthDays)
                .filter(([key, value]) => value)
                .map(([key, value]) => +key)
            if (monthDays.length > 0)
                offering.offeringMeta = {days: monthDays}
            break
        }
        case OfferingTypes.WEEKLY: {
            let weekDays = Object.entries(formState.offeringMeta.weekDays)
                .filter(([key, value]) => value)
                .map(([key, value]) => key as WeeklyDayType)
            if(weekDays.length > 0)
                offering.offeringMeta = {days: weekDays}
            break
        }
    }
    if (
        formState.offeringType !== OfferingTypes.CUSTOM && 
        formState.offeringMeta.excludedDates.dates.length > 0
    )
        offering.offeringMeta = {
            ...offering.offeringMeta,
            excludes: formState.offeringMeta.excludedDates.dates.map(d => dateFormat(d, apiDateFormat))
        }
    if(offering.offeringMeta && Object.keys(offering.offeringMeta).length === 0){
        delete offering.offeringMeta
    }
    return offering
}

export const offeringToForm = (data:{offering: IOfferingResponse, feeSplitConfig?: FeeSplitConfigResponseType,categoryMap:TOfferingCategoryResponseMapType}) : IOfferingFormType => {
   const {categoryMap,offering,feeSplitConfig} = data
   
    return {
        name: offering.name,
        category: categoryMap[offering?.category]?.url,
        price: offering.price,
        canChangePrice: offering.canChangePrice,
        offeringType: offering.offeringType,
        description: offering.description ?? '',
        isRecommended: offering.isRecommended ?? false,
        isCourierAvailable: offering.isCourierAvailable ?? false,
        blockPeriod: offering.blockPeriod ?? BlockPeriods.NA,
        perDayLimit: offering.perDayLimit ?? '',
        additionalField: defaultNewField,
        assignedDeities:offering.assignedDeities ?? [],
        blockUnit: offering.blockUnit ?? '',
        repeatUnit: offering.repeatUnit ?? '',
        isDakshinaRequired:offering.isDakshinaRequired ?? false,
        minDakshinaAmount:offering.minDakshinaAmount ?? '',
        useClosingTime: offering.useClosingTime,
        availableOnline: offering.availableOnline,
        availableOffline: offering.availableOffline,
        offeringTimes: {
            currentEditing: {
                fromTime: '',
                toTime: '',
                blockCalcChooser: BlockCalcEnum.FROM,
                maxLimit:'',
                index: ''
            },
            times: offering.offeringTimes && offering.offeringTimes.length > 0 ? 
                offering.offeringTimes.map(time => offeringTimeToForm(time)) : []
        },
        offeringMeta: {
            customDates: {
                fieldDate: '',
                dates: offering.offeringType === OfferingTypes.CUSTOM && 
                    offering?.offeringMeta?.dates &&
                    offering.offeringMeta.dates.length > 0 ?
                    offering.offeringMeta.dates.map(date => parseDate(date, apiDateFormat, new Date())) : []
            },
            monthDays: offering.offeringType === OfferingTypes.MONTHLY &&
                offering?.offeringMeta?.days &&
                offering.offeringMeta.days.length > 0 ?
                genBooleanArray(31, false, offering.offeringMeta.days) : genBooleanArray(31),
            weekDays: offering.offeringType === OfferingTypes.WEEKLY &&
                offering?.offeringMeta?.days &&
                offering.offeringMeta.days.length > 0 ?
                weekDayWithFlag(offering.offeringMeta.days.map(d => d as WeeklyDayType)) :
                weekDayWithFlag(),
            excludedDates: {
                fieldDate: '',
                dates: offering.offeringType !== OfferingTypes.CUSTOM &&
                    offering?.offeringMeta?.excludes &&
                    offering.offeringMeta.excludes.length > 0 ?
                    offering.offeringMeta.excludes.map(d => parseDate(d, apiDateFormat, new Date()))
                        .sort((a,b) => +a-+b) : []
            },
        },
        splitEntries: feeSplitConfig !== undefined ? feeSplitConfig.entries.map(
            val => ({
                name: val.name,
                percent: val.percent,
                value: round((offering.price * val.percent) / 100, 2)
            })) : undefined
    }

}


export function offeringNot(a: IOfferingResponse[], b: IOfferingResponse[]) {
    return a.filter(obj1 => !b.some(obj2 =>  obj1.id === obj2.id))
}

export function offeringIntersect(a: IOfferingResponse[], b: IOfferingResponse[]) {
    return a.filter(obj1 => b.some(obj2 =>  obj1.id === obj2.id))
}

export function offeringUnion(a: IOfferingResponse[], b: IOfferingResponse[]) {
    return [...a, ...offeringNot(b, a)];
}



export const fmtBlockPeriod = (blockPeriod: BlockPeriods, blockUnit: number) => {
    let s: string 
    switch(blockPeriod){
        case BlockPeriods.YEAR: {
            s = `${blockUnit} Year`
            break
        }
        case BlockPeriods.MONTH: {
            s = `${blockUnit} Month`
            break
        }
        case BlockPeriods.WEEK: {
            s = `${blockUnit} Week`
            break
        }
        case BlockPeriods.DAY: {
            s = `${blockUnit} Day`
            break
        }
        case BlockPeriods.HOUR: {
            s = `${blockUnit} Hour`
            break
        }
        default: {
            s = 'N/a'
        }
    }
    if (blockPeriod !== BlockPeriods.NA && blockUnit > 1) s = `${s}s`
    return s
}