import { apiInstance, deleteWithUrl } from "api/client";
import { FeeSplitConfigResponseType, FeeSplitNameResponseType } from "./report_app.response";
import { FeeSplitConfigRequestType, FeeSplitNameRequestType } from "./report_app.request";
import snakeCaseKeys from 'snakecase-keys'
import { ContentObjectBaseType } from "./report_app.base";

const basePath = 'report-app'

export const feeSplitNameAPI = {
    ...deleteWithUrl,
    list: async(params: ContentObjectBaseType) => 
        await apiInstance.get<FeeSplitNameResponseType[]>(`${basePath}/fee-split-name/`, {params: snakeCaseKeys(params)}),
    get: async(url: string) => 
        await apiInstance.get<FeeSplitNameResponseType>(url),
    post: async(data: FeeSplitNameRequestType) =>
        await apiInstance.post<FeeSplitNameResponseType>(`${basePath}/fee-split-name/`, data),
    update: async(url: string, data: Partial<FeeSplitNameRequestType>) =>
        await apiInstance.patch<FeeSplitNameResponseType>(url, data)
}


export const feeSplitConfigAPI = {
    ...deleteWithUrl,
    list: async(params: ContentObjectBaseType) => 
        await apiInstance.get<FeeSplitConfigResponseType[]>(`${basePath}/fee-split-config/`, {params: snakeCaseKeys(params)}),
    get: async(url: string) => 
        await apiInstance.get<FeeSplitConfigResponseType>(url),
    post: async(data: FeeSplitConfigRequestType) =>
        await apiInstance.post<FeeSplitConfigResponseType>(`${basePath}/fee-split-config/`, data),
    update: async(url: string, data: Partial<FeeSplitConfigRequestType>) =>
        await apiInstance.patch<FeeSplitConfigResponseType>(url, data)
}