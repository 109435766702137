import Scrollbar from '@crema/core/Scrollbar';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import React from 'react';
import { apiStatus } from 'shared/constants/AppEnum';
import { IApiState } from 'store/types';
import { useStyles } from '../common/style'
import { CommonTableOrder } from '../common/types';

interface NormalTableProps {
  toolbar?: React.ReactElement
  tableHead?: React.ReactElement
  api?: IApiState
  action?: React.ReactElement
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<T extends {}>(
  order: CommonTableOrder,
  orderBy: keyof T,
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const NormalTable: React.FC<NormalTableProps> = props => {
  const {
    toolbar,
    tableHead,
    api,
    action,
    children
  } = props
  const classes = useStyles()


  const buildFooter = () => (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        {action}
      </Grid>
    </Grid>
  )
  return (
    <React.Fragment>
      {toolbar !== undefined && toolbar}
      <TableContainer>
        <Scrollbar>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            {tableHead !== undefined && tableHead}
            <TableBody>
              {children}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
      {api && api.status === apiStatus.LOADING && <LinearProgress />}
      {action && buildFooter()}
    </React.Fragment>
  );
};

export default NormalTable;