import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CremaTheme } from 'types/crema/AppContextPropsType';

export const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        na: {
            backgroundColor: theme.palette.gray[400]
        },
        active: {
            backgroundColor: theme.palette.success.main
        },
        inactive: {
            backgroundColor: theme.palette.error.main
        }
    }),
);