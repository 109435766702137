import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormikTextField from 'components/Fields/FormikTextField';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup'

export type FormValuesType = AddEmptyStringType<Omit<CreateUserRequestType, 'deviceId'>> & {
    confirmPassword: string
}

interface CreateUserFormProps {
    handleSubmit: (values: FormValuesType, helpers: FormikHelpers<FormValuesType>) => void
}

const CreateUserForm : React.FC<CreateUserFormProps> = ({handleSubmit}) => {
    const { messages } = useIntl()
    
    const validationSchema = yup.object({
        firstName: yup.string().required(),
        middleName: yup.string(),
        lastName: yup.string().required(),
        email: yup.string().email().required(),
        password: yup.string().required(),
        confirmPassword: yup.string().required()
            .oneOf([yup.ref('password'), ''], 'Passwords must match')
    })

    const initialValues : FormValuesType = {
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    }
    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}

        >
            {({isSubmitting, isValid, dirty}) => (
                <Form noValidate autoComplete='off'>
                    <FormikTextField
                        name='firstName'
                        variant='outlined'
                        required
                        placeholder={messages['user.firstName']}
                        label={messages['user.firstName']}
                        size="small"
                        fullWidth
                    />
                    <FormikTextField
                        name='middleName'
                        variant='outlined'
                        placeholder={messages['user.middleName']}
                        label={messages['user.middleName']}
                        size="small"
                        fullWidth
                    />
                    <FormikTextField
                        name='lastName'
                        variant='outlined'
                        required
                        placeholder={messages['user.lastName']}
                        label={messages['user.lastName']}
                        size="small"
                        fullWidth
                    />
                    <FormikTextField
                        name='email'
                        variant='outlined'
                        required
                        placeholder={messages['common.email']}
                        label={messages['common.email']}
                        size="small"
                        fullWidth
                    />
                    <FormikTextField
                        name='password'
                        variant='outlined'
                        required
                        placeholder={messages['common.password']}
                        label={messages['common.password']}
                        size="small"
                        fullWidth
                        type="password"
                    />
                    <FormikTextField
                        name='confirmPassword'
                        variant='outlined'
                        required
                        placeholder={messages['common.confirmPassword']}
                        label={messages['common.confirmPassword']}
                        size="small"
                        fullWidth
                        type="password"
                    />
                    <Box my={3} display="flex" justifyContent="flex-end">
                        <Button 
                            style={{float: 'right'}} 
                            disabled={isSubmitting || !isValid || !dirty} 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            size="small"
                        >
                            {messages['common.create']}
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>

    );
};

export default CreateUserForm;