import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import axios, { AxiosResponse } from 'axios'
import { templeAPI } from 'api/admin';
import Box from '@material-ui/core/Box';
import { ITempleResponse } from 'types/api/temple';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useInView } from 'react-intersection-observer';
import { extractHeaderLink } from 'utils/api';
import { useEffect } from 'react';
import { useCallback } from 'react';
import TempleSearchItem from 'components/Temple/TempleSearchItem';

interface TempleSearchProps {
    handleTempleOpen : (url: string) => void
}

const TempleSearch: React.FC<TempleSearchProps> = ({handleTempleOpen}) => {
    const { messages } = useIntl()
    const [temples, setTemples] = useState<ITempleResponse[]>([])
    const [loading, setLoading] = useState(false)
    const [nextUrl, setNextUrl] = useState('')
    const [cancelToken, setCancelToken] = useState(axios.CancelToken.source())
    const [query, setQuery] = useState('')
    const { ref, inView } = useInView({threshold: 1.0});

    const handleResponse = useCallback((response: AxiosResponse<ITempleResponse[]>, reset=false) => {
        let hasNext = false
        reset ?
            setTemples(response.data) :
            setTemples(t => [...t, ...response.data])
        if ('link' in response.headers){
            let links = extractHeaderLink(response.headers['link'])
            if ('next' in links) {
                hasNext = true
                setNextUrl(links['next'])
            }
        }
        if (!hasNext){
            setNextUrl('')
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if(inView && nextUrl){
            let tokenSource = axios.CancelToken.source()
            setCancelToken(tokenSource)
            templeAPI.search({url: nextUrl}, tokenSource.token)
                .then(response => handleResponse(response))
                .catch(err => err)
        }
            
    }, [inView, nextUrl, handleResponse])

    const handleChange = (query: string) => {
        setQuery(query)
        if (cancelToken) {
            cancelToken.cancel('search query modified')
        }
        if (query) {
            setLoading(true)
            let tokenSource = axios.CancelToken.source()
            setCancelToken(tokenSource)
            templeAPI.search({query: query}, tokenSource.token)
                .then(response => handleResponse(response, true))
                .catch(err => err)
        } else {
            setTemples([])
            setLoading(false)
        }
    }
    return (
        <Box mb={4}>
            <TextField
                variant='filled'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
                label={messages['sidebar.search']}
                placeholder={messages['sidebar.search.temple'].toString()}
                onChange={e => handleChange(e.target.value)}
                fullWidth
            />
            {(temples.length > 0 || loading) && query && (
                <Paper style={{padding: '5px'}}>
                    <Grid container direction="column" spacing={2}>
                        {temples.map(temple => (
                            <TempleSearchItem key={temple.id} temple={temple} handleClick={handleTempleOpen} />
                        ))}
                    </Grid>
                    {loading &&  (
                        <div ref={ref} style={{display: 'flex', justifyContent: 'center', margin: '5px'}}>
                            <CircularProgress  style={{width: '35px', height: '35px'}}/>
                        </div>
                    )}
                </Paper>
            )}
        </Box>
    );
};


export default TempleSearch;