import React from 'react'
import { CremaTheme } from 'types/crema/AppContextPropsType'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Paper, {PaperProps} from '@material-ui/core/Paper'

const useStyles = makeStyles((theme: CremaTheme) =>
createStyles({
    paper: {
        padding: "3vh 2vw",
        width: "fit-content",
        // minWidth: "30vw",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: "3vh 5vw"
        },
    }
})
)

const FitContentPaper : React.FC<PaperProps> = (props) => {
    const classes = useStyles()
    return <Paper {...{...props, className: classes.paper}} />
}

export default FitContentPaper