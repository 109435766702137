import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import React from 'react'
import { RouteConfigComponentProps } from 'react-router-config'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const GoBack: React.FC<RouteConfigComponentProps> = ({ history, children }) => {
    const handleGoBack = () => history.goBack()
    return (
        <>
            <Box mb={5} width="100%">
                <Button startIcon={<ArrowBackIosIcon />} disableElevation onClick={handleGoBack}>
                    <IntlMessages id="common.back" />
                </Button>
            </Box>
            {children}
        </>
    )
}

export default GoBack