import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import React from 'react'
import FormBlock from '../../../components/Form/FormBlock'

interface MonthDaysProps{
    formState: { [key: string]: boolean; },
    formContext: string,
    // handleChange : ((day: number|string) => void | Promise<any>) & Function
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
}

const MonthDays : React.FC<MonthDaysProps> = (props) => {
    const {formContext, formState, setFieldValue} = props

    const handleLocalChange = (day: string|number) => {
        setFieldValue(formContext, {...formState, [day]: !formState[day]})
    }

    return (
        <FormBlock titleId="offering.monthlyOffering.action.title">
            <FormGroup row>
                {Object.entries(formState).map(([key, value]) => (
                    <FormControlLabel
                        style={{ minWidth: "60px" }}
                        key={key}
                        control={
                            <Checkbox
                                checked={value}
                                onChange={() => handleLocalChange(key)}
                                name={key}
                                color="primary"
                            />
                        }
                        label={key}
                    />
                ))}
            </FormGroup>
        </FormBlock>
    )
}

export default React.memo(MonthDays)