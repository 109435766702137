import React from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';


// const useTableStyles = makeStyles((theme: CremaTheme) =>
//     createStyles({
//         cellRoot:{
//             [theme.breakpoints.up('md')]: {
//                 padding: "6px 16px"
//             },
//         }
//     })
// )

const DonationCategory : React.FC<RouteConfigComponentProps> = ({route}) => renderRoutes(route?.routes)

export default DonationCategory;
