import React, { ReactNode, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';
import qs from 'qs';
import AppContext from './AppContext';
import { useAuthToken } from './AppHooks';
import { Loader } from '../index';
import { checkPermission } from './Utils';
import { initialUrls } from 'shared/constants/AppConst';
import AppContextPropsType from '../../types/crema/AppContextPropsType';
import { NavStyle, ThemeMode, ThemeStyle } from '../../shared/crema/constants/AppEnums';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selfThunk } from 'store/reducers/temple/usersSlice';
import { selectCurrentTemple } from 'store/reducers/temple/templeSlice';
import useRestricter from 'hooks/useRestricter';
import { selectIsAuthenticated } from 'store/reducers/auth/authSlice';

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const {
    routes,
    changeNavStyle,
    updateThemeStyle,
    updateThemeMode,
  } = useContext<AppContextPropsType>(AppContext);
  const [loading, user] = useAuthToken();
  const currentRoute = matchRoutes(routes, pathname)[0].route;
  let isPermitted = checkPermission(currentRoute.auth, user ? user.role : null);
  const dispatch = useAppDispatch()
  const temple = useAppSelector(selectCurrentTemple)
  const hasPermission = useRestricter()
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  useEffect(() => {
    if (user && temple && isAuthenticated) dispatch(selfThunk(temple.id))
  }, [user, temple , isAuthenticated])

  useEffect(() => {
    function handleQueryParams() {
      const query = qs.parse(search.split('?')[1]);
      if (query.layout) {
        changeNavStyle(query.layout as NavStyle);
      }
      if (query.mode) {
        updateThemeMode(query.mode as ThemeMode);
      }
      if (query.style) {
        updateThemeStyle!(query.style as ThemeStyle);
      }
    }

    if (search) {
      handleQueryParams();
    }
  }, [changeNavStyle, updateThemeMode, updateThemeStyle, search]);

  useEffect(() => {
    if (!loading) {
      if (!user && !isPermitted) {
        history.push('/login'); // allowed route
      } else if (user && !isPermitted) {
        history.push('/404'); // Not found
      } else if (user && isPermitted && (pathname === '/' || pathname === '/login')) {
        history.push(initialUrls[user.role!]);
      }
    }
  }, [user, loading, isPermitted, pathname, history]);

  return (loading || !hasPermission) ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;
