import { Avatar, Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ImageSizes } from 'shared/constants/AppEnum';
import { ITempleResponse } from 'types/api/temple'
import { CremaTheme } from 'types/crema/AppContextPropsType';
import { getImage } from 'utils/fns';
import clsx from 'clsx'

const useStyles = makeStyles((theme: CremaTheme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightRegular,
      width: 'max-content'
    },
    avatar: {
        marginRight: '5px'
    },
  }),
);


interface TempleInfoProps {
    temple: ITempleResponse
}

const TempleInfo : React.FC<TempleInfoProps> = ({temple}) => {
    const classes = useStyles()
    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <Avatar 
                className={classes.avatar} 
                src={temple.displayImg ? getImage(temple.displayImg, ImageSizes.SMALL) : ''}
            >{temple.name.charAt(0)}</Avatar>
        <Typography className={clsx(classes.heading, 'temple-select-temple-name')}>{temple.name}</Typography>
    </Box>
    )
}

export default TempleInfo