

const getDayStartEnd = (date: Date) => {
    const start = new Date(date.valueOf())
    start.setHours(0, 0, 0, 0);
    const end = new Date(date.valueOf())
    end.setHours(23, 59, 59, 999);
    return [start, end]
}


const getMonthStartEnd = (year: number, month: number) => {
    const lastDay = new Date(year, month + 1, 0);
    lastDay.setHours(23, 59, 59 , 999)
    return [ new Date(year, month, 1), lastDay]
}

export default  {getDayStartEnd, getMonthStartEnd} as const