import { BlockPeriods, DonationTypes} from "shared/constants/AppEnum";
import { IDonation, IDonationFormType, WeeklyDayType } from "types/store/temple";
import { format as dateFormat, parse as parseDate } from 'date-fns'
import { apiDateFormat} from "shared/constants/AppConst";
import { genBooleanArray, weekDayWithFlag } from "utils/fns";
import { IDonationResponse } from "types/api/temple";

export const donationFormInitValues : IDonationFormType = {
    name: '' ,
    category: '',
    amount: '',
    minAmount:'',
    maxAmount:'',
    donationType:DonationTypes.DAILY,
    blockPeriod: BlockPeriods.NA,
    perDayLimit: '',
    blockUnit: '',
    repeatUnit: '',
    description: '',
    isDateRequired:false,
    isAmountFixed:false,
    donationMeta: {
        customDates: {
            fieldDate: '',
            dates: []
        },
        monthDays: genBooleanArray(31),
        weekDays: weekDayWithFlag(),
        excludedDates: {
            fieldDate: '',
            dates: []
        },
    }
}


export const formToDonation = (formState: IDonationFormType) : IDonation => {
    let donation : IDonation = {
        name: formState.name,
        amount: formState.amount ? +formState.amount : null,
        minAmount: formState.minAmount ? +formState.minAmount : null,
        maxAmount: formState.maxAmount ? +formState.maxAmount : null,
        isDateRequired: formState.isDateRequired,
        isAmountFixed: formState.isAmountFixed,
        donationType: formState.isDateRequired ? formState.donationType : DonationTypes.DAILY,
        category: formState.category,
        donationMeta: {},
        blockPeriod: formState.blockPeriod,
    }
    donation.description = formState.description ?? null
    if (formState.perDayLimit) 
        donation.perDayLimit = formState.perDayLimit
    if(formState.blockPeriod !== BlockPeriods.NA && formState.blockUnit){
        donation.blockUnit = formState.blockUnit
    }
    if(formState.repeatUnit && formState.donationType !== DonationTypes.CUSTOM) 
        donation.repeatUnit = formState.repeatUnit
    switch(formState.donationType){
        case DonationTypes.CUSTOM: {
            if(formState.donationMeta.customDates.dates.length > 0)
                donation.donationMeta = {
                    dates: formState.donationMeta.customDates.dates.map(d => dateFormat(d, apiDateFormat))
                }
            break
        }
        // add case yearly
        case DonationTypes.MONTHLY: {
            let monthDays = Object.entries(formState.donationMeta.monthDays)
                .filter(([key, value]) => value)
                .map(([key, value]) => +key)
            if (monthDays.length > 0)
                donation.donationMeta = {days: monthDays}
            break
        }
        case DonationTypes.WEEKLY: {
            let weekDays = Object.entries(formState.donationMeta.weekDays)
                .filter(([key, value]) => value)
                .map(([key, value]) => key as WeeklyDayType)
            if(weekDays.length > 0)
                donation.donationMeta = {days: weekDays}
            break
        }
    }
    if (
        formState.donationType !== DonationTypes.CUSTOM && 
        formState.donationMeta.excludedDates.dates.length > 0
    )
        donation.donationMeta = {
            ...donation.donationMeta,
            excludes: formState.donationMeta.excludedDates.dates.map(d => dateFormat(d, apiDateFormat))
        }
    if(donation.donationMeta && Object.keys(donation.donationMeta).length === 0){
        delete donation.donationMeta
    }
    return donation
}

export const donationToForm = (donation: IDonationResponse) : IDonationFormType => {
    return {
        name: donation.name,
        category: donation.category, 
        amount: donation.amount ?? '',
        isDateRequired:donation.isDateRequired,
        isAmountFixed:donation.isAmountFixed,
        minAmount:donation.minAmount ?? '',
        maxAmount:donation.maxAmount ?? '',
        donationType: donation.donationType,
        description: donation.description ?? '',
        blockPeriod: donation.blockPeriod ?? BlockPeriods.NA,
        perDayLimit: donation.perDayLimit ?? '',
        blockUnit: donation.blockUnit ?? '',
        repeatUnit: donation.repeatUnit ?? '',
        donationMeta: {
            customDates: {
                fieldDate: '',
                dates: donation.donationType === DonationTypes.CUSTOM && 
                    donation?.donationMeta?.dates &&
                    donation.donationMeta.dates.length > 0 ?
                    donation.donationMeta.dates.map(date => parseDate(date, apiDateFormat, new Date())) : []
            },
            monthDays: donation.donationType === DonationTypes.MONTHLY &&
                donation?.donationMeta?.days &&
                donation.donationMeta.days.length > 0 ?
                genBooleanArray(31, false, donation.donationMeta.days) : genBooleanArray(31),
            weekDays: donation.donationType === DonationTypes.WEEKLY &&
                donation?.donationMeta?.days &&
                donation.donationMeta.days.length > 0 ?
                weekDayWithFlag(donation.donationMeta.days.map(d => d as WeeklyDayType)) :
                weekDayWithFlag(),
            excludedDates: {
                fieldDate: '',
                dates: donation.donationType !== DonationTypes.CUSTOM &&
                    donation?.donationMeta?.excludes &&
                    donation.donationMeta.excludes.length > 0 ?
                    donation.donationMeta.excludes.map(d => parseDate(d, apiDateFormat, new Date()))
                        .sort((a,b) => +a-+b) : []
            },
        }
    }
}



export function offeringNot(a: IDonationResponse[], b: IDonationResponse[]) {
    return a.filter(obj1 => !b.some(obj2 =>  obj1.id === obj2.id))
}

export function offeringIntersect(a: IDonationResponse[], b: IDonationResponse[]) {
    return a.filter(obj1 => b.some(obj2 =>  obj1.id === obj2.id))
}

export function offeringUnion(a: IDonationResponse[], b: IDonationResponse[]) {
    return [...a, ...offeringNot(b, a)];
}

export const fmtBlockPeriod = (blockPeriod: BlockPeriods, blockUnit: number) => {
    let s: string 
    switch(blockPeriod){
        case BlockPeriods.YEAR: {
            s = `${blockUnit} Year`
            break
        }
        case BlockPeriods.MONTH: {
            s = `${blockUnit} Month`
            break
        }
        case BlockPeriods.WEEK: {
            s = `${blockUnit} Week`
            break
        }
        case BlockPeriods.DAY: {
            s = `${blockUnit} Day`
            break
        }
        case BlockPeriods.HOUR: {
            s = `${blockUnit} Hour`
            break
        }
        default: {
            s = 'N/a'
        }
    }
    if (blockPeriod !== BlockPeriods.NA && blockUnit > 1) s = `${s}s`
    return s
}