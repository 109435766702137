import {useEffect, useState} from 'react';
import {selectUser, selectIsAuthenticated, userThunk} from 'store/reducers/auth/authSlice'
import {fetchError, fetchStart, fetchSuccess} from 'store/reducers/common/commonSlice';
import { useAppSelector, useAppDispatch } from 'store/hooks'

export const useAuthToken = (): [boolean, UserResponseType | null] => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const user = useAppSelector(selectUser) ?? null
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  useEffect(() => {
    const validateAuth = async () => {
      dispatch(fetchStart());
      if (!isAuthenticated) {
        dispatch(fetchSuccess());
        return;
      }
      await dispatch(userThunk())
        .then(() => dispatch(fetchSuccess()))
        .catch(err => dispatch(fetchError(err)))
    };

    const checkAuth = () => {
      Promise.all([validateAuth()]).then(() => {
        setLoading(false);
      });
    };
    checkAuth();
  }, [dispatch, isAuthenticated]);

  return [loading, user];
};

export const useAuthUser = (): UserResponseType | null => {
  const user = useAppSelector(selectUser)
  if (user) {
    return user;
  }
  return null;
};
