import { currentTempleKey } from "shared/constants/AppConst";
import { AddressResponseType } from "types/api/temple";
import { getLocalStorageItem, setLocalStorageItem } from "utils/fns";

export const getLocalCurrentTemple = () => getLocalStorageItem(currentTempleKey) ?? ''
export const setLocalCurrentTemple = (id: string | number) => setLocalStorageItem(currentTempleKey, id)
export const removeLocalCurrentTemple = () => localStorage.removeItem(currentTempleKey)

export const templeAddressToStrFn = (address: AddressResponseType) => {
    const addressArray = 
        [address.address_line_1, address.address_line_2, address.city, address.district, address.state, address.pincode]
            .filter(val => val)
    return addressArray.join(', ')
}