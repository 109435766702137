
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CremaTheme } from 'types/crema/AppContextPropsType';

export const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        media: {
            height: '200px'
        },
        button: {
            margin: theme.spacing(1),
        },
        approveButton: {
            borderColor: theme.palette.success.main,
            color: theme.palette.success.main
        },
        disapproveButton: {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main
        },
        deleteButton: {
            background: theme.palette.error.main,
            color: theme.palette.error.contrastText
        }
    }),
);