import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { apiStatus } from 'shared/constants/AppEnum'
import { AppState } from 'store/reduxStore'
import { IApiState } from 'store/types'
import { StaffTempleAdminCreateRequest, StaffTempleAdminParams, StaffTempleAdminResponse } from 'api/admin/types'
import { adminTempleAdminApi } from 'api/admin'

const staffTempleAdminAdapter = createEntityAdapter<StaffTempleAdminResponse>()

type InitialStateType = {
    api: IApiState
}

const addOnIntitalState: InitialStateType = {
    api: {
        status: apiStatus.IDLE,
        error: null
    }
}

export const staffTempleAdminListThunk = createAsyncThunk(
    'staffTempleAdmin/list',
    async (payload: StaffTempleAdminParams, { rejectWithValue, }) => {
        try {
            const response = await adminTempleAdminApi.list(payload)
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const staffTempleAdminCreateThunk = createAsyncThunk(
    'staffTempleAdmin/create',
    async (payload: StaffTempleAdminCreateRequest, { rejectWithValue }) => {
        try {
            const response = await adminTempleAdminApi.create(payload)
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const staffTempleAdminUpdateThunk = createAsyncThunk(
    'staffTempleAdmin/update',
    async (payload: PayloadWithUrl<Partial<StaffTempleAdminCreateRequest>>, { rejectWithValue }) => {
        try {
            const response = await adminTempleAdminApi.update(payload.url, payload.data)
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const staffTempleAdminRetrieveThunk = createAsyncThunk(
    'staffTempleAdmin/retrieve',
    async (payload: UrlType, { rejectWithValue, getState }) => {
        try {
            const response = await adminTempleAdminApi.get(payload.url)
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const staffTempleAdminDeleteThunk = createAsyncThunk(
    'staffTempleAdmin/delete',
    async (payload: IdAndUrlType) => {
        await adminTempleAdminApi.delete(payload.url)
        return payload.id
    }
)


const staffTempleAdminSlice = createSlice({
    name: 'staffTempleAdmin',
    initialState: staffTempleAdminAdapter.getInitialState(addOnIntitalState),
    reducers: {
        resetState(state) {
            state.api = {
                status: apiStatus.IDLE,
                error: null
            }
            staffTempleAdminAdapter.removeAll(state)
        }
    },
    extraReducers: builder => {
        builder.addCase(staffTempleAdminCreateThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            staffTempleAdminAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(staffTempleAdminUpdateThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            staffTempleAdminAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(staffTempleAdminListThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            staffTempleAdminAdapter.upsertMany(state, action.payload)
        })
        builder.addCase(staffTempleAdminRetrieveThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            staffTempleAdminAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(staffTempleAdminDeleteThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            staffTempleAdminAdapter.removeOne(state, action.payload)
        })
        builder.addMatcher(
            isAnyOf(
                staffTempleAdminCreateThunk.pending,
                staffTempleAdminUpdateThunk.pending,
                staffTempleAdminListThunk.pending,
                staffTempleAdminRetrieveThunk.pending,
                staffTempleAdminDeleteThunk.pending,
            ), (state, action) => {
                state.api = {
                    status: apiStatus.LOADING,
                    error: null
                }
            }
        )
        builder.addMatcher(
            isAnyOf(
                staffTempleAdminCreateThunk.rejected,
                staffTempleAdminUpdateThunk.rejected,
                staffTempleAdminListThunk.rejected,
                staffTempleAdminRetrieveThunk.rejected,
                staffTempleAdminDeleteThunk.rejected,
            ), (state, action) => {
                state.api = {
                    status: apiStatus.FAILED,
                    error: action.payload
                }
            }
        )
    }
})

export default staffTempleAdminSlice.reducer

export const { resetState } = staffTempleAdminSlice.actions

export const {
    selectAll: selectAllStaffTempleAdmins,
    selectById: selectStaffTempleAdminById,
    selectIds: selectStaffTempleAdminIds,
} = staffTempleAdminAdapter.getSelectors((state: AppState) => state.staffTempleAdmin)

export const selectStaffTempleAdminStatus = (state: AppState): IApiState => state.offeringCategory.api

export const selectStaffTempleAdminsByTempleId = createSelector(
    (state: AppState) => state,
    (_: any, templeId: string | number) => templeId,
    (state, templeId) => {
        if (!templeId) return
        return selectAllStaffTempleAdmins(state).filter(admin => admin.temple == templeId)
    }
)

