import {v4 as uuidv4} from 'uuid'
import { tokenKey, deviceIdKey, userRoleKey } from 'shared/constants/AppConst'
import { getLocalStorageItem, setLocalStorageItem } from 'utils/fns'
import { TJWTResponse, UserRoleType } from 'store/reducers/auth/authSlice'

export const setJWT = (jwt: TJWTResponse | null) => setLocalStorageItem(tokenKey ,JSON.stringify(jwt))

export const getJWT = (): TJWTResponse | null => {
    try {
        return JSON.parse(getLocalStorageItem(tokenKey) ?? "")
    } catch(err) {
        return null
    }
}

export const getDeviceId = () => {
    let deviceId = getLocalStorageItem(deviceIdKey)
    if (!deviceId){
        deviceId = uuidv4()
        setLocalStorageItem(deviceIdKey, deviceId)
    }
    return deviceId
}

export const getLocalUserRole = () => getLocalStorageItem(userRoleKey)
export const setLocalUserRole = (role: UserRoleType) => setLocalStorageItem(userRoleKey, role)