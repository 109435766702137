import React from 'react';
import { useField } from 'formik'
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';

interface FormikFileFieldProps{
    label: string
    name: string,
    accept?: string[],
    variant?: "text" | "outlined" | "contained"
    size?: "small" | "medium" | "large"
    color?: "inherit" | "primary" | "secondary" | "default"
    margin?: "dense" | "none" | "normal"
}

const FormikFileField : React.FC<FormikFileFieldProps> = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const errorText = meta.error && meta.touched ? meta.error : ''
    return (
        <FormControl margin={props.margin ?? "normal"}>
            <Button
                variant={props.variant ?? 'outlined'}
                size={props.size ?? 'small'}
                color={props.color ?? 'primary'}
                component="label"
            >
                {field.value ? field.value.name : props.label}
                <input
                    type="file"
                    name={field.name}
                    onChange={e => {
                        if (!meta.touched) helpers.setTouched(true)
                        if (e.target.files?.length){
                            helpers.setValue(e.target.files[0])
                        }
                    }}
                    hidden
                    {...(props.accept ? {accept: props.accept.join(',')} : {})}
                />
            </Button>
            <FormHelperText error={!!errorText}>{errorText}</FormHelperText>
        </FormControl>
    );
};

export default React.memo(FormikFileField)