import { AxiosError, AxiosResponse } from "axios"
import { arrayToString, getArrayDiff, getObjectArrayDiff, isArrayOfArray, isObjectArray } from "./fns"
import _ from 'lodash'

export const extractError = (err: any): string => {
    if (typeof err === 'string') return err
    if (typeof err === 'object') {
        if ('detail' in err) return err.detail
        if ('nonFieldErrors' in err) return err.nonFieldErrors.join(', ')
    }
    return 'Unknown Error'
}

export const isFieldError = (err: any): boolean =>
    typeof err === 'object' && !('detail' in err || 'nonFieldErrors' in err)


export const getFieldErrors = (data: any, error: any, parent?: string): { [key: string]: string } => {
    let errors: { [key: string]: string } = {}
    if (typeof data === 'object' && typeof error === 'object') {
        let dataKeys = Object.keys(data)
        Object.entries(error).forEach(entry => {
            const [key, value] = entry;
            if (typeof value === 'object' && !Array.isArray(value))
                errors = { ...errors, ...getFieldErrors(data[key], value, parent ? `${parent}.${key}` : key) }
            else if (dataKeys.indexOf(key) !== -1)
                errors[parent ? `${parent}.${key}` : key] = arrayToString(value)
        })
    }
    return errors
}

type StringIndexed = { [key: string]: any }

export const getEditedPayload = <T extends StringIndexed>(
    initialValues: T, data: T, ignoreKeys: string[] = []
): Partial<T> => {
    if (!initialValues) return data
    let result: StringIndexed = {}
    Object.entries(data).forEach(([key, value]) => {
        if (ignoreKeys.indexOf(key) !== -1)
            return
        if (value == null) {
            if (value !== initialValues[key])
                result[key] = value
        }
        else if (value || value === 0 || value === '' || typeof value === 'boolean') {
            if (Array.isArray(value) && !isArrayOfArray(value)) {
                if (isObjectArray(value)) {
                    if (initialValues[key]) {
                        let objArrayDiff = getObjectArrayDiff(value, initialValues[key])
                        if (objArrayDiff.length > 0)
                            result[key] = objArrayDiff
                    }
                    else result[key] = value
                } else {
                    if (initialValues[key]) {
                        let arrayDiff = getArrayDiff(value, initialValues[key])
                        if (arrayDiff.length > 0)
                            result[key] = arrayDiff
                    } else result[key] = value
                }
            } else if (value instanceof File) {
                result[key] = value
            } else if (typeof value === 'object') {
                let obj = getEditedPayload(initialValues[key], value)
                if (Object.keys(obj).length > 0)
                    result[key] = obj
            } else if ((typeof value === "string" || typeof value === "number" || typeof value === 'boolean') &&
                initialValues[key] !== value) {
                result[key] = value
            }
        }
    })
    return result as Partial<T>
}

const linkHeaderRegex = /<([^>]+)>;\s+rel="([^"]+)"/

export const extractHeaderLink = (linkHeader: string) => {
    let result: { [key: string]: string } = {}
    linkHeader.split(',')
        .forEach(link => {
            let match = link.match(linkHeaderRegex)
            if (match)
                result[match[2]] = match[1]
        })
    return result
}

export const extractAxiosHeaderLink = <T>(response: AxiosResponse<T>, linkType: 'next' | 'prev' | 'current'): string => {
    if (response.headers['link']) {
        let links = extractHeaderLink(response.headers['link'])
        if (linkType in links) return links[linkType]
    }
    return ''
}

export const objectToFormData = <T>(data: T, convert: boolean = true) => {
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
        if (convert) {
            key = _.snakeCase(key)
        }
        if (typeof value === 'string' || value instanceof File) {
            formData.append(key, value)
        }
        else if (typeof value === 'number') {
            formData.append(key, value.toString())
        }
    })
    return formData
}

export const getAxiosError = (error: AxiosError): string => {
    if (error.response) {
        const err = error.response.data ?? ""
        if(typeof err === 'string') return err
        if(typeof err === 'object') return Object.keys(err).map(k=> `${k}: ${err[k]}`).join(', ')
        
        return typeof err
    } else if (error.request) {
        return error.request
    } else {
        return error.message
    }
}