import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { receiptAPI } from "api/temple";
import { apiStatus } from "shared/constants/AppEnum";
import { AppState } from "store/reduxStore";
import { IApiState } from "store/types";
import { ReceiptResponseType } from "types/api/receipt";
import { extractAxiosHeaderLink } from "utils/api";
import { ReceiptFilterArgsType, ReceiptRequestTypes } from "./types";

const receiptAdapter = createEntityAdapter<ReceiptResponseType>()

type ReceiptListArgsType = {
    filter: ReceiptFilterArgsType
    pagination: PaginationType
}

type InitialStateType = {
    api: IApiState
    listArgs: ReceiptListArgsType
    // completionListArgs: ReceiptListArgsType
}


const addOnInitialState: InitialStateType = {
    api: {
        status: apiStatus.IDLE,
        error: null
    },
    listArgs: {
        filter: {
            pageSize: 25,
            asc: false,
            orderBy: 'id',
            status: 'paid'
        },
        pagination: {},
    },
    // completionListArgs: {
    //     filter: {
    //         pageSize: 50,
    //         asc: false,
    //         orderBy: 'id',
    //         status: 'paid',
    //         completionStatus: 'pending',
    //     },
    //     pagination: {},
    // }
}

export const receiptDownloadPdfThunk = createAsyncThunk(
    'receipt/download/pdf',
    async (requestType: ReceiptRequestTypes, { rejectWithValue, getState }) => {
        let state = (getState() as AppState).receipt
        try {
            const response = requestType === 'pdf' ?
                await receiptAPI.generatePDFDownloadUrl({...state.listArgs.filter}) :
                await receiptAPI.generatePriestDownloadUrl({...state.listArgs.filter})
            window.open(response.data.downloadUrl, '_self')
            return
        }
        catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const receiptListThunk = createAsyncThunk(
    'receipt/list',
    async (_, { rejectWithValue, getState }) => {
        const args : ReceiptListArgsType = (getState() as AppState).receipt.listArgs
        try {
            const response = args.pagination.next ?
                await receiptAPI.listWithUrl(args.pagination.next) :
                await receiptAPI.list({...args.filter})
            return {
                data: response.data,
                pagination: { next: extractAxiosHeaderLink(response, 'next') },
                reset: args.pagination.next ? false : true
            }
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

// export const receiptCompletionListThunk = createAsyncThunk(
//     'receipt/list/complete',
//     async (_, { rejectWithValue, getState }) => {
//         const args  = (getState() as AppState).receipt.completionListArgs
//         try {
//             const response = args.pagination.next ?
//                 await receiptAPI.listWithUrl(args.pagination.next) :
//                 await receiptAPI.list(args.filter)
//             return {
//                 data: response.data,
//                 pagination: { next: extractAxiosHeaderLink(response, 'next') },
//                 reset: args.pagination.next ? false : true
//             }
//         } catch (err: any) {
//             return rejectWithValue(err.response.data)
//         }
//     }
// )

export const receiptRetrieveThunk = createAsyncThunk(
    'receipt/retrieve',
    async (payload: IdOrUrlType, { rejectWithValue }) => {
        try {
            let params
            if ('id' in payload && payload.id)
                params = { itemId: payload.id }
            else if ('url' in payload)
                params = { url: payload.url }
            else
                return rejectWithValue({ detail: 'Identifier not provided' })
            const response = await receiptAPI.get(params)
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const markAsCompleteThunk = createAsyncThunk(
    'bookings/update',
    async (ids: (string|number)[], { rejectWithValue, getState }) => {
        const markAsCompleteArgs: MarkAsCompleteArgsType = {ids: ids}        
        try {
            const response = await receiptAPI.markAsComplete(markAsCompleteArgs)
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

const receiptSlice = createSlice({
    name: 'receipt',
    initialState: receiptAdapter.getInitialState(addOnInitialState),
    reducers: {
        resetState(state) {
            state.api = {
                status: apiStatus.IDLE,
                error: null
            }
            receiptAdapter.removeAll(state)
            state.listArgs = {
                filter: {
                    status: 'paid',
                    pageSize: 25,
                    asc: false,
                    orderBy: 'id'
                },
                pagination: {}
            }
            // state.completionListArgs = {
            //     filter: {
            //         pageSize: 50,
            //         asc: false,
            //         orderBy: 'id',
            //         status: 'paid',
            //         completionStatus: 'pending'
            //     },
            //     pagination: {},
            // }
        },
        setFilters(state, action: PayloadAction<Partial<ReceiptFilterArgsType>>) {
            state.listArgs = {
                filter: {
                    pageSize: state.listArgs.filter.pageSize,
                    asc: state.listArgs.filter.asc,
                    status: state.listArgs.filter.status,
                    orderBy: state.listArgs.filter.orderBy,
                    ...action.payload
                },
                pagination: {}
            }
        },
        // setCompletionFilters(state, action) {
        //     state.completionListArgs = {
        //         filter: {
        //             pageSize: state.completionListArgs.filter.pageSize,
        //             asc: state.completionListArgs.filter.asc,
        //             status: state.completionListArgs.filter.status,
        //             completionStatus: state.completionListArgs.filter.completionStatus,
        //             ...action.payload
        //         },
        //         pagination: {}
        //     }
        //     receiptAdapter.removeAll(state)
        // },
    },
    extraReducers: builder => {
        builder.addCase(receiptListThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            state.listArgs.pagination = action.payload.pagination
            if (action.payload.reset)
                receiptAdapter.removeAll(state)
            receiptAdapter.upsertMany(state, action.payload.data)
        })
        // builder.addCase(receiptCompletionListThunk.fulfilled, (state, action) => {
        //     state.api = {
        //         status: apiStatus.SUCCEEDED,
        //         error: null
        //     }
        //     state.completionListArgs.pagination = action.payload.pagination
        //     if (action.payload.reset)
        //         receiptAdapter.removeAll(state)
        //     receiptAdapter.upsertMany(state, action.payload.data)
        // })
        builder.addCase(receiptDownloadPdfThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
        })
        builder.addCase(receiptRetrieveThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            receiptAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(markAsCompleteThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            receiptAdapter.upsertMany(state, action.payload)
        })
        builder.addMatcher(
            isAnyOf(
                receiptListThunk.pending,
                // receiptCompletionListThunk.pending,
                receiptDownloadPdfThunk.pending,
                receiptRetrieveThunk.pending,
                markAsCompleteThunk.pending
            ),
            (state, action) => {
                state.api = {
                    status: apiStatus.LOADING,
                    error: null
                }
            })
        builder.addMatcher(
            isAnyOf(
                receiptListThunk.rejected,
                // receiptCompletionListThunk.rejected,
                receiptDownloadPdfThunk.rejected,
                receiptRetrieveThunk.rejected,
                markAsCompleteThunk.rejected
            ), (state, action) => {
                state.api = {
                    status: apiStatus.FAILED,
                    error: action.payload
                }
            }
        )
    }
})

export default receiptSlice.reducer

export const { resetState, setFilters } = receiptSlice.actions

export const {
    selectAll: selectAllReceipt,
    selectById: selectReceiptById,
    selectIds: selectReceiptIds,
} = receiptAdapter.getSelectors((state: AppState) => state.receipt)

export const selectFilterArgs = (state: AppState) => state.receipt.listArgs.filter

export const selectPagination = (state: AppState) => state.receipt.listArgs.pagination

// export const selectCompletionFilterArgs = (state: AppState) => state.receipt.completionListArgs.filter

// export const selectCompletionPagination = (state: AppState) => state.receipt.completionListArgs.pagination

export const selectReceiptStatus = (state: AppState) => state.receipt.api

export const selectIncompleteReceipts = createSelector(
    (state: AppState) => selectAllReceipt(state),
    (receipts:  ReceiptResponseType[]) => receipts.filter(receipt => receipt.status === 'pending')
)

