import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => (
    {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        },
        box: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
            border: '1px solid #f28500',
            borderRadius: '8px',
            padding: '20px',
            maxWidth: '400px',
            backgroundColor: 'rgba(242, 133, 0, 0.05)'
        },
        text: {
            fontSize: '18px',
            textAlign: 'center'
        }
    }
));

export default useStyles