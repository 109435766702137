import { createStyles, makeStyles } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import React, { useState, useRef } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectCurrentTemple, setTempleThunk, selectTempleListApi, selectAllAdminTemples } from 'store/reducers/temple/templeSlice'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CremaTheme } from 'types/crema/AppContextPropsType';
import TempleInfo from './TempleInfo';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom'
import ContentLoader from 'react-content-loader'
import { apiStatus } from 'shared/constants/AppEnum'

const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        root: {
            width: '100%',
            boxShadow: 'none'
        },
        summaryContent: {
            margin: "12px 0 !important"
        },
        selectedTemple: {
            background: theme.palette.gray[200],
        },
        templeCells: {
            borderRadius: "1.5rem",
            "&:hover": {
                background: theme.palette.gray[100]
            }
        }
    }),
);

const TempleSelect: React.FC = (props) => {
    const rootRef = useRef<HTMLDivElement>(null)
    const dispatch = useAppDispatch()
    const history = useHistory()
    const temples = useAppSelector(selectAllAdminTemples)
    const listApi = useAppSelector(selectTempleListApi)
    const currentTemple = useAppSelector(selectCurrentTemple)
    const classes = useStyles()
    const [expanded, setExpanded] = useState(false)
    const handleExpandedChange = () => {
        setExpanded(!expanded)
    }
    const handleTempleChange = async(id: string | number) => {
        await dispatch(setTempleThunk({ id: id }))
        rootRef?.current?.scrollIntoView()
        setExpanded(false)
        history.push('/')
    }
    return (
        currentTemple ? (
            <Accordion
                expanded={expanded}
                classes={{
                    root: classes.root
                }}
                onChange={handleExpandedChange}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{ 
                        content: classes.summaryContent,
                        expandIcon: 'temple-select-expanded-icon'
                    }}
                    ref={rootRef}
                >
                    <TempleInfo temple={currentTemple} />
                </AccordionSummary>
                <AccordionDetails>
                    <Box width="100%" flexDirection="column"  className="temple-select-list">
                        {temples && temples.map(temple => (
                            <Box
                                className={
                                    clsx(classes.templeCells, currentTemple && currentTemple.id === temple.id ? classes.selectedTemple : '')
                                }
                                key={temple.id}
                                mt={1} mb={1} padding={1} ml={-1} mr={-1}
                                style={{
                                    cursor: "pointer"
                                }}
                                onClick={() => handleTempleChange(temple.id)}
                            >
                                <TempleInfo temple={temple} />
                            </Box>
                        ))}
                    </Box>
                </AccordionDetails>
            </Accordion >
        ) : (
            listApi.status === apiStatus.LOADING ? (
                <ContentLoader viewBox="0 0 250 50" height={80} width={250}>
                    <rect x="65" y="20" rx="5" ry="5" width="150" height="10" />
                    <circle cx="25" cy="25" r="25" />
                </ContentLoader>
            ) : <></>
        )
    )
}

export default TempleSelect