import React, { useState } from 'react'
import OfferingTransferList from './OfferingTransferList'
import * as yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'
import { deityFormInitValues } from 'utils/temple/deity'
import { IOfferingResponse } from 'types/api/temple'
import { IDeityType } from 'types/store/temple'
import FormikTextField from 'components/Fields/FormikTextField'
import { useIntl } from 'react-intl'
import IntlMessages from '@crema/utility/IntlMessages'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import { extractError, isFieldError } from 'utils/api'
import { IApiState } from 'store/types'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { CremaTheme } from 'types/crema/AppContextPropsType';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { getImage } from 'utils/fns';
import { apiStatus, ImageSizes } from 'shared/constants/AppEnum';
import clsx from 'clsx'
import { ITempleResponse } from 'types/api/temple';
import { allowedImgTypes, maxFileSize } from 'shared/constants/AppConst';
import { DropzoneDialog } from 'material-ui-dropzone';
import { TempleImgType } from 'types/store/temple';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CircularProgress, Typography } from '@material-ui/core';
import { selectImgUploadApi, uploadImgThunk } from 'store/reducers/temple/deitySlice'
import { selectCurrentTemple } from 'store/reducers/temple/templeSlice'
import _ from 'lodash'

type FormSubmitHandleType =
    ((values: IDeityType, formikHelpers: FormikHelpers<IDeityType>) => void | Promise<any>) & Function

interface FormDeityProps {
    initialValues?: IDeityType & IdType
    buttonLabelId: string
    offerings: IOfferingResponse[]
    handleAddOfferings: ((offerings: IOfferingResponse[]) => void | Promise<any>) & Function
    handleRemoveOfferings: ((offerings: IOfferingResponse[]) => void | Promise<any>) & Function
    onSubmitCb: FormSubmitHandleType
    api: IApiState
    alerts: TqAlert[]
}

const useStyles = makeStyles((theme: CremaTheme) => (
    createStyles({
        bgImg: {
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: theme.palette.gray[50],
            position: 'relative'
        },
        imgsContainer: {
            position: 'relative',
            textAlign: 'center',
            marginTop: "6px",
        },
        displayImg: {
            width: '20vh',
            height: '20vh',
            fontSize: '.8rem',
            borderRadius: '.5rem',
            border: '2px solid #fff',
            overflow: 'hidden',
        },
        addNewText: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'gray'
        },
        changeContainer: {
            width: '100%',
            height: '100%',
            padding: 10,
            background: 'rgba(0,0,0,0.5)',
            color: theme.palette.primary.contrastText,
        },
        cvrChangeContainer: {
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            padding: 20
        },
        displayChangeContainer: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        loader: {
            background: 'rgba(0,0,0,0.5)',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0
        },
        circularProgess: {
            color: "#fff"
        },
        flex: {
            display: 'flex',
        },
        hidden: {
            display: 'none'
        },
    })
))


const FormDeity: React.FC<FormDeityProps> = props => {
    const {
        initialValues,
        offerings,
        handleAddOfferings,
        handleRemoveOfferings,
        buttonLabelId,
        alerts,
        onSubmitCb,
        api,
    } = props
    const { messages } = useIntl()
    const classes = useStyles()
    const [displayCls, setDisplayCls] = useState(classes.hidden)
    const [dialog, setDialog] = useState(false)
    const [imgType, setImgType] = useState<TempleImgType|''>('')
    const uploadApi = useAppSelector(selectImgUploadApi)
    const temple = useAppSelector(selectCurrentTemple)

    const dispatch = useAppDispatch()
    const openDialog = (type: TempleImgType) => {
        setDialog(true)
        setImgType(type)
    }
    const closeDialog = () => {
        setDialog(false)
        setImgType('')
    }
    const saveDialog = (files: File[]) => {
        if(initialValues && temple?.id && files.length === 1 ){
            dispatch(uploadImgThunk({
                id: temple?.id,
                url: initialValues.url,
                file: files[0],
            }))
        }
        setDialog(false)
    }

    const validationSchema = yup.object({
        name: yup
            .string()
            .required('Name is required'),
        isMain: yup
            .boolean()
            .required('Required'),
    })
    return (
        <Formik
            enableReinitialize
            validateOnChange={true}
            initialValues={initialValues ?? deityFormInitValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitCb}
        >
            {({ isSubmitting, dirty, values, isValid, setFieldValue, errors }) => (
                <Form noValidate autoComplete='off'>
                    <Grid container spacing={6} direction="column">
                        <Grid item container spacing={6}>
                        <Grid item direction='column' container spacing={6}  xs={12} md={8}>
                            <Grid item>
                                <FormikTextField
                                    placeholder={messages['common.name']}
                                    name='name'
                                    label={<IntlMessages id='common.name' />}
                                    variant='outlined'
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend"><IntlMessages id="deity.main.title" /></FormLabel>
                                    <RadioGroup 
                                        row
                                        aria-label="main prathistta" 
                                        name="isMain" 
                                        value={values.isMain ? "yes": "no"} 
                                        onChange={event => setFieldValue(event.target.name, event.target.value === 'yes'? true : false)}
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    {initialValues && (
                        <Grid item xs={12} md={4}>
                            <FormLabel>Deity Image</FormLabel>
                            <Box className={classes.imgsContainer}>
                                <Box
                                    className={clsx(classes.bgImg, classes.displayImg)}
                                    style={{
                                        backgroundImage: initialValues?.deityImg ? `url(${getImage(initialValues.deityImg, ImageSizes.MEDIUM)})` : '',
                                        border: initialValues?.deityImg ? 'none' : '1px dashed lightgray'
                                    }}
                                    onMouseEnter={() => setDisplayCls(classes.flex)}
                                    onMouseLeave={() => setDisplayCls(classes.hidden)}
                                >
                                    {!initialValues?.deityImg && (
                                        <Typography className={classes.addNewText} variant='body1'>
                                            Upload a Diety Image
                                            </Typography>
                                    )}
                                    <Box 
                                        onClick={() => openDialog('display')}
                                        className={clsx(
                                            classes.changeContainer, 
                                            classes.displayChangeContainer, 
                                            uploadApi.status === apiStatus.LOADING ? classes.hidden : displayCls
                                        )}
                                    >
                                        <Box component="span">
                                            <IntlMessages id="display.change" />
                                        </Box>
                                    </Box>
                                    <Box className={clsx(classes.loader, uploadApi.status !== apiStatus.LOADING ? classes.hidden : '')}>
                                        <CircularProgress size="2em" classes={{colorPrimary: classes.circularProgess}}/>
                                    </Box>
                                </Box>
                            </Box>
                            <DropzoneDialog
                                open={dialog}
                                onSave={saveDialog}
                                acceptedFiles={allowedImgTypes}
                                showPreviews={false}
                                maxFileSize={maxFileSize}
                                onClose={() => closeDialog()}
                                filesLimit={1}
                                showPreviewsInDropzone={true}
                                // useChipsForPreview={true}
                            />
                        </Grid>
                    )}
                        </Grid>
                        <Grid item>
                            <Box mb={4} component="h5">
                                <IntlMessages id="deity.offerings.assign.title" />
                            </Box>
                            <OfferingTransferList
                                chosenOfferings={offerings}
                                handleAddOfferings={handleAddOfferings}
                                handleRemoveOfferings={handleRemoveOfferings}
                            />
                        </Grid>
                        <Grid item>
                            <Button disabled={(!dirty || isSubmitting || !isValid) &&  _.isEqual(offerings.map(o => o.url), initialValues ? initialValues?.offerings : [])} variant="contained" color="primary" type="submit">
                                <IntlMessages id={buttonLabelId} />
                            </Button>
                        </Grid>
                        <Grid item container spacing={4} direction="column" alignItems="flex-start">
                            {alerts.map((alert, index) => (
                                <Grid item  key={index}>
                                    <Alert severity={alert.severity}>{alert.message}</Alert>
                                </Grid>
                            ))}
                            {api.status === apiStatus.FAILED && !isFieldError(api.error) && (
                                <Grid item>
                                    <Alert severity="error">{extractError(api.error)}</Alert>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>

    )
}

export default React.memo(FormDeity)