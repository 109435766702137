import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useState } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import BankAccountApprovalTable from '../BankAccountApprovalTable';
import CreateUser from '../CreateUser';
import TempleApprovalTable from '../TempleApprovalTable';
import TempleDialogWrapper from '../TempleDialogWrapper';
import TempleSearch from '../TempleSearch';

const Dashboard: React.FC<RouteConfigComponentProps> = props => {
    const [templeDialog, setTempleDialog] = useState<{ open: boolean, url: string }>({ open: false, url: ''})
    const handleTempleOpen = (url: string) => setTempleDialog({url: url, open: true})
    const handleTempleClose = () => setTempleDialog({ open: false, url: ''})
    return (
        <TempleDialogWrapper {...templeDialog} handleClose={handleTempleClose}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TempleSearch handleTempleOpen={handleTempleOpen} />
                        </Grid>
                        <Grid item xs={12}>
                            <CreateUser/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TempleApprovalTable handleTempleOpen={handleTempleOpen} />
                        </Grid>
                        <Grid item xs={12}>
                            <BankAccountApprovalTable handleTempleOpen={handleTempleOpen} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TempleDialogWrapper>
    );
};

export default Dashboard;