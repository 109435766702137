import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { CremaTheme } from 'types/crema/AppContextPropsType';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectCurrentTemple, selectTempleReadApi, selectTempleUpdateApi, templeRetrieveThunk } from 'store/reducers/temple/templeSlice';
import { apiStatus } from 'shared/constants/AppEnum';
import Error404 from 'components/ErrorPage/Error404';
import Loader from '@crema/core/Loader';
import TempleImg from './TempleImg';
import TempleForm from './TempleForm';
import BankAccount from './BankAccount';
import TempleLocation from './TempleLocation';
import { PERMISSIONS, RESTRICTED_PATHS } from 'utils/permissions';
import { Backdrop, Tooltip, Typography } from '@material-ui/core';
import usePermissionCheck from 'hooks/usePermissionCheck';
import LogoUpload from './LogoUpload';

const useStyles = makeStyles((theme: CremaTheme) => (
    createStyles({
        paper: {
            width: "100%",
            overflow: 'hidden'
        },
    })
))

const TempleProfile = () => {
    const classes = useStyles()
    const temple = useAppSelector(selectCurrentTemple)
    const readApi = useAppSelector(selectTempleReadApi)
    const updateApi = useAppSelector(selectTempleUpdateApi)
    const dispatch = useAppDispatch()
    const hasEditPermission = usePermissionCheck(RESTRICTED_PATHS.TEMPLE, PERMISSIONS.CHANGE)
    const [tooltip, setTooltip] = useState<{ x: number, y: number, inside: boolean }>({ x: 0, y: 0, inside: false })

    useEffect(() => {
        dispatch(templeRetrieveThunk())
    }, [dispatch])

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => setTooltip(prev => ({ ...prev, x: e.clientX, y: e.clientY }))

        window.addEventListener('mousemove', handleMouseMove)
        return () => window.removeEventListener('mousemove', handleMouseMove)
    }, [])

    return (
        <>
            {readApi.status === apiStatus.LOADING ?
                <Loader /> :
                temple ? (
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={7}>
                            <Paper className={classes.paper}>
                                <TempleImg temple={temple} />
                                <Box m={8}>
                                    <TempleForm temple={temple} api={updateApi} />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <LogoUpload temple={temple} />
                            <BankAccount temple={temple} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <Paper className={classes.paper}>
                                <TempleLocation temple={temple} />
                            </Paper>
                        </Grid>
                    </Grid>
                ) : <Error404 />}

            <Backdrop open={!hasEditPermission} onMouseOver={() => setTooltip(prev => ({ ...prev, inside: true }))} onMouseLeave={() => setTooltip(prev => ({ ...prev, inside: false }))} style={{ position: 'fixed', zIndex: 999, height: '100%', backgroundColor: " rgba(0, 0, 0, 0.2)", cursor: 'not-allowed' }} />
            {tooltip.inside && (
                <Box bgcolor="rgba(0,0,0,0.5)" style={{ position: 'fixed', zIndex: 2000, top: tooltip.y, left: tooltip.x, color: '#fafafa', padding: "2px 6px", borderRadius: "4px" }}>
                    <Typography variant='body2'>You don't have enough permissions to modify this section</Typography>
                </Box>
            )}
        </>
    );
};

export default TempleProfile;
