import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    page: {
        display: 'flex',
        height: '70vh',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    container: {
        width: '100%',
        maxWidth: '400px',
        backgroundColor: 'white',
        padding: '24px'
    },
    actions: {
        display: "flex",
        justifyContent: 'end',
        gap: '12px'
    },
    title: {
        fontWeight: 600,
        marginBottom: "4px"
    },
    body: {
        marginBottom: "12px"
    },
    formRoot: {
        textAlign: 'left',
        [theme.breakpoints.up('xl')]: {
            marginBottom: 24,
        },
    },
}));