import { ReceiptFilterFormType } from "types/store/receipt";
import { format as dateFormat} from 'date-fns'
import { apiDateFormat, apiTimeFormat } from "shared/constants/AppConst";
import { apiDateToDate, apiTimeToDate } from "utils/fns";
import { ReceiptFilterArgsType } from "store/reducers/temple/receiptSlice/types";

export const filterInitValues: ReceiptFilterFormType = {
  fromDate: '',
  toDate: '',
  fromBookingDate: '',
  toBookingDate: '',
  deity: '',
  category: '',
  offering: '',
  // time: ''
  fromTime: '',
  toTime: ''
}

export const filterArgsToForm = (filterArgs: ReceiptFilterArgsType) : ReceiptFilterFormType => {
  return {
    fromDate: filterArgs.dateAfter ? apiDateToDate(filterArgs.dateAfter) : '',
    toDate: filterArgs.dateBefore ? apiDateToDate(filterArgs.dateBefore) : '',
    fromBookingDate: filterArgs.bookingDateAfter ? apiDateToDate(filterArgs.bookingDateAfter) : '',
    toBookingDate: filterArgs.bookingDateBefore ? apiDateToDate(filterArgs.bookingDateBefore) : '',
    offering:  filterArgs.offering ?? '',
    category: filterArgs.category ?? '',
    deity: filterArgs.deity ?? '',
    // time: string|number
    fromTime: filterArgs.fromTime ? apiTimeToDate(filterArgs.fromTime) : '',
    toTime: filterArgs.toTime ? apiTimeToDate(filterArgs.toTime) : ''
  }
}

export const formToFilterArgs = (formState: ReceiptFilterFormType) : Partial<ReceiptFilterArgsType> => {
  let filterArgs : Partial<ReceiptFilterArgsType> = {}
  if (formState.fromDate)
    filterArgs.dateAfter = dateFormat(formState.fromDate, apiDateFormat)
  if (formState.toDate)
    filterArgs.dateBefore= dateFormat(formState.toDate, apiDateFormat)
  if (formState.fromBookingDate){
    formState.fromBookingDate.setHours(0, 0, 0, 0)
    filterArgs.bookingDateAfter = formState.fromBookingDate.toISOString()
  }
  if (formState.toBookingDate){
    formState.toBookingDate.setHours(23, 59, 59, 999)
    filterArgs.bookingDateBefore = formState.toBookingDate.toISOString()
  }
  if (formState.deity)
    filterArgs.deity = formState.deity
  if (formState.category)
    filterArgs.category = formState.category
  if (formState.offering)
    filterArgs.offering = formState.offering
  if (formState.fromTime)
    filterArgs.fromTime = dateFormat(formState.fromTime.setSeconds(0, 0), apiTimeFormat)
  if (formState.toTime)
    filterArgs.toTime = dateFormat(formState.toTime.setSeconds(0,0), apiTimeFormat)
  return filterArgs
}