import React from "react";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// core components
import useStyles from 'assets/theme/components/footer/authFooter'

const AuthFooter = () => {
  const classes = useStyles();
  return (
    <Box mt={10} component="footer" width="100%" paddingTop="1rem" paddingLeft="20px" paddingRight="20px">
      <Box
        maxWidth="xl"
        display="flex!important"
        alignItems="center"
        className={classes.justifyContentCenter + " " + classes.flexDirectionColumn}
      >
        <Grid item xs={12} xl={6}>
          <div className={classes.copyrightWrapper}>
            © {new Date().getFullYear()}{" "}
            <a
              className={classes.copyrightLink}
              href="https://templeclick.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              TempleClick
            </a>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          xl={6}
          component={Box}
          display="flex"
          justifyContent="flex-end"
          classes={{
            root:
              classes.justifyContentCenter + " " + classes.flexDirectionColumn,
          }}
        >
          <Box
            component={List}
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={classes.justifyContentCenter + " " + classes.flexDirectionColumn}
          >

            <ListItem
              component="a"
              href="#"
              rel="noopener noreferrer"
              target="_blank"
              classes={{
                root: classes.listItemRoot,
              }}
            >
              Contact Us
            </ListItem>

            <ListItem
              component="a"
              href="#"
              rel="noopener noreferrer"
              target="_blank"
              classes={{
                root: classes.listItemRoot,
              }}
            >
              Privacy Policy
            </ListItem>

            <ListItem
              component="a"
              href="#"
              rel="noopener noreferrer"
              target="_blank"
              classes={{
                root: classes.listItemRoot,
              }}
            >
              Terms and Conditions
            </ListItem>


          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default AuthFooter;
