import { Loader } from '@crema'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import { FormikHelpers } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { apiStatus } from 'shared/constants/AppEnum'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { BankAccountType, ITempleResponse } from 'types/api/temple'
import { CremaTheme } from 'types/crema/AppContextPropsType'
import { getFieldErrors } from 'utils/api'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BankAccountForm from 'components/Temple/BankAccountForm'
import { readBankAccount, selectBankAccountReadApi, selectBankAccountUpdateApi, selectCurrentTempleBankAccount, updateBankAccount } from 'store/reducers/temple/bankAccountSlice'

interface BankAccountProps {
    temple: ITempleResponse
}

const useStyles = makeStyles((theme: CremaTheme) => (
    createStyles({
        loader: {
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            backgroundColor: '#fff',
            zIndex: 999
        },
        container: {
            position: 'relative',
            overflow: 'hidden',
            margin: '0 1rem 1rem 1rem'
        },
    })
))

const BankAccount: React.FC<BankAccountProps> = ({ temple }) => {
    const { messages } = useIntl()
    const dispatch = useAppDispatch()
    const readApi = useAppSelector(selectBankAccountReadApi)
    const updateApi = useAppSelector(selectBankAccountUpdateApi)
    const bankAccount = useAppSelector(selectCurrentTempleBankAccount)
    const classes = useStyles()
    const [initialFetch, setInitialFetch] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const { enqueueSnackbar } = useSnackbar()    

    const handleAccordion = () => {
        setExpanded(!expanded)
        if(!initialFetch){
            dispatch(readBankAccount({ url: temple.url, id: temple.id }))
            setInitialFetch(true)
        }
    }

    const handleSubmit = (values: Partial<BankAccountType>, actions: FormikHelpers<Partial<BankAccountType>>) => {
        dispatch(updateBankAccount({ id: temple.id, url: temple.url, data: values }))
            .then(unwrapResult)
            .then(() => enqueueSnackbar('Bank Account Updated', { variant: 'success' }))
            .catch(err => {
                Object.entries(getFieldErrors(values, err)).forEach(([key, value]) =>
                    actions.setFieldError(key, value))
            })
            .finally(() => actions.setSubmitting(false))
    }

    return (
        <Accordion expanded={expanded} onChange={() => handleAccordion()}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Grid container spacing={2} direction="row">
                    <Grid item><AccountBalanceIcon fontSize="small" /></Grid>
                    <Grid item>
                        <Box component='h5'>{` ${messages['sidebar.bankAccount']}`}</Box>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Box className={classes.container}>
                    <BankAccountForm
                        api={updateApi}
                        handleSubmit={handleSubmit}
                        bankAccount={bankAccount}
                    />
                    {readApi.status === apiStatus.LOADING && (
                        <Box className={classes.loader}>
                            <Loader />
                        </Box>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

export default BankAccount