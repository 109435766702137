import { IRow, MemoizedBasicTable, StringNumberArrayType } from 'components/Table/BasicTable';
import React, { useMemo } from 'react';
import { IDeityResponse } from 'types/api/temple';

interface DeityTableProps {
    editHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deleteHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deletingIds?: StringNumberArrayType,
    deities: IDeityResponse[]
}

const getRows = (deities: IDeityResponse[]) : IRow[] => {
    return deities.map(deity => ({
        id: deity.id,
        values: [
            deity.name,
            deity.isMain ? 'Yes' : 'No'
        ]
    }))
}

const DeityTable : React.FC<DeityTableProps> = ({editHandler, deleteHandler, deletingIds, deities}) => {
    const rows = useMemo(() => getRows(deities),[deities])
    return (
        <MemoizedBasicTable
            rows={rows}
            headRow={[
                'common.name',
                'deity.main.title',
            ]}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            deletingIds={deletingIds}
        />
    );
};

export default DeityTable;