
import { CommonTableHeadCell, CommonTableOrder } from 'components/Table/common/types';
import NormalTableHead from 'components/Table/NormalTable/NormalTableHead';
import usePermissionCheck from 'hooks/usePermissionCheck';
import React, { useMemo } from 'react'
import { IOffferingFinancialRow } from 'types/api/offeringFinancial';
import { UsersResponseType } from 'types/api/temple';
import { PERMISSIONS, RESTRICTED_PATHS } from 'utils/permissions';

interface UsersTableHeadProps {
  onRequestSort: (property: keyof (PublicUserResponseType & UsersResponseType)) => void;
  order: CommonTableOrder;
  orderBy: keyof (PublicUserResponseType & UsersResponseType);
}
const UsersTableHead = (props: UsersTableHeadProps) => {
  const { order, orderBy, onRequestSort } = props;
  const hasEditPermission = usePermissionCheck(RESTRICTED_PATHS.USER, PERMISSIONS.CHANGE)
  const hasDeletePermission = usePermissionCheck(RESTRICTED_PATHS.USER, PERMISSIONS.DELETE)

  const headCells = useMemo(() => {
    const row: CommonTableHeadCell<PublicUserResponseType & UsersResponseType >[] = [
      { id: 'id', align: 'left', disablePadding: false, label: 'UserID', hasSorting: false },
      { id: 'fullName', align: 'left', disablePadding: false, label: 'Name', hasSorting: false },
      { id: 'email', align: 'left', disablePadding: false, label: 'Email', hasSorting: false },
      { id: 'phone', align: 'left', disablePadding: false, label: 'Phone', hasSorting: false },
      { id: 'role', align: 'left', disablePadding: false, label: 'Role', hasSorting: false },
    ]
    if (hasEditPermission || hasDeletePermission) row.push(
      { id: 'permissions', align: 'right', disablePadding: false, label: '', hasSorting: false }
    )
   
    return row
  }, [hasEditPermission])

  return (
    <NormalTableHead
      headCells={headCells}
      orderBy={orderBy}
      order={order}
      sortHandler={onRequestSort}
    />
  )
}

export default UsersTableHead