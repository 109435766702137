import {store} from 'store/reduxStore'
import { selectAuth, AuthState } from 'store/reducers/auth/authSlice'
import { setJWT } from 'utils/auth'


let currentState : AuthState

export const subscribeAuth = () => {
    let previousState = currentState
    currentState = selectAuth(store.getState())
    if (previousState && previousState?.jwt?.access !== currentState?.jwt?.access){
        const jwt = currentState.jwt
        setJWT(jwt)
    }
}