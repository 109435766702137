import { Loader } from '@crema';
import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { initialUrls } from 'shared/constants/AppConst';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUser, setUserRole } from 'store/reducers/auth/authSlice';

const UserSwitcher : React.FC = props => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const history = useHistory()
    useEffect(() => {
        if (user?.role && user.role === 'user' && user.isStaff){
            dispatch(setUserRole('staff'))
            history.push(initialUrls['staff'])
        }
        else if (user?.role && user.role === 'staff'){
            dispatch(setUserRole('user'))
            history.push(initialUrls['user'])
        }
        else history.goBack()
    }, [dispatch, user, history])
    return <Loader/>;
};

export default UserSwitcher;