// import React from 'react';
import Staff from "containers/Staff";
import Dashboard from "containers/Staff/Dashboard";


export const staffConfig = [
  {
    auth: ['staff'],
    component: Staff,
    path: '/staff',
    routes: [
      {
        component: Dashboard,
        path: '/staff/dashboard'
      }
    ]
  },
];
