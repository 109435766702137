import React from 'react';

import CssBaseline from "@material-ui/core/CssBaseline";
import { ConnectedRouter } from 'connected-react-router';

// crema imports
import AppLayout from '@crema/core/AppLayout';
import AuthRoutes from '@crema/utility/AuthRoutes';
import CremaThemeProvider from '@crema/utility/CremaThemeProvider';
import CremaStyleProvider from '@crema/utility/CremaStyleProvider';
import ContextProvider from '@crema/utility/ContextProvider';
import LocaleProvider from '@crema/utility/LocaleProvider';

// app imports
import { Provider } from 'react-redux'
import { store } from 'store/reduxStore'
import { subscribeAuth } from 'store/subscriptions/auth'
import { history } from 'store/reducers/history'
import { SnackbarProvider } from 'notistack';
// import routes from "routes/routes"


// custom stylesheet
import "assets/scss/main.scss"

function App() {
  store.subscribe(subscribeAuth)
  return (
    <ContextProvider>
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <CremaThemeProvider>
            <CremaStyleProvider>
              <LocaleProvider>
                <ConnectedRouter history={history}>
                  <AuthRoutes>
                    <CssBaseline />
                    <AppLayout />
                  </AuthRoutes>
                </ConnectedRouter>
              </LocaleProvider>
            </CremaStyleProvider>
          </CremaThemeProvider>
        </SnackbarProvider>
      </Provider>
    </ContextProvider>
  );
}

export default App;
