import HeadingCard from 'components/Card/HeadingCard';
import { FormikHelpers } from 'formik';
import React from 'react';
import { IApiState } from 'store/types';
import { BankAccountType } from 'types/api/temple';
import BankAccountForm from '../BankAccountForm';

interface BankAccountCardProps {
    bankAccount?: BankAccountType
    updateApi: IApiState,
    handleSubmit: (values: Partial<BankAccountType>, actions: FormikHelpers<Partial<BankAccountType>>) => void 
}

const BankAccountCard: React.FC<BankAccountCardProps> = ({ bankAccount, updateApi, handleSubmit }) => {
    return (
        <HeadingCard title="Bank Account">
            <BankAccountForm forStaff={true} bankAccount={bankAccount} handleSubmit={handleSubmit} api={updateApi} />
        </HeadingCard>
    );
};


export default BankAccountCard;