import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import { apiInstance, apiNewInstance, uploadInstance } from "./client"
import { store } from "store/reduxStore"
import { setJwtToken } from '../store/reducers/auth/authSlice'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { baseUrl } from "shared/constants/AppConst"

interface RetryQueueItem {
    resolve: (value?: any) => void;
    reject: (error?: any) => void;
    config: AxiosRequestConfig;
    instance: 'default' | 'upload'
  }

let isRefreshing: boolean = false
const refreshQueue: RetryQueueItem[] = [];

const refreshTokenFn = async (): Promise<TJWTResponse | undefined> => {
    try {
        const response = await axios.post<TJWTResponse>('/user/v2/auth/refresh/', { refresh: store.getState().auth?.jwt?.refresh ?? undefined} ,{ baseURL: baseUrl, headers: { 'Content-Type': 'application/json' }})
        store.dispatch(setJwtToken(response.data))
        return response.data
    } catch (err: any) {
        return Promise.reject(err)
    }
}

const requestInterceptor = (config: any) => {
    const jwt = store.getState().auth.jwt
    if (config?.data)
        config.data = snakeCaseKeys(config.data)
    if(config?.templeId)
        config.headers['X-Temple-ID'] = config.templeId

    config.headers.Authorization = jwt ?  `JWT ${jwt.access}` : '';
    return config
}

const responseInterceptor = (response: AxiosResponse) => {
    if (response?.data)
        response.data = camelCaseKeys(response.data, {deep: true, exclude: [/^.*_\d.*$/]})
    return response
}

const responseErrInterceptor = (instance: 'default' | 'upload') => async (err: any) => {
    if(axios.isAxiosError(err) && err.response && err.response.status === 401 && typeof err?.response?.data === 'object' && typeof err?.response?.data?.code=== 'object' && err?.response?.data?.code?.code === "token_not_valid") {
        const originalRequest: AxiosRequestConfig = err.config;
        if(!isRefreshing) {
            isRefreshing = true
            try {
                const newJwt = await refreshTokenFn()

                err.config.headers['Authorization'] = `JWT ${newJwt?.access}`;

                refreshQueue.forEach(({ config, instance, resolve, reject }) => {
                    if(instance === 'default')
                        apiInstance
                            .request(config)
                            .then((response) => resolve(response))
                            .catch((err) => reject(err));
                    else 
                        uploadInstance
                            .request(config)
                            .then((response) => resolve(response))
                            .catch((err) => reject(err));
                  });

                refreshQueue.length = 0;

                return apiNewInstance.request(originalRequest);
            } catch (refreshError) {
                console.log(refreshError)
                store.dispatch(setJwtToken(null))
                throw refreshError;
            } finally {
                isRefreshing = false;
            }
        }

        return new Promise<void>((resolve, reject) => {
            refreshQueue.push({ config: originalRequest, instance, resolve, reject });
        });
    }
    
    
    if(err?.response?.status === 500){
        err.response.data = {detail: '500 Internal Server Error Occured'}
    }
    else if(err?.response?.data)
        err.response.data = camelCaseKeys(err.response.data, {deep: true})

    return Promise.reject(err)
}

apiInstance.interceptors.request.use(requestInterceptor)

apiInstance.interceptors.response.use(responseInterceptor, responseErrInterceptor('default'))
apiNewInstance.interceptors.response.use(responseInterceptor, responseErrInterceptor('default'))


uploadInstance.interceptors.request.use((config: any) => {
    const jwt = store.getState().auth.jwt
    if(config?.templeId)
        config.headers['X-Temple-ID'] = config.templeId

    config.headers.Authorization = jwt ?  `JWT ${jwt.access}` : '';
    return config
})
uploadInstance.interceptors.response.use(responseInterceptor, responseErrInterceptor('upload'))
