import React, { useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton';
import IntlMessages from '@crema/utility/IntlMessages';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { ReceiptFilterFormType } from 'types/store/receipt';
import FormikTextField from 'components/Fields/FormikTextField';
import { useIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core';
import { CremaTheme } from 'types/crema/AppContextPropsType';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deityListThunk, selectCurrentTempleDeities } from 'store/reducers/temple/deitySlice';
import { filterInitValues, formToFilterArgs } from 'utils/temple/receipt';
import * as yup from 'yup'
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import { IApiState } from 'store/types';
import { apiStatus } from 'shared/constants/AppEnum';
import PaginatedToolbar from 'components/Table/PaginatedTable/PaginatedToolbar';
import { ReceiptRequestTypes } from 'store/reducers/temple/receiptSlice/types';
import BookingsDeitySelect from '../Bookings/BookingsDeitySelect';
import { useHistory, useLocation } from 'react-router';
import { selectCurrentTempleOfferingCategories } from 'store/reducers/temple/offeringCategorySlice';

interface EnhancedTableToolbarProps {
    changeFilter: (filters: OfferingFilterForm) => void,
    receiptApi: IApiState,
    values: OfferingFilterForm
}

const useStyles = makeStyles((theme: CremaTheme) => (
    createStyles({
        field: {
            flexGrow: 1
        },
        fieldRoot: {
            // width: 180,
            // [theme.breakpoints.down('sm')]: {
            //   width: '100%'
            // },
            width: '100%'
        },
        hidden: {
            display: "none"
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        formGridRoot: {
            [theme.breakpoints.down('sm')]: {
                flexDirection: "column"
            },
        }
    })
))

export type OfferingFilterForm = {
    deity: string,
    category: string
}

const OfferingToolbar: React.FC<EnhancedTableToolbarProps> = ({ changeFilter, receiptApi, values }) => {
    const [showFilters, setShowFilters] = useState(false);
    const handleShowFilter = () => setShowFilters(!showFilters)
    const { messages } = useIntl();
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const deities = useAppSelector(selectCurrentTempleDeities)
    const categories = useAppSelector(selectCurrentTempleOfferingCategories)
    useEffect(() => {
        dispatch(deityListThunk({}))
    }, [dispatch])

    const history = useHistory()
    const location = useLocation()
    const { pathname } = location
    const handleFormChange = (requestType: ReceiptRequestTypes, values: ReceiptFilterFormType) => {
    }
    const validationSchema = yup.object({

        deity: yup.string(),
        category:  yup.string(),
    })
    return (
        <PaginatedToolbar titleId="offering.title" handleShowFilter={handleShowFilter} showFilters={showFilters} addNew={() => history.push(`${pathname}/add`)} >
            <Formik
                initialValues={values}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => changeFilter(values)}
            >
                {({ values, setFieldValue, isValid, errors, resetForm }) => (
                    <Form noValidate autoComplete="off">
                        <Grid container direction="row" spacing={6} className={classes.formGridRoot}>

                            <Grid item container md={4} xs={12} spacing={2}>
                                <Grid item className={classes.field}>
                                    <FormikTextField
                                        select
                                        name='deity'
                                        className={classes.fieldRoot}
                                        size="small"
                                        label={<IntlMessages id='deity' />}
                                        margin="none"
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {deities.map(deity => (
                                            <MenuItem key={deity.id} value={deity.url}>
                                                {deity.name}
                                            </MenuItem>
                                        ))}
                                    </FormikTextField>
                                </Grid>
                            </Grid>
                            <Grid item container md={4} xs={12} spacing={2}>
                                <Grid item className={classes.field}>
                                    <Grid item className={classes.field}>
                                        <FormikTextField
                                            select
                                            name='category'
                                            className={classes.fieldRoot}
                                            size="small"
                                            label={<IntlMessages id='common.category' />}
                                            margin="none"
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {
                                                categories.map(category => <MenuItem key={category.id} value={category.url}>
                                                    {category.name}
                                                </MenuItem>)
                                            }
                                        </FormikTextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={4} xs={4} alignItems='flex-end'>
                                <Grid item>
                                    <Button disabled={!isValid || receiptApi.status === apiStatus.LOADING} variant="contained" size="small" color="primary" type="submit">
                                        <IntlMessages id="common.apply" />
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <IconButton onClick={() => {
                                        changeFilter({ category: '', deity: '' })
                                        resetForm()
                                    }} size="small">
                                        <RefreshIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </PaginatedToolbar>
    );
};

export default React.memo(OfferingToolbar)