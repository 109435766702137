import Box from '@material-ui/core/Box'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableCell, {TableCellProps} from '@material-ui/core/TableCell'
import React from 'react'
import { CremaTheme } from 'types/crema/AppContextPropsType'

const useStyles = makeStyles((theme: CremaTheme) => (
    createStyles({
        cell: {
            width: 'max-content',
            maxWidth: 150,
            display: 'inline-block'
        }
    })
))
const FullWidthTableCell : React.FC<TableCellProps> = props => {
    const classes = useStyles()
    return (
        <TableCell {...props}>
            <Box className={classes.cell} style={{textAlign: props.align ?? 'left'}}>
                {props.children}
            </Box>
        </TableCell>
    )
}

export default FullWidthTableCell