import { IRow, MemoizedBasicTable, StringNumberArrayType } from 'components/Table/BasicTable';
import React, { useMemo } from 'react';
import { IDonationCategoryResponse } from 'types/api/temple';

interface DonationCategoryTableProps {
    editHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deleteHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deletingIds?: StringNumberArrayType,
    donationCategories: IDonationCategoryResponse[]
}

const getRows = (donationCategories: IDonationCategoryResponse[]) : IRow[] => {
    let rows : IRow[] = []
    donationCategories.forEach(cat => {
        rows.push({
            id: cat.id,
            values: [cat.name, cat.donations.length]
        })
    })
    return rows
}

const DonationCategoryTable : React.FC<DonationCategoryTableProps> = ({editHandler, deleteHandler, deletingIds, donationCategories}) => {
    const rows = useMemo(() => getRows(donationCategories),[donationCategories])
    return (
        <MemoizedBasicTable
            rows={rows}
            headRow={['common.name', 'offering.no']}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            deletingIds={deletingIds}
        />
    );
};

export default DonationCategoryTable;
