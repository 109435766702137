import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { usersApi } from "api/temple";
import { apiStatus } from "shared/constants/AppEnum";
import { AppState } from "store/reduxStore";
import { IApiState } from "store/types";
import { TUpdatePermissionsPayload, TUserPermissions, UsersResponseType } from "types/api/temple";
import { cachePermissions, loadPermissions } from "utils/routePermissionHandler";

export enum UserRoleEnum {
    staff = 'staff',
    admin = 'administrator'
}

export type TInviteForm = {
    email: string;
    role: UserRoleEnum
}

type TUsersState = {
    currentUserId: string | number,
    permissions: TUserPermissions
    usersApi: IApiState,
    listPermissionsApi: IApiState,
    updatePermissionsApi: IApiState
    userDeleteApi: IApiState
}

const usersAdapter = createEntityAdapter<UsersResponseType>({
    sortComparer: (a, b) => a.user.firstName.localeCompare(b.user.firstName),
})

const { selectById, selectAll } = usersAdapter.getSelectors()

export const selfThunk = createAsyncThunk(
    'users/self',
    async (payload: string | number, { rejectWithValue }) => {
        try {
            const response = await usersApi.self(payload)
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    })

export const usersListThunk = createAsyncThunk(
    'users/list',
    async (_, { rejectWithValue }) => {
        try {
            const response = await usersApi.list()
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    })

export const userDeleteThunk = createAsyncThunk(
    'users/delete',
    async (payload: string, { rejectWithValue }) => {
        try {
            const response = await usersApi.delete(payload)
            return payload
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const permissionsListThunk = createAsyncThunk(
    'users/permissions',
    async (_, { rejectWithValue }) => {
        try {
            const response = await usersApi.permissions()
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    })

export const permissionsUpdateThunk = createAsyncThunk(
    'users/permissions/update',
    async (payload: TUpdatePermissionsPayload, { rejectWithValue }) => {
        try {
            const response = await usersApi.updatePermissions(payload)
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    })

const addOnInitialState: TUsersState = {
    currentUserId: '',
    permissions: loadPermissions() ?? {},
    usersApi: {
        status: apiStatus.IDLE,
        error: null
    },
    listPermissionsApi: {
        status: apiStatus.IDLE,
        error: null
    },
    updatePermissionsApi: {
        status: apiStatus.IDLE,
        error: null
    },
    userDeleteApi: {
        status: apiStatus.IDLE,
        error: null
    }
}

const usersSlice = createSlice({
    name: 'users',
    initialState: usersAdapter.getInitialState(addOnInitialState),
    reducers: {
        resetState: (state) => {
            state.currentUserId = ''
            state.usersApi = {
                status: apiStatus.IDLE,
                error: null
            }
            state.listPermissionsApi = {
                status: apiStatus.IDLE,
                error: null
            }
            state.updatePermissionsApi = {
                status: apiStatus.IDLE,
                error: null
            }
            usersAdapter.removeAll(state)
            cachePermissions({})
        }
    },
    extraReducers: (builder) => {

        builder.addCase(userDeleteThunk.fulfilled, (state, action) => {
            state.userDeleteApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            usersAdapter.removeOne(state, action.payload)
        })
        builder.addCase(userDeleteThunk.pending, (state, action) => {
            state.userDeleteApi =  {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(userDeleteThunk.rejected, (state, action) => {
            state.userDeleteApi =  {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(usersListThunk.pending, (state, action) => {
            state.usersApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(usersListThunk.fulfilled, (state, action) => {
            state.usersApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            usersAdapter.upsertMany(state, action.payload)
        })
        builder.addCase(usersListThunk.rejected, (state, action) => {
            state.usersApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(permissionsListThunk.pending, (state, action) => {
            state.listPermissionsApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(permissionsListThunk.fulfilled, (state, action) => {
            state.listPermissionsApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            state.permissions = action.payload
            cachePermissions(action.payload)
        })
        builder.addCase(permissionsListThunk.rejected, (state, action) => {
            state.listPermissionsApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(permissionsUpdateThunk.pending, (state, action) => {
            state.updatePermissionsApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(permissionsUpdateThunk.fulfilled, (state, action) => {
            state.updatePermissionsApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            usersAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(permissionsUpdateThunk.rejected, (state, action) => {
            state.updatePermissionsApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(selfThunk.fulfilled, (state, action) => {
            state.updatePermissionsApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            state.currentUserId = action.payload.id
            usersAdapter.upsertOne(state, action.payload)
        })
    }
})

export default usersSlice.reducer
export const { resetState: resetUsersState } = usersSlice.actions

export const selectUsersApiStatus = (state: AppState) => state.users.usersApi
export const selectListPermissionApiStatus = (state: AppState) => state.users.listPermissionsApi
export const selectUpdatePermissionApiStatus = (state: AppState) => state.users.updatePermissionsApi
export const selectCurrentUser = (state: AppState) => selectById(state.users, state.users.currentUserId)
export const selectAllUsers = (state: AppState) => selectAll(state.users)
export const selectUserById = (state: AppState, id: string) => selectById(state.users, id)
export const selectPermissions = (state: AppState) => state.users.permissions
export const selectSelfPermissions = (state: AppState) => selectById(state.users, state.users.currentUserId)?.permissions