export type StaffTempleOrderByType = 'created_at' | '-created_at' | 'id' | '-id' | 'name' | '-name'

export type StaffTempleParamsType = {
    is_active?: boolean,
    o?: StaffTempleOrderByType
}

export type StaffBankAccountOrderByType = 'created_at' | '-created_at' | 'updated_at' | '-updated_at' | 'id' | '-id' 

export type StaffBankAccountParamsType = {
    is_active?: boolean,
    o?: StaffBankAccountOrderByType
}

export enum TempleAdminRoleEnum {
    ADMIN = 'administrator',
    MANAGER = 'manager'
}

export type StaffTempleAdminParams = {
    temple: string | number,
    user?: string | number,
    role?: TempleAdminRoleEnum
}

export type StaffTempleAdminCreateRequest = Required<StaffTempleAdminParams>

export type StaffTempleAdminResponse = IdAndUrlType & {
    temple: string | number,
    user: PublicUserResponseType,
    role: TempleAdminRoleEnum
}