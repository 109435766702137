import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { unwrapResult } from '@reduxjs/toolkit';
import { StaffBankAccountOrderByType, StaffBankAccountParamsType } from 'api/admin/types';
import HeadingCard from 'components/Card/HeadingCard';
import { CommonTableHeadCell, CommonTableOrder } from 'components/Table/common/types';
import PaginatedTable, { getComparator } from 'components/Table/PaginatedTable';
import PaginatedTableHead from 'components/Table/PaginatedTable/PaginatedTableHead';
import React, { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { apiStatus } from 'shared/constants/AppEnum';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllInactiveBankAccounts, selectBankAccountListApi, staffBankListThunk } from 'store/reducers/temple/bankAccountSlice';
import { BankAccountType } from 'types/api/temple';
import { dateTimeToString, isoDateToDate, stableSort } from 'utils/fns';

type BankAccountApprovalRowType = {
    id: number | string
    accName: string
    accNo: string
    ifsc: string
    merchantId?: string
    templeUrl?: string
    updatedAt?: Date
}

type OrderingChoicesType = Partial<Record<keyof BankAccountApprovalRowType, StaffBankAccountOrderByType>>
type OrderByChoices = Record<CommonTableOrder, OrderingChoicesType>

interface BankAccountApprovalTableProps{
    handleTempleOpen: (url: string) => void
}

interface IPaginationState {
    order: CommonTableOrder,
    currentPage: number,
    maxPage: number,
    nextPageUrl: string
    orderBy: keyof BankAccountApprovalRowType,
    pageSize: number,
}

const headCells: CommonTableHeadCell<BankAccountApprovalRowType>[] = [
    { id: 'accName', align: 'left', disablePadding: false, label: 'Name', hasSorting: false },
    { id: 'accNo', align: 'left', disablePadding: false, label: 'Account No.', hasSorting: false },
    { id: 'ifsc', align: 'left', disablePadding: false, label: 'IFSC', hasSorting: false },
    { id: 'merchantId', align: 'left', disablePadding: false, label: 'Merchant ID', hasSorting: false },
    { id: 'updatedAt', align: 'left', disablePadding: false, label: 'Updated On', hasSorting: true },
];

const getRows = 
(bankAccounts: BankAccountType[], order: CommonTableOrder, orderBy: keyof BankAccountApprovalRowType): BankAccountApprovalRowType[] => {
    const rows : BankAccountApprovalRowType[] = bankAccounts.map(bankAccount => {
        let updatedAt: Date | boolean = bankAccount.updatedAt ? isoDateToDate(bankAccount.updatedAt) : false
        return ({
            templeUrl: bankAccount.temple?.url ?? '',
            id: bankAccount.id,
            accName: bankAccount.accName,
            accNo: bankAccount.accNumber,
            ifsc: bankAccount.ifsc,
            merchantId: bankAccount.merchantId ?? '',
            ...(typeof updatedAt !== 'boolean' ? { updatedAt: updatedAt } : {})
        })
    })
    return stableSort(rows, getComparator(order, orderBy))
}


const initialState: IPaginationState = {
    order: 'desc',
    currentPage: 0,
    maxPage: 0,
    nextPageUrl: '',
    orderBy: 'updatedAt',
    pageSize: 10,
}


const orderByChoices: OrderByChoices = {
    asc: {
        id: 'id',
        updatedAt: 'updated_at'
    },
    desc: {
        id: '-id',
        updatedAt: '-updated_at'
    }
}

const BankAccountApprovalTable: React.FC<BankAccountApprovalTableProps> = ({handleTempleOpen}) => {
    const dispatch = useAppDispatch()
    const api = useAppSelector(selectBankAccountListApi)
    const [pagination, setPagination] = useState<IPaginationState>(initialState)
    const [params, setParams] = useState<StaffBankAccountParamsType>({ is_active: false, o: '-updated_at' })
    const bankAccounts = useAppSelector(selectAllInactiveBankAccounts)
    const rows = useMemo(() => getRows(bankAccounts, pagination.order, pagination.orderBy), [bankAccounts, pagination.order, pagination.orderBy])
    useEffect(() => {
        dispatch(staffBankListThunk(params))
            .then(unwrapResult)
            .then(response => setPagination(p => ({ ...p, nextPageUrl: response.nextLink })))
            .catch(err => err)
    }, [dispatch, params])
    useEffect(() => {
        if (orderByChoices[pagination.order][pagination.orderBy])
            setParams(p => ({ ...p, o: orderByChoices[pagination.order][pagination.orderBy] }))
    }, [pagination.order, pagination.orderBy])
    const handleChangePage = async (event: unknown, newPage: number) => {
        if (newPage > pagination.maxPage) {
            setPagination(p => ({ ...p, maxPage: newPage }))
            if (pagination.nextPageUrl) {
                await dispatch(staffBankListThunk({ url: pagination.nextPageUrl }))
                    .then(unwrapResult)
                    .then(response => setPagination(p => ({ ...p, nextPageUrl: response.nextLink })))
                    .catch(err => err)
            }
        }
        setPagination(p => ({ ...p, currentPage: newPage }))
    };

    const sortHandler = (id: keyof BankAccountApprovalRowType) => {
        pagination.orderBy === id ?
            setPagination(p => ({ ...p, order: p.order === 'asc' ? 'desc' : 'asc' })) :
            setPagination(p => ({ ...p, orderBy: id }))
    }

    return (
        <HeadingCard title="Bank Account Approvals" cardContent={false} hasheaderBackground={false}>
            {bankAccounts.length > 0 ? (
                <PaginatedTable
                    pagination={{
                        rowsPerPage: pagination.pageSize,
                        rowsPerPageOptions: [10],
                        currentPage: pagination.currentPage,
                        nextIconDisabled:
                            bankAccounts.length > 0
                                && !pagination.nextPageUrl
                                && pagination.currentPage === pagination.maxPage ? true : false,
                        handleChangePage: handleChangePage,
                    }}
                    tableHead={
                        <PaginatedTableHead
                            headCells={headCells}
                            orderBy={pagination.orderBy}
                            order={pagination.order}
                            sortHandler={sortHandler}
                        />
                    }
                    api={api}
                >
                    {rows.slice(pagination.currentPage * pagination.pageSize, pagination.currentPage * pagination.pageSize + pagination.pageSize)
                        .map(row => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    <Box 
                                        onClick={() => row.templeUrl && handleTempleOpen(row.templeUrl)}
                                        style={{cursor: 'pointer'}}
                                    >{row.accName}</Box>
                                </TableCell>
                                <TableCell align="left">{row.accNo}</TableCell>
                                <TableCell align="left">{row.ifsc}</TableCell>
                                <TableCell align="left">{row.merchantId}</TableCell>
                                <TableCell align="left">{row.updatedAt ? dateTimeToString(row.updatedAt) : ''}</TableCell>
                            </TableRow>
                        ))}
                </PaginatedTable>
            ) : (
                <Box textAlign="center" my={10}>
                    {api.status === apiStatus.LOADING ? <CircularProgress/> : <p>'No pending requests'</p>}
                </Box>
            )}
        </HeadingCard>
    );
};


export default BankAccountApprovalTable